import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { OrdersTable, Loader } from "../../../components";
import {
  ORDER_TYPES,
  OrderState,
  SimpleOrderPaginatedResponse,
} from "../../../types";

type OpenProps = {
  onGetOrders: (
    state: OrderState,
    type: ORDER_TYPES,
    _type: ORDER_TYPES,
    page: number
  ) => Promise<SimpleOrderPaginatedResponse>;
};

export const Opened = ({ onGetOrders }: OpenProps) => {
  const [openedPageNumber, setOpenedPageNumber] = useState(0);

  const orders = useQuery({
    queryKey: ["orders", openedPageNumber],
    queryFn: () =>
      onGetOrders(
        OrderState.OPEN,
        ORDER_TYPES.NORMAL,
        ORDER_TYPES.INVERSE,
        openedPageNumber
      ),
  });

  const handleOpenedPageNumber = (page: number) => setOpenedPageNumber(page);

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={openedPageNumber}
              setPageNumber={handleOpenedPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
