import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CheckCircleIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import {
  mapStateToProps,
  mapDispatchToProps,
  newMaterialOfferValidationSchema,
  NO_SPEC,
  TO_BE_AGREED,
  IT_DOESNT_HAVE_SPEC,
  IS_CONFIDENTIAL,
  addressFormatter,
  PN_TYPE,
} from "../../utils";
import { GoBackArrow, Loader, Modal, NewOfferMaterialForm, InfoItem } from "..";
import {
  useCountries,
  useFileInputUploader,
  useID,
  useMaterials,
  useMaterialsOffers,
} from "../../hooks";
import { CertificationResponse, UserResponse } from "../../types";

type OpportunityProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
};

const Opportunity = ({ user, onOpenNotification }: OpportunityProps) => {
  const { t } = useTranslation();
  const id = useID();
  const { onGetMaterial } = useMaterials();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [specificationName, setSpecificationName] = useState<any>(null);
  const [certifications, setCertifications] = useState<CertificationResponse[]>(
    []
  );
  const { onCreateMaterialOffer, onCheckMaterialOffer } = useMaterialsOffers();
  const { onGetPublicCountries } = useCountries();

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: () => onGetPublicCountries(),
  });

  const { data: material, isLoading: isLoadingMaterial } = useQuery({
    queryKey: ["material", id],
    queryFn: () => onGetMaterial(id),
    enabled: !!id,
  });

  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();

  const isSpec = NO_SPEC === material?.fileURL;

  const formOptions = {
    resolver: yupResolver(newMaterialOfferValidationSchema(isSpec)),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const { data: checkMaterialOffer } = useQuery({
    queryKey: ["checkMaterialOffer", id, user?.id],
    queryFn: () =>
      onCheckMaterialOffer({ materialId: id, buyerId: material?.user?.id }),
    enabled: !!id && !!material,
  });

  const { mutate: createMaterialOffer, isPending: isCreatingMaterialOffer } =
    useMutation({
      mutationFn: (body: any) => onCreateMaterialOffer(body),
      onSuccess: (res) => {
        onOpenNotification(t("materialOffer.success"));
        navigate(`/material/offer/${res?.id}`);
      },
      onError: () => onOpenNotification(t("newMaterialForm.error")),
    });

  const onSubmit = (data: any) => {
    if (certifications.length === 0) {
      createMaterialOffer({
        ...data,
        specificationFileUrl: uploadedSpecificationFileUrl
          ? uploadedSpecificationFileUrl
          : NO_SPEC,
        material: { id: material?.id },
        origin: { id: data.origin },
        user: { id: user?.id },
      });
    } else {
      const formattedCertfications = certifications.map(
        (cert: CertificationResponse) => ({
          valid: true,
          materialCertification: cert,
        })
      );
      createMaterialOffer({
        ...data,
        specificationFileUrl: uploadedSpecificationFileUrl
          ? uploadedSpecificationFileUrl
          : NO_SPEC,
        material: { id: material?.id },
        origin: { id: data.origin },
        user: { id: user?.id },
        certifications: formattedCertfications,
      });
    }
    setSpecificationName(null);
    reset();
    setOpen(false);
  };
  const onError = () => setOpen(false);

  const handleSpecification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
      setSpecificationName(file[0]?.name);
    }
  };

  const handleAddCertification = (cert: CertificationResponse) => {
    setCertifications((prev) => {
      const exists = prev.some((item) => item.id === cert.id);
      if (exists) {
        return prev.filter((item) => item.id !== cert.id);
      } else {
        return [...prev, cert];
      }
    });
  };

  const PI =
    material?.category?.parent?.parent?.parent?.parent?.name === PN_TYPE.PI;

  return (
    <Fragment>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("materialOffer.apply")}
        message={t("materialOffer.applyQuestion")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
      {isLoadingMaterial || isCreatingMaterialOffer ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <GoBackArrow stylesAdded={"px-8"} />
              <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-2 gap-4">
                <div className="col-span-2 xl:col-span-1">
                  <div className="md:flex md:items-center md:justify-between md:space-x-4 border-b pb-4">
                    <div>
                      <h1 className="text-xl font-bold text-gray-900 uppercase">
                        {material?.globalMaterialName}
                      </h1>
                    </div>
                  </div>
                  {PI && (
                    <Fragment>
                      {material?.coin && (
                        <InfoItem
                          name={t("newMaterialForm.currency")}
                          description={material?.coin}
                        />
                      )}
                      <InfoItem
                        name={t("opportunities.table.incoTerm")}
                        description={material?.incoTerm}
                      />
                      <InfoItem
                        name={t("opportunities.table.deliveryFormat")}
                        description={material?.deliveryFormat}
                      />
                    </Fragment>
                  )}
                  <InfoItem
                    name={t("opportunities.table.deliveryPlace")}
                    description={
                      material?.deliveryPlace
                        ? addressFormatter(material?.deliveryPlace?.address)
                        : TO_BE_AGREED
                    }
                  />
                  {material?.application && (
                    <InfoItem
                      name={t("opportunities.table.application")}
                      description={material?.application}
                    />
                  )}
                  {material?.comments && (
                    <InfoItem
                      name={t("opportunities.table.comments")}
                      description={material?.comments}
                    />
                  )}
                  <div className="bg-white overflow-hidden">
                    <div className="py-2 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                          <dt className="uppercase text-xs font-medium text-gray-500">
                            {t("newMaterialForm.specification")}
                          </dt>
                          <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                            {material?.confidentSpec ? (
                              <p>{IS_CONFIDENTIAL}</p>
                            ) : isSpec ? (
                              <p>{IT_DOESNT_HAVE_SPEC}</p>
                            ) : (
                              <a
                                href={material?.fileURL}
                                target="_blank"
                                rel="noreferrer"
                                className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                              >
                                <ArrowDownTrayIcon
                                  className="-ml-0.5 mr-2 h-4 w-4"
                                  aria-hidden="true"
                                />
                                {t("cta.download")}
                              </a>
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 xl:col-span-1">
                  {!checkMaterialOffer ? (
                    <NewOfferMaterialForm
                      countries={countries}
                      material={material}
                      errors={errors}
                      setOpen={setOpen}
                      register={register}
                      isLoadingUpload={isLoadingUpload}
                      handleAddCertification={handleAddCertification}
                      specificationName={specificationName}
                      handleSpecification={handleSpecification}
                    />
                  ) : (
                    <div className="w-full text-xs p-2 rounded border border-green-800 bg-green-100 text-center font-bold uppercase">
                      <p>{t("materialOffer.applied")}!</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Opportunity);
