import { t } from "i18next";
import { NumericFormat } from "react-number-format";
import { ORDER_TYPES } from "../../types";
import { useState } from "react";
import { currencyFormatter } from "../../utils";

type JointOfferFormProps = {
  id: string;
  errors: any;
  actions: any;
  currency: string;
  orderType: ORDER_TYPES;
  initialPrice: number | null;
  measurementUnit: string;
  updateEvent: any;
  quantity: number;
};

const JointOfferForm = ({
  id,
  errors,
  actions,
  currency,
  orderType,
  initialPrice,
  measurementUnit,
  updateEvent,
  quantity,
}: JointOfferFormProps) => {
  const errorMessage = errors[id];
  const [value, setValue] = useState(0);
  return (
    <>
      <td className="flex gap-2 items-center relative py-4">
        <NumericFormat
          name="price"
          id="price"
          thousandSeparator="."
          decimalSeparator=","
          defaultValue={0}
          max={
            orderType === ORDER_TYPES.INVERSE && initialPrice
              ? initialPrice
              : ""
          }
          onValueChange={(values) => {
            updateEvent({
              id: id,
              type: actions.UPDATE_OFFER_PRICE,
              price: values.value,
            });
            setValue(Number(values.value));
          }}
          decimalScale={2}
          allowNegative={false}
          prefix={"$ "}
          className={`mt-1 block shadow-sm sm:text-xs rounded ${
            errorMessage ? "border-red-500" : "border-gray-300"
          }`}
        />
        <span className="text-xs uppercase text-gray-600">
          {currency} / {measurementUnit}
        </span>
        {errorMessage && (
          <span className="absolute left-0 bottom-0 text-xs text-red-600">
            {errorMessage}
          </span>
        )}
      </td>
      <td className="w-200 text-xs">
        {currencyFormatter(quantity * value, currency) || 0}
      </td>
      <td>
        <input
          className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
          id="comment"
          name="comment"
          placeholder={t("offer.comments")}
          type="text"
          onChange={(e) =>
            updateEvent({
              id: id,
              type: actions.UPDATE_OFFER_COMMENT,
              comment: e.target.value,
            })
          }
        />
      </td>
    </>
  );
};

export default JointOfferForm;
