import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UsersIcon } from "@heroicons/react/24/solid";
import { Loader } from "../../components";
import Delegate from "../../components/Material/Delegate/Delegate";
import { useMaterials } from "../../hooks";
import { mapDispatchToProps } from "../../utils";

const DelegateCnt = ({
  onOpenNotification,
}: {
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onGetBackAllMaterials, onGetMaterialsByOwner } = useMaterials();

  const { data: materialsByOwner, isLoading: isLoadingMaterials } = useQuery({
    queryKey: ["materialsByOwner"],
    queryFn: () => onGetMaterialsByOwner(),
  });

  const { mutate, isPending: isGettingBackMaterials } = useMutation({
    mutationFn: () => onGetBackAllMaterials(),
    onSuccess: () => {
      onOpenNotification(t("materials.success"));
      navigate(`/materials`);
    },
    onError: () => onOpenNotification(t("newMaterialForm.error")),
  });

  return (
    <div className="px-4 mt-6">
      <div className="flex items-center justify-between pb-8">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {t("delegate.material")}
        </h2>
        {materialsByOwner && materialsByOwner?.content?.length > 0 ? (
          <button
            type="button"
            onClick={() => mutate()}
            className="uppercase flex justify-center items-center gap-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light mt-4"
          >
            <UsersIcon className="h-4 w-4" />
            <span>{t("materials.retrieve")}</span>
          </button>
        ) : null}
      </div>
      {isLoadingMaterials || isGettingBackMaterials ? (
        <Loader isFullScreen />
      ) : (
        <Delegate isDelegateAll />
      )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(DelegateCnt);
