import axios from "../axios-orders";

export default function useSuppliers() {
  const onInviteSupplier = async (email: string, materialId: string) => {
    const { data } = await axios.get(
      `/user/invitation/material/${email}/${materialId}`
    );
    return data;
  };

  const onGetInvitedMaterialSuppliers = async (materialId: string) => {
    const { data } = await axios.get(
      `/user/invitations/material/${materialId}`
    );
    return data;
  };

  const onGetInvitedOrderSuppliers = async (orderId: string) => {
    const { data } = await axios.get(`/user/invitations/order/${orderId}`);
    return data;
  };

  return {
    onInviteSupplier,
    onGetInvitedOrderSuppliers,
    onGetInvitedMaterialSuppliers,
  };
}
