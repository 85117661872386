import { Link } from "react-router-dom";
import { Pill } from "../..";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import {
  PILL_LABELS,
  BIDDING_STATES,
  BIDDING_LABELS,
  BIDDING_TYPES,
  BIDDING_TYPES_LABELS,
} from "../../../utils";
import { OfferResponse } from "../../../types";

type JointOffersTableRowProps = {
  offer: OfferResponse;
  offers: OfferResponse[];
  quantity: number;
};

const JointOffersTableRow = ({
  offer,
  offers,
  quantity,
}: JointOffersTableRowProps) => {
  const isEvenOneSelected = offers?.some((offer) => offer.selected);

  const pillResolver = () => {
    if (offer.order.state === BIDDING_STATES.SUCCEED && isEvenOneSelected)
      return PILL_LABELS.OFFER_SELECTED;
    else if (offer.order.state === BIDDING_STATES.SUCCEED && !isEvenOneSelected)
      return PILL_LABELS.OFFER_NOT_SELECTED;
    else if (offer.order.state === BIDDING_STATES.PENDING)
      return BIDDING_LABELS.SCHEDULED;
    else if (offer.order.state === BIDDING_STATES.OPEN)
      return BIDDING_LABELS.IN_PROGRESS;
    else if (offer.order.state === BIDDING_STATES.CLOSED)
      return PILL_LABELS.OFFER_PENDING_OF_SELECTION;
    else if (offer.order.state === BIDDING_STATES.CANCELLED)
      return PILL_LABELS.BIDDING_CANCELED;
    else return "";
  };

  return (
    <tr key={offer.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xs font-bold text-gray-900">
        <Link
          to={`/offer/multiple/${offer.order.id}?multipleOrderId=${offer.order.multipleOrder?.id}`}
          className="truncate hover:text-gray-600 uppercase"
        >
          {offer.order?.name}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {offer.order.user.company.name}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">{offer.order.finishDate}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">{quantity}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill label={pillResolver()} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          <span
            className={`text-xs font-bold ${
              offer.order.type === BIDDING_TYPES.INVERSE ||
              offer.order.type === BIDDING_TYPES.INVERSE_JOINT
                ? "text-spectum"
                : offer.order.type === BIDDING_TYPES.NORMAL
                  ? "text-green-600"
                  : "text-yellow-600"
            }`}
          >
            {offer.order.type === BIDDING_TYPES.INVERSE &&
              BIDDING_TYPES_LABELS.INVERSE}
            {offer.order.type === BIDDING_TYPES.NORMAL &&
              BIDDING_TYPES_LABELS.NORMAL}
            {offer.order.type === BIDDING_TYPES.JOINT &&
              BIDDING_TYPES_LABELS.JOINT}
            {offer.order.type === BIDDING_TYPES.INVERSE_JOINT &&
              BIDDING_TYPES_LABELS.INVERSE_JOINT}
          </span>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
        <Link
          to={`/offer/multiple/${offer.order.id}?multipleOrderId=${offer.order.multipleOrder?.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default JointOffersTableRow;
