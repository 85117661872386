import axios from "../axios-orders";

export default function useOpportunities() {
  const onGetOpportunities = async () => {
    const { data } = await axios.get(`/order/materials/suppliers`);
    return data;
  };

  const onGetMultipleOpportunities = async (type: string, _type: string) => {
    const { data } = await axios.get(
      `order/multiple/suppliers/${type}/${_type}`
    );
    return data;
  };

  return {
    onGetOpportunities,
    onGetMultipleOpportunities,
  };
}
