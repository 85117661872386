import axios from "../axios-orders";

export default function useMaterialVolume() {
  const onGetMaterialVolumePrice = async (materialId: string) => {
    const { data } = await axios.get(`/materialVolume/material/${materialId}`);
    return data;
  };

  const onUpdateMaterialVolume = async (materialVolume: any) => {
    const data = await axios.put("/materialVolume", materialVolume);
    return data;
  };

  const onGetAllMaterialsVolumesPrices = async () => {
    const { data } = await axios.get("/materialVolume/user");
    return data;
  };

  const onCreateNegotiation = async ({
    userId,
    body,
  }: {
    userId: string;
    body: any;
  }) => {
    const { data } = await axios.post(`/negotiation/${userId}`, body);
    return data;
  };

  return {
    onCreateNegotiation,
    onUpdateMaterialVolume,
    onGetMaterialVolumePrice,
    onGetAllMaterialsVolumesPrices,
  };
}
