import { Fragment, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  PN_TYPE,
  SAMPLE_STATE,
  mapDispatchToProps,
  mapStateToProps,
} from "../../utils";
import { Chat, Loader, MaterialOffer, Slide, Tabs } from "..";
import Detail from "./Detail/Detail";
import Delegate from "./Delegate/Delegate";
import PricingVolume from "./PricingVolume/PricingVolume";
import {
  useAI,
  useChat,
  useCountries,
  useID,
  useMaterials,
  useMaterialsOffers,
  useValidateSeller,
} from "../../hooks";
import FormattedText from "../UI/FormattedText/FormattedText";
import ValidatedSuppliers from "./ValidatedSuppliers/ValidatedSuppliers";
import BiddingList from "./BiddingList/BiddingList";
import Actions from "./Actions/Actions";
import InviteSupplier from "./InviteSupplier/InviteSupplier";
import i18next from "../../i18n";
import PotencialSupplier from "./PotencialSupplier/PotencialSupplier";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CombinedBuyerSellerMaterialOfferResponse,
  MaterialResponse,
  UserResponse,
} from "../../types";
import { openNotification } from "../../store/actions/notificationActions";

const tabs = [
  { name: i18next.t("material.detail"), href: "#", current: true },
  { name: i18next.t("material.volumePrice"), href: "#", current: false },
  { name: i18next.t("material.tenders"), href: "#", current: false },
];

const npiTabs = [
  { name: i18next.t("material.detail"), href: "#", current: true },
  { name: i18next.t("material.tenders"), href: "#", current: false },
];

type MaterialProps = {
  user: UserResponse;
  material: MaterialResponse;
  onOpenNotification: (message: string) => void;
};

const Material = ({ user, onOpenNotification, material }: MaterialProps) => {
  const id = useID();
  const [selectedTab, setSelectedTab] = useState(0);
  const userId = new URLSearchParams(useLocation().search).get("userId");
  const navigate = useNavigate();
  const goToBidding = () =>
    navigate({
      pathname: "/order",
      search: `?id=${id}`,
    });
  const { onEditMaterial, onToggleMaterialStatus } = useMaterials();
  const { onRejectSeller, onValidateSeller } = useValidateSeller();
  const {
    onRequestSample,
    onQualifySample,
    onGetMaterialOffers,
    onCreateMaterialOfferInvitation,
  } = useMaterialsOffers();
  const { onGetChat, onCreateChat, onCreateMessage } = useChat();
  const { onGetCountriesByMaterialId } = useCountries();
  const { onGetMaterialMarketInfo } = useAI();

  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openDelegate, setOpenDelegate] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [potencialSupplierToShow, setPotencialSupplierToShow] =
    useState<any>("");
  const [materialOfferToShow, setMaterialOfferToShow] =
    useState<CombinedBuyerSellerMaterialOfferResponse>();

  const queryClient = useQueryClient();

  const { data: materialOffers, isLoading: isLoadingMaterialOffer } = useQuery({
    queryKey: ["materialOffers", id, userId],
    queryFn: () => onGetMaterialOffers(id),
    enabled: !!id,
  });

  const { data: materialMarketInfo, isLoading: isLoadingMaterialMarketInfo } =
    useQuery({
      queryKey: ["materialMarketInfo", id],
      queryFn: () => onGetMaterialMarketInfo(id),
      enabled: material?.type === PN_TYPE.PI,
    });

  const { data: countriesByMaterial } = useQuery({
    queryKey: ["countriesByMaterial", id],
    queryFn: () => onGetCountriesByMaterialId(id),
    enabled: material?.type === PN_TYPE.PI,
  });

  const { data: chat, isLoading: isLoadingChat } = useQuery({
    queryKey: ["chat", openChat, materialOfferToShow, material?.id],
    queryFn: () =>
      materialOfferToShow &&
      onGetChat(materialOfferToShow.materialOffer.user.id, material.id),
    enabled: !!materialOfferToShow && !!material && openChat,
    retry: false,
  });

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useMutation({
    mutationFn: (body) => onCreateChat(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat"] });
    },
  });

  const { mutate: mutateMessage, isPending: isLoadingMessage } = useMutation({
    mutationFn: (body) => onCreateMessage(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat"] });
    },
  });

  const { mutate: mutateMaterial, isPending: isLoadingMaterials } = useMutation(
    {
      mutationFn: ({ materialId, body }: { materialId: string; body: any }) =>
        onEditMaterial({ materialId, body }),
      onSuccess: () => {
        openNotification(i18next.t("material.retrieveMessage"));
        queryClient.invalidateQueries({ queryKey: ["material"] });
      },
    }
  );

  const { mutate: rejectSeller, isPending: isRejectingSeller } = useMutation({
    mutationFn: ({
      buyerSellerId,
      buyerSeller,
    }: {
      buyerSellerId: string;
      buyerSeller: any;
    }) => onRejectSeller({ buyerSellerId, buyerSeller }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["materialOffers"] });
      onOpenNotification(i18next.t("offer.rejectedApplication"));
    },
    onError: () => {
      onOpenNotification(i18next.t("offer.error"));
    },
  });

  const { mutate: validateSeller } = useMutation({
    mutationFn: (buyerSellerId: string) => onValidateSeller(buyerSellerId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["materialOffers"],
      }),
  });

  const { mutate: qualifySample, isPending: isQualifyingSample } = useMutation({
    mutationFn: ({ materialOfferId, body }: any) =>
      onQualifySample({ materialOfferId, body }),
    onSuccess: (res) => {
      onOpenNotification(
        res.state === SAMPLE_STATE.REJECTED
          ? i18next.t("offer.rejected")
          : i18next.t("offer.approved")
      );
      queryClient.invalidateQueries({ queryKey: ["materialOffers"] });
      setOpen(false);
    },
  });

  const { mutate: requestSample, isPending: isRequestingSample } = useMutation({
    mutationFn: (materialOfferId: string) => onRequestSample(materialOfferId),
    onSuccess: () => {
      onOpenNotification(i18next.t("offer.requestedSample"));
      queryClient.invalidateQueries({ queryKey: ["materialOffers"] });
      setOpen(false);
    },
    onError: () => onOpenNotification(i18next.t("offer.error")),
  });

  const { mutate: toggleMaterialStatus, isPending: isTogglingMaterialStatus } =
    useMutation({
      mutationFn: onToggleMaterialStatus,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["material"] });
      },
    });

  const {
    mutate: createMaterialOfferInvitation,
    isPending: isCreatingInvitation,
  } = useMutation({
    mutationFn: (body: any) => onCreateMaterialOfferInvitation(body),
    onSuccess: () => {
      onOpenNotification(i18next.t("invite.successSupplierInvite"));
      queryClient.invalidateQueries({ queryKey: ["materialOffers"] });
    },
    onError: () => onOpenNotification(i18next.t("newMaterialForm.error")),
  });

  const handleTabClick = (index: number) => setSelectedTab(index);

  const handleInviteSupplier = () => setOpenInvite(true);
  const onCloseInvitation = () => setOpenInvite(false);

  const handleDelegate = () => setOpenDelegate(true);
  const onCloseDelegate = () => setOpenDelegate(false);

  const handleRegain = () => {
    mutateMaterial({
      materialId: material?.id,
      body: {
        ...material,
        user,
        owner: { id: user?.id },
      },
    });
  };

  if (
    isLoadingMaterials ||
    isRejectingSeller ||
    isQualifyingSample ||
    isRequestingSample ||
    isCreatingInvitation ||
    isTogglingMaterialStatus
  )
    return <Loader isFullScreen />;

  const pnType = material?.category?.parent?.parent?.parent?.parent?.name;

  const isOwner = user?.id === material?.user?.id;
  const isResponsible = user?.id === material?.owner?.id;

  return (
    <Fragment>
      <Actions
        isOwner={isOwner}
        isResponsible={isResponsible}
        material={material}
        goToBidding={goToBidding}
        onClick={handleDelegate}
        onClickRegain={handleRegain}
        onToggleMaterialStatus={toggleMaterialStatus}
      />
      <div className="mx-auto px-4 lg:px-8 pb-8">
        <Tabs
          tabs={
            material?.volume && material?.type !== PN_TYPE.SERVICE
              ? tabs
              : npiTabs
          }
          handleTabClick={handleTabClick}
          selectedTab={selectedTab}
        />
      </div>
      <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-2">
        {selectedTab === 0 && (
          <Fragment>
            <div className="xl:pr-8 xl:border-r xl:border-gray-200 relative">
              <Detail material={material} pnType={pnType} pn_type={PN_TYPE} />
              {!isLoadingMaterialMarketInfo &&
                material?.type === PN_TYPE.PI && (
                  <div className="shadow border rounded p-2 relative my-1">
                    <p className="text-spectum-light absolute top-1 right-1 text-md font-bold shadow-2xl flex items-center justify-center border-2 border-spectum-dark rounded-full h-7 w-7">
                      AI
                    </p>
                    <FormattedText text={materialMarketInfo?.text} />
                  </div>
                )}
            </div>
            <ValidatedSuppliers
              materialId={id}
              countryId={
                material?.deliveryPlace?.country?.id ??
                material?.company?.country?.id
              }
              setOpen={setOpen}
              isActive={material?.active}
              onClick={handleInviteSupplier}
              materialOffers={materialOffers}
              setOpenSupplier={setOpenSupplier}
              setOpenChat={setOpenChat}
              countriesByMaterial={countriesByMaterial}
              setMaterialOfferToShow={setMaterialOfferToShow}
              setPotencialSupplierToShow={setPotencialSupplierToShow}
            />
          </Fragment>
        )}
      </div>
      {selectedTab === 1 && material?.type !== PN_TYPE.SERVICE && (
        <PricingVolume
          material={material}
          isOwner={isOwner}
          isResponsible={isResponsible}
        />
      )}
      {selectedTab === 1 && material?.type === PN_TYPE.SERVICE && (
        <BiddingList materialId={material?.id} />
      )}
      {selectedTab === 2 && <BiddingList materialId={material?.id} />}
      <Slide open={open} setOpen={setOpen}>
        {materialOfferToShow && (
          <MaterialOffer
            setOpen={() => setOpen(false)}
            onRejectSeller={rejectSeller}
            onApproveSample={qualifySample}
            onRejectSample={qualifySample}
            onRequestSample={requestSample}
            materialOffer={materialOfferToShow}
            onValidateSeller={validateSeller}
            onChangeToggle={() => setOpen(false)}
          />
        )}
      </Slide>
      <Slide open={openInvite} setOpen={setOpenInvite}>
        <InviteSupplier materialId={id} onCloseInvitation={onCloseInvitation} />
      </Slide>
      <Slide open={openDelegate} setOpen={setOpenDelegate}>
        <Delegate material={material} onCloseDelegate={onCloseDelegate} />
      </Slide>
      <Slide open={openSupplier} setOpen={setOpenSupplier}>
        {potencialSupplierToShow && (
          <PotencialSupplier
            materialId={id}
            isLoadingMaterialOffer={isLoadingMaterialOffer}
            onCreateMaterialOfferInvitation={createMaterialOfferInvitation}
            potencialSupplier={potencialSupplierToShow}
            setOpenSupplier={() => setOpenSupplier(false)}
          />
        )}
      </Slide>
      <Slide open={openChat} setOpen={setOpenChat}>
        <Chat
          chat={chat}
          seller={materialOfferToShow}
          material={material}
          onCreateChat={mutateChat}
          onCreateMessage={mutateMessage}
          isLoadingChat={
            isLoadingChat || isLoadingMessage || isLoadingCreateChat
          }
        />
      </Slide>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Material);
