import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";
import { eraseCookie, getCookie, setCookie } from "../../utils";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (data: any) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    data,
  };
};

export const authFailed = (error: any) => {
  return {
    type: actionTypes.AUTH_FAILED,
    error: error,
  };
};

export const setAuthRedirectPath = (path: string) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path,
  };
};

export const authLogout = () => {
  eraseCookie("accessToken");
  eraseCookie("userId");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = (navigate: any, location: any) => {
  return (dispatch: any) => {
    const token = getCookie("accessToken");
    const userId = getCookie("userId");

    const currentPath = location.pathname;
    if (currentPath === "/") return;

    if (!token || !userId) {
      dispatch(authLogout());
      if (
        location.pathname !== "/register" &&
        location.pathname !== "/new-password" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/validate-user"
      ) {
        navigate("/");
      }
    }

    if (userId && token) dispatch(authSuccess({ token, userId }));
  };
};

export const authSignIn = (body: any) => {
  return (dispatch: any) => {
    dispatch(authStart());
    axios
      .post("/auth/login", body)
      .then((res) => {
        const resData = res.data;
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${resData?.accessToken}`;
        if (resData) {
          if (resData.errors) {
            return dispatch(authFailed(resData));
          }
          setCookie("accessToken", resData?.accessToken, 1);
          setCookie("userId", resData?.userId, 1);
          dispatch(
            authSuccess({
              accessToken: resData?.accessToken,
              userId: resData?.userId,
              tokenType: resData?.tokenType,
            })
          );
        }
      })
      .catch((err) => {
        const error = err?.response?.data;
        dispatch(authFailed(error));
      });
  };
};

export const authSignUp = (body: any) => {
  return (dispatch: any) => {
    dispatch(authStart());
    axios
      .post("/auth/signup", body)
      .then((res) => {
        const resData = res;
        if (resData?.data.errors) {
          return dispatch(authFailed(resData));
        }
        return dispatch(authSuccess(resData));
      })
      .catch((err) => {
        dispatch(authFailed(err));
      });
  };
};
