import axios from "../axios-orders";
import { MaterialType, MaterialWithSuppliersPaginatedResponse } from "../types";

export type MaterialOpportunitiesByLastIdType = {
  countryIds: string[];
  page: number;
  type: MaterialType;
  categoryIds: string[];
};

export type SearchMaterialOpportunitiesByLastIdType = {
  query: string;
  page: number;
  type: MaterialType;
  categoryIds: string[];
  countryIds: string[];
};

export default function useMaterials() {
  const onGetMaterials = async (
    type: MaterialType,
    page = 0
  ): Promise<MaterialWithSuppliersPaginatedResponse> => {
    const { data } = await axios.get(`/material/type/${type}/${page}`);
    return data;
  };

  const onGetAllCompanyMaterials = async (
    type?: MaterialType | string,
    page = 0
  ) => {
    if (!type) return;
    const { data } = await axios.get(`/material/company/type/${type}/${page}`);
    return data;
  };

  const onGetMaterial = async (materialId: string) => {
    const { data } = await axios.get(`/material/${materialId}`);
    return data;
  };

  const onToggleMaterialStatus = async (materialId: string) => {
    const { data } = await axios.get(`/material/toggleActivate/${materialId}`);
    return data;
  };

  const onSearchMaterials = async (query: string, page = 0) => {
    const { data } = await axios.get(`/material/search/${query}/${page}`);
    return data;
  };

  const onSearchAllMaterials = async (
    type: MaterialType,
    query: string,
    page = 0
  ) => {
    const { data } = await axios.get(
      `/material/company/search/${type}/${query}/${page}`
    );
    return data;
  };

  const onCreateMaterial = async (body: any) => {
    const { data } = await axios.post("/material", body);
    return data;
  };

  const onEditMaterial = async ({
    materialId,
    body,
  }: {
    materialId: string;
    body: any;
  }) => {
    const { data } = await axios.put(`/material/${materialId}`, body);
    return data;
  };

  const onGetMaterialsByOwner = async (
    page = 0
  ): Promise<MaterialWithSuppliersPaginatedResponse> => {
    const { data } = await axios.get(`/material/list/user/${page}`);
    return data;
  };

  const onDelegateAllMaterials = async (userId: string) => {
    const { data } = await axios.put(`/material/delegate/list/${userId}`);
    return data;
  };

  const onGetBackAllMaterials = async () => {
    const { data } = await axios.put(`/material/delegate/list`);
    return data;
  };

  const onGetOpportunitiesByLastIds = async ({
    countryIds,
    page = 0,
    type = MaterialType.PI,
    categoryIds,
  }: MaterialOpportunitiesByLastIdType) => {
    const { data } = await axios.post(
      `/material/categories/search/${page}/${type}/last?countryIds=${countryIds.join(
        ","
      )}`,
      categoryIds
    );
    return data;
  };

  const onSearchOpportunitiesByLastIds = async ({
    query,
    page = 0,
    type,
    categoryIds,
    countryIds,
  }: SearchMaterialOpportunitiesByLastIdType) => {
    const { data } = await axios.post(
      `/material/categories/search/${page}/${type}/last?query=${query}&countryIds=${countryIds.join(
        ","
      )}`,
      categoryIds
    );
    return data;
  };

  const onGetAllMaterialsByCompanyAndUser = async (
    userId: string,
    page = 0
  ) => {
    const { data } = await axios.get(`/material/company/${userId}/${page}`);
    return data;
  };

  return {
    onGetMaterial,
    onEditMaterial,
    onGetMaterials,
    onCreateMaterial,
    onSearchMaterials,
    onGetMaterialsByOwner,
    onGetBackAllMaterials,
    onToggleMaterialStatus,
    onDelegateAllMaterials,
    onSearchAllMaterials,
    onGetAllCompanyMaterials,
    onGetOpportunitiesByLastIds,
    onSearchOpportunitiesByLastIds,
    onGetAllMaterialsByCompanyAndUser,
  };
}
