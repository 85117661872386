import i18next from "../../i18n";
import SitesTableRow from "./SitesTableRow/SitesTableRow";
import { SiteResponse } from "../../types";

const sitesTh = [
  { id: 1, name: i18next.t("sites.code") },
  { id: 2, name: i18next.t("sites.name") },
  { id: 3, name: i18next.t("sites.address") },
  { id: 4, name: i18next.t("sites.comment") },
  { id: 5, name: "" },
];

const SitesTable = ({ sites }: { sites: SiteResponse[] }) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {sitesTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sites?.map((site) => (
                <SitesTableRow key={site.id} site={site} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SitesTable;
