import { Link } from "react-router-dom";
import { Pill } from "../..";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { PILL_LABELS, BIDDING_STATES, BIDDING_LABELS } from "../../../utils";
import { OrderResponse } from "../../../types";

const BidTableRow = ({ order }: { order: OrderResponse }) => {
  const pillResolver = () => {
    if (order.state === BIDDING_STATES.SUCCEED)
      return PILL_LABELS.OFFER_SELECTED;
    else if (order.state === BIDDING_STATES.PENDING)
      return BIDDING_LABELS.SCHEDULED;
    else if (order.state === BIDDING_STATES.OPEN)
      return BIDDING_LABELS.IN_PROGRESS;
    else if (order.state === BIDDING_STATES.CLOSED)
      return PILL_LABELS.OFFER_PENDING_OF_SELECTION;
    else if (order.state === BIDDING_STATES.CANCELLED)
      return PILL_LABELS.BIDDING_CANCELED;
    else return "";
  };

  return (
    <tr key={order.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-900 uppercase font-bold">
        <Link to={`/order/${order.id}`}>{order?.name}</Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {order.quantity} {order.measurementUnit}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">{order.startDate}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">{order.finishDate}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill label={pillResolver()} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
        <Link
          to={`/order/${order.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default BidTableRow;
