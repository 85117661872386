import { Fragment, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  ExclamationTriangleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import {
  AwardJoint,
  GoBackArrow,
  Loader,
  Modal,
  Offer,
  Slide,
  TenderSucceeded,
  Chat,
} from "../../components";
import { useChat, useID, useOffers, useOrders } from "../../hooks";
import OffersOnMultipleOrders from "./OffersOnMultipleOrders";
import SuppliersSelected from "./SuppliersSelected";
import BidHeader from "./BidHeader";
import MultipleBiddingSummary from "../Order/MultipleBiddingSummary/MultipleBiddingSummary";
import BidResultTable from "./BidResultTable";
import { JointOfferResponse, OfferResponse } from "../../types";
import {
  mapDispatchToProps,
  BIDDING_STATES,
  mapStateToProps,
} from "../../utils";

const IsJointBuyerBid = ({
  isCompliant,
  onOpenNotification,
}: {
  isCompliant: boolean;
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const id = useID();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openSlide, setOpenSlide] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");
  const [itemToSlide, setItemToSlide] = useState(null);
  const [isAward, setIsAward] = useState(false);

  const multipleOrderId = searchParams.get("multipleOrderId");

  const { onGetMultipleOffersOnOrders, onAcceptOffer } = useOffers();
  const { onDeleteMultipleOrders, onGetJointOrder } = useOrders();
  const { onGetMultipleOrderChat, onCreateChat, onCreateMessage } = useChat();

  const queryClient = useQueryClient();

  const { data: multipleOrderChat, isLoading: isLoadingChat } = useQuery({
    queryKey: ["multipleOrderChat", multipleOrderId, id],
    queryFn: () => multipleOrderId && onGetMultipleOrderChat(multipleOrderId),
    enabled: !!multipleOrderId,
    retry: false,
  });

  const { data: jointOrder, isLoading: isLoadingOrders } = useQuery({
    queryKey: ["jointOrder", id],
    queryFn: () => onGetJointOrder(id),
    enabled: !!id,
  });

  const { data: multipleOffersOnOrders, isLoading: isLoadingOffers } =
    useQuery<JointOfferResponse>({
      queryKey: ["multipleOffersOnOrders", id, multipleOrderId || ""],
      queryFn: () => onGetMultipleOffersOnOrders(multipleOrderId || ""),
      enabled: !!multipleOrderId,
    });

  const { mutate: acceptOffer, isPending: isAccepting } = useMutation({
    mutationFn: (offers: any) => onAcceptOffer(offers),
    onSuccess: () => {
      setIsAward(false);
      onOpenNotification(t("tenders.data.acceptOffer"));
      return queryClient.invalidateQueries({
        queryKey: ["multipleOffersOnOrders"],
      });
    },
    onError: () => onOpenNotification(t("newMaterialForm.error")),
  });

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useMutation({
    mutationFn: (body) => onCreateChat(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["multipleOrderChat"] });
    },
  });

  const { mutate: mutateMessage, isPending: isLoadingMessage } = useMutation({
    mutationFn: (body) => onCreateMessage(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["multipleOrderChat"] });
    },
  });

  const { mutate: deleteMultipleOrders, isPending: isDeletingOrders } =
    useMutation({
      mutationFn: (body: any) => onDeleteMultipleOrders(body),
      onSuccess: () => {
        onOpenNotification(t("tenders.data.cancelMessage"));
        queryClient.invalidateQueries({ queryKey: ["multipleOrderChat"] });
        return navigate("/orders");
      },
      onError: () => onOpenNotification(t("newMaterialForm.error")),
    });

  const multipleOffers = multipleOffersOnOrders
    ? (Object.values(multipleOffersOnOrders) as [OfferResponse[]])
    : ([[]] as [OfferResponse[]]);

  // Flatten the multipleOffers array
  const flattenedOffers = multipleOffers?.flat() || [];

  const alignedOffers = jointOrder?.map((order: any) => {
    return (
      flattenedOffers.find(
        (offer: any) => offer.order.material.id === order.material.id
      ) || null
    );
  });

  // Determine unique material IDs and their names
  const materials = alignedOffers?.reduce(
    (acc: any, offer: OfferResponse, idx: number) => {
      if (offer) {
        const { id, globalMaterialName } = offer.order.material;
        if (!acc[id]) {
          acc[id] = globalMaterialName;
        }
      } else {
        const { id, globalMaterialName } = jointOrder[idx].material;
        acc[id] = globalMaterialName;
      }
      return acc;
    },
    {}
  );

  const materialIds = materials && Object.keys(materials);

  // Create a map of materialId to index
  const materialIndexMap = materialIds?.reduce(
    (acc: any, id: string, index: number) => {
      acc[id] = index;
      return acc;
    },
    {}
  );

  // Initialize the cols object with arrays of the same length
  const cols: any = {};

  // Iterate over the flattened offers to populate the matrix
  flattenedOffers?.forEach((offer: any) => {
    const userId = offer.user.id;
    const materialId = offer.order.material.id;
    const materialName = offer.order.material.globalMaterialName;
    const index = materialIndexMap[materialId];

    if (!cols[userId]) {
      // Initialize the array with the corresponding materials if it doesn't exist
      cols[userId] = materialIds.map((id: string) => ({
        material: id,
        materialName: materials[id],
        seller: null,
        offer: null,
      }));
    }

    // Add the offer object to the correct index in the array
    cols[userId][index] = {
      material: materialId,
      materialName,
      seller: offer.user.company.name,
      offer: offer,
    };
  });

  const handleCancel = () => {
    if (!cancelMessage || !jointOrder || jointOrder.length === 0) return;

    const cancelledOrders = jointOrder.map((order: any) => ({
      ...order,
      state: BIDDING_STATES.CANCELLED,
      comment: cancelMessage,
    }));

    if (Array.isArray(cancelledOrders) && cancelledOrders.length > 0) {
      deleteMultipleOrders(cancelledOrders);
    }

    setOpen(false);
  };

  const handleItemClick = (item: any) => {
    if (jointOrder[0]?.state === BIDDING_STATES.OPEN && isCompliant) return;
    setItemToSlide(item);
    setOpenSlide(true);
  };

  const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCancelMessage(e.target.value);

  const selectedOffers = useMemo(() => {
    const filteredOffers = multipleOffers?.flatMap((offers: any) =>
      offers.filter((offer: any) => offer.selected)
    );
    return filteredOffers?.sort((a, b) =>
      a.order.material.globalMaterialName.localeCompare(
        b.order.material.globalMaterialName
      )
    );
  }, [multipleOffers]);

  const removeDuplicatesByUserId = (array: any) => {
    const unique = array.reduce((acc: any, offer: any) => {
      if (!acc.has(offer.user.id)) {
        acc.set(offer.user.id, offer);
      }
      return acc;
    }, new Map());

    return Array.from(unique.values());
  };

  const selectedOffersSuppliers = removeDuplicatesByUserId(selectedOffers);

  const findOrdersWithoutOffers = () => {
    const selectedOrdersIds = new Set(
      selectedOffers.map((offer) => offer.order.id)
    );
    // Filter jointOrders to find those that are not in offeredOrderIds
    const ordersWithoutOffers = jointOrder?.filter(
      (order: any) => !selectedOrdersIds.has(order.id)
    );

    return ordersWithoutOffers;
  };

  const ordersWithoutOffers = jointOrder && findOrdersWithoutOffers();

  if (jointOrder) {
    return (
      <Fragment>
        <Modal
          open={open}
          setOpen={setOpen}
          hasMessage
          disabled={!cancelMessage}
          onChangeMessage={onChangeMessage}
          icon={
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          }
          title={t("tenders.data.cancel")}
          message={t("tenders.data.cancelQuestion")}
          isCancel
          onClickSuccess={handleCancel}
          onClickCancel={() => setOpen(false)}
        />
        {isLoadingOrders ||
        isLoadingOffers ||
        isAccepting ||
        isDeletingOrders ? (
          <Loader isFullScreen />
        ) : (
          <div className="min-h-full flex">
            <div className="flex flex-col w-0 flex-1">
              <div className="py-8 xl:py-10 relative">
                <div className="w-full px-8 flex justify-between items-center gap-4">
                  <GoBackArrow />
                  {!isAward ? (
                    <div className="flex gap-4 items-center w-full justify-end">
                      {jointOrder[0]?.state !== BIDDING_STATES.CANCELLED &&
                        jointOrder[0]?.state !== BIDDING_STATES.SUCCEED && (
                          <button
                            className="uppercase px-4 py-2 shadow-sm text-xs font-medium rounded text-white border bg-spectum-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                            onClick={() => setOpen(true)}
                          >
                            <span>{t("tenders.data.cancel")}</span>
                          </button>
                        )}
                      <button
                        onClick={() => setOpenChat(true)}
                        className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                      >
                        <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                        {t("chat.title")}
                        {multipleOrderChat?.messages?.length > 0 && (
                          <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                            {multipleOrderChat.messages.length}
                          </span>
                        )}
                      </button>
                      {jointOrder[0]?.state === BIDDING_STATES.CLOSED &&
                      multipleOffers?.length ? (
                        <button
                          onClick={() => setIsAward(true)}
                          className="uppercase px-4 py-2 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                        >
                          {t("tenders.data.accept")}
                        </button>
                      ) : null}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="uppercase inline-flex justify-center px-4 py-2 shadow-sm text-xs font-medium rounded text-black bg-white border border-black"
                      onClick={() => setIsAward(false)}
                    >
                      {t("cta.cancel")}
                    </button>
                  )}
                </div>
                <div className="mx-auto px-4 lg:px-8 gap-4 grid grid-cols-3">
                  <div className="col-span-3">
                    <BidHeader
                      order={jointOrder[0]}
                      orderName={jointOrder[0]?.name}
                      ean={jointOrder[0]?.material?.ean}
                      setOpen={setOpen}
                    />

                    {jointOrder[0]?.state === BIDDING_STATES.SUCCEED &&
                      selectedOffers && (
                        <>
                          <BidResultTable
                            selectedOffers={selectedOffers}
                            ordersWithoutOffers={ordersWithoutOffers}
                            title={t("tenders.seller.acceptedJoint")}
                          />
                          <div className="grid grid-cols-3 gap-2">
                            {selectedOffersSuppliers.map((t: any) => (
                              <TenderSucceeded key={t.id} tender={t} />
                            ))}
                          </div>
                          <hr />
                        </>
                      )}

                    {!isAward ? (
                      <>
                        <MultipleBiddingSummary
                          generalBidData={jointOrder[0]}
                          orderSummary={jointOrder}
                          type={jointOrder[0]?.type}
                          isBid
                          specificationName={jointOrder[0]?.plicationUrl}
                        />
                      </>
                    ) : (
                      <AwardJoint
                        offers={cols}
                        order={jointOrder}
                        onAcceptOffer={acceptOffer}
                        onCancel={() => setIsAward(false)}
                        setIsAward={() => setIsAward(false)}
                      />
                    )}
                  </div>
                  {!isAward ? (
                    <div className="col-span-3">
                      <div className="flex gap-2">
                        <div className="w-full">
                          <h2
                            id="activity-title"
                            className="text-lg font-medium text-gray-900 border-b pb-4"
                          >
                            {t("tenders.data.receivedOffers")}
                          </h2>
                          {jointOrder[0]?.state === BIDDING_STATES.CLOSED &&
                            multipleOffers[0]?.length === 0 && (
                              <p className="uppercase text-sm font-bold text-red-800 rounded py-2 mb-4">
                                {t("tenders.data.noOffersMessage")}
                              </p>
                            )}
                          <OffersOnMultipleOrders
                            offOnOrd={multipleOffers}
                            handleItemClick={handleItemClick}
                            state={jointOrder[0]?.state}
                            isCompliant={isCompliant}
                          />
                        </div>
                        {jointOrder[0]?.state === BIDDING_STATES.CANCELLED && (
                          <p className="text-sm font-bold text-red-800 uppercase py-2">
                            {t("tenders.data.cancelledMessage")}{" "}
                            {jointOrder[0]?.comment}
                          </p>
                        )}

                        <SuppliersSelected
                          suppliers={jointOrder[0]?.suppliers}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
        <Slide open={openSlide} setOpen={setOpenSlide}>
          {itemToSlide && (
            <Offer offer={itemToSlide} type={jointOrder[0]?.type} />
          )}
        </Slide>
        <Slide open={openChat} setOpen={setOpenChat}>
          <Chat
            isOrder
            isMultiple
            chat={multipleOrderChat}
            order={jointOrder[0]}
            onCreateChat={mutateChat}
            isLoadingChat={
              isLoadingChat || isLoadingMessage || isLoadingCreateChat
            }
            onCreateMessage={mutateMessage}
          />
        </Slide>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IsJointBuyerBid);
