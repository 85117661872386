import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoSpectum from "../../../assets/images/logo-simbuy-white.png";
import { Navigation } from "../../../types";

type NavbarProps = {
  classNames: any;
  navigation: Navigation[];
  secondaryNavigation: Navigation[];
};

const Navbar = ({
  classNames,
  navigation,
  secondaryNavigation,
}: NavbarProps) => {
  const location = useLocation();
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
      <div className="flex flex-col flex-grow bg-spectum-ultraDark pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <Link to="/">
            <img src={LogoSpectum} alt="Spectum" />
          </Link>
        </div>
        <nav
          className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto"
          aria-label="Sidebar"
        >
          <div className="px-2 space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.href === url
                    ? "bg-cyan-800 text-white"
                    : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                  "group flex items-center px-2 py-2 text-xs leading-6 font-medium rounded uppercase"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className="mr-4 flex-shrink-0 h-4 w-4 text-cyan-200"
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            ))}
          </div>
          <div className="mt-6 pt-6">
            <div className="px-2 space-y-1">
              {secondaryNavigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="uppercase group flex items-center px-2 py-2 text-xs leading-6 font-medium rounded text-cyan-100 hover:text-white hover:bg-cyan-600"
                >
                  <item.icon
                    className="mr-4 h-4 w-4 text-cyan-200"
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
