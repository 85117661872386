import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { connect } from "react-redux";
import { ErrorPill, Loader } from "../..";
import { useSuppliers } from "../../../hooks";
import { mapDispatchToProps } from "../../../utils";
import { InvitationResponse } from "../../../types";

type InviteSupplierProps = {
  isJoint?: boolean;
  isOrder?: boolean;
  materialId: string;
  materialName?: string;
  onOpenNotification: (message: string) => void;
  onCloseInvitation: () => void;
  onAddUserToInvites?: (invite: any) => void;
  orderInvitation?: any[];
  handleOrdersToSubmit?: (invite: any, type?: string) => void;
};

const InviteSupplier = ({
  isOrder,
  isJoint,
  materialId,
  materialName,
  orderInvitation,
  onCloseInvitation,
  onAddUserToInvites,
  onOpenNotification,
  handleOrdersToSubmit,
}: InviteSupplierProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successOrderInvite, setSuccessOrderInvite] = useState(false);
  const [hasError, setHasError] = useState<any>(null);

  const { onInviteSupplier, onGetInvitedMaterialSuppliers } = useSuppliers();

  const queryClient = useQueryClient();

  const { data: invitedMaterialSuppliers } = useQuery<InvitationResponse[]>({
    queryKey: ["invitedMaterialSuppliers", materialId],
    queryFn: () => onGetInvitedMaterialSuppliers(materialId),
    enabled: !!materialId,
  });

  const {
    data: newInvitation,
    mutate: mutateInviteSupplier,
    isPending,
  } = useMutation({
    mutationFn: ({
      emails,
      materialId,
    }: {
      emails: string;
      materialId: string;
    }) => onInviteSupplier(emails, materialId),
    onSuccess: (res) => {
      if (res?.find((el: any) => el.error)) {
        setHasError(res?.find((el: any) => el.error));
      }
      setEmails([]);
      onOpenNotification(t("invite.success"));
      return queryClient.invalidateQueries({
        queryKey: ["invitedMaterialSuppliers"],
      });
    },
    onError: () => {
      onOpenNotification(t("offer.error"));
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setError(null);
    setHasError(null);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      const email = value.trim().toLowerCase();

      if (email && isValid(email)) {
        setEmails((prevState) => [...prevState, email]);
        setValue("");
      }
    }
  };

  const handleDelete = (toBeRemoved: string) => {
    setEmails((prevState) =>
      prevState.filter((email) => email !== toBeRemoved)
    );
    setError(null);
    setHasError(null);
  };

  const isValid = (email: string) => {
    let error = null;
    if (isInList(email)) {
      error = `${email} ${t("invite.already")}`;
    }
    if (!isEmail(email)) {
      error = `${email} ${t("invite.notValid")}`;
    }
    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const isInList = (email: string) => {
    return emails.includes(email);
  };

  const isEmail = (email: string) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };

  const handleOrderInvitation = (invite: any) => {
    setIsLoading(true);
    if (onAddUserToInvites) {
      onAddUserToInvites(invite);
    }
    if (isOrder && isJoint && handleOrdersToSubmit) {
      handleOrdersToSubmit(invite, "isJoint");
    } else if (isOrder && handleOrdersToSubmit) {
      handleOrdersToSubmit(invite);
    }
    setTimeout(() => {
      setIsLoading(false);
      setSuccessOrderInvite(true);
    }, 1000);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const emailsList = emails.join(":");
    const buildInvite = {
      materialId,
      emailsList,
    };
    isOrder
      ? handleOrderInvitation(buildInvite)
      : mutateInviteSupplier({ emails: emailsList, materialId });
  };

  const invitedToOrders = () => {
    const order = orderInvitation?.map((item) => {
      if (item.materialId === materialId) {
        const list = item.emailsList.toLowerCase().split(":");
        return list.map((email: string) => (
          <p className="text-xs pb-1" key={email}>
            {email}
          </p>
        ));
      }
    });

    return (
      <div className="mt-4">
        <p className="uppercase block text-xs font-medium text-gray-700 pb-2">
          {t("invite.pendingOrder")}
        </p>
        {order}
      </div>
    );
  };

  if (isPending || isLoading) return <Loader isFullScreen />;

  if (isOrder && successOrderInvite) {
    onOpenNotification(t("invite.success"));
    onCloseInvitation();
    setSuccessOrderInvite(false);
  }

  return (
    <div>
      {hasError ? <ErrorPill list={newInvitation.data} /> : null}
      {materialName && (
        <h2 className="text-lg text-spectum font-bold uppercase">
          {materialName}
        </h2>
      )}
      <p className="text-sm uppercase text-spectum pb-4">
        {t("invite.invite")}
      </p>
      <form onSubmit={handleSubmit}>
        <label className="uppercase block text-xs font-medium text-gray-700 pb-2">
          {t("invite.message")}
        </label>
        {emails.map((email) => (
          <div key={email}>
            <span className="inline-flex items-center rounded-full bg-indigo-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-spectum-dark">
              {email}
              <button
                type="button"
                onClick={() => handleDelete(email)}
                className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
              >
                <span className="sr-only">Remove large option</span>
                <svg
                  className="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          </div>
        ))}
        <input
          placeholder="Ingresar un email"
          type="email"
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          className="mt-4 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
        />
        {error ? <p className="text-xs text-red-500">{error}</p> : null}
        <button
          className={`inline-flex justify-center mt-8 py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded ${
            !(emails.length > 0)
              ? "bg-gray-400 hover:bg-gray-400 cursor-not-allowed"
              : "bg-spectum hover:bg-spectum-light focus:ring-spectum-light"
          } text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  uppercase`}
          disabled={!(emails.length > 0)}
        >
          {t("invite.send")}
        </button>
      </form>
      {!isOrder &&
        invitedMaterialSuppliers &&
        invitedMaterialSuppliers?.filter((sup) => sup.used === false)?.length >
          0 && (
          <div className="pt-10">
            <p className="uppercase block text-xs font-medium text-gray-700 pb-2">
              {t("invite.pending")}
            </p>
            <ul className="text-xs">
              {invitedMaterialSuppliers
                ?.filter((sup) => sup.used === false)
                .map((sup) => <li key={sup.id}>{sup.email}</li>)}
            </ul>
          </div>
        )}
      {orderInvitation && orderInvitation?.length > 0 && invitedToOrders()}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(InviteSupplier);
