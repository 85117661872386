import i18next from "../../i18n";
import MaterialOfferTableRow from "./MaterialOfferTableRow/MaterialOfferTableRow";
import { TableTitle, Pagination } from "..";
import { MaterialOffersPaginatedResponse } from "../../types";

const offersTh = [
  { id: 1, name: i18next.t("offer.material") },
  { id: 2, name: i18next.t("offer.origin") },
  { id: 3, name: i18next.t("offer.sample") },
  { id: 4, name: i18next.t("offer.spec") },
  { id: 5, name: "" },
];

const allOffersTh = [
  { id: 1, name: i18next.t("offer.material") },
  { id: 2, name: i18next.t("offer.origin") },
  { id: 3, name: i18next.t("offer.sample") },
  { id: 4, name: i18next.t("offer.spec") },
  { id: 5, name: i18next.t("users.responsible") },
  { id: 6, name: "" },
];

type MaterialOfferTableProps = {
  materialOffers: MaterialOffersPaginatedResponse;
  pageNumber: number;
  setPageNumber: (value: number) => void;
  isCompany?: boolean;
};

const MaterialOfferTable = ({
  materialOffers,
  pageNumber,
  setPageNumber,
  isCompany,
}: MaterialOfferTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col">
        {isCompany && (
          <TableTitle title={i18next.t("offer.companyApplications")} />
        )}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {isCompany
                  ? allOffersTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))
                  : offersTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {materialOffers?.content?.map((off) => (
                <MaterialOfferTableRow
                  key={off.id}
                  offer={off}
                  isCompany={isCompany}
                />
              ))}
            </tbody>
          </table>
          <Pagination
            results={materialOffers.numberOfElements}
            total={materialOffers.totalElements}
            amountOfPages={materialOffers.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default MaterialOfferTable;
