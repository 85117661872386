import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SpectumLogo from "../../../assets/images/logo-simbuy-white.png";
import { classNames } from "../../../utils";
import { Navigation } from "../../../types";

type MobileMenuProps = {
  setSidebarOpen: any;
  sidebarOpen: boolean;
  navigation: Navigation[];
  secondaryNavigation: Navigation[];
};

const MobileMenu = ({
  sidebarOpen,
  navigation,
  setSidebarOpen,
  secondaryNavigation,
}: MobileMenuProps) => {
  const [active, setActive] = useState("/");

  const handleNavItems = (item: Navigation) => {
    setActive(item.href);
    setSidebarOpen(false);
  };

  return (
    <Transition show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 lg:hidden"
        onClose={setSidebarOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-spectum-ultraDark">
            <TransitionChild
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>
            <div className="flex-shrink-0 flex items-center px-4">
              <img className="w-full" src={SpectumLogo} alt="Spectum" />
            </div>
            <nav
              className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    onClick={() => handleNavItems(item)}
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.href === active
                        ? "bg-cyan-800 text-white"
                        : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                      "group flex items-center px-2 py-2 text-xs font-medium rounded uppercase"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className="mr-4 flex-shrink-0 h-4 w-4 text-cyan-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      className="uppercase group flex items-center px-2 py-2 text-base font-medium rounded text-cyan-100 hover:text-white hover:bg-cyan-600"
                      rel="noreferrer"
                    >
                      <item.icon
                        className="mr-4 h-4 w-4 text-cyan-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default MobileMenu;
