import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader, UsersTable } from "../../components";
import { useUsers } from "../../hooks";
import { mapStateToProps } from "../../utils";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { UserResponse } from "../../types";

type UsersProps = {
  isVisualizer: boolean;
  user: UserResponse;
};

const Users = ({ isVisualizer, user }: UsersProps) => {
  const { t } = useTranslation();
  const { onGetUsersByCompany } = useUsers();

  const { data: usersByCompany, isLoading } = useQuery<UserResponse[]>({
    queryKey: ["usersByCompany"],
    queryFn: () => onGetUsersByCompany(),
  });

  return (
    <div className="px-4 mt-6">
      <div className="md:flex items-center justify-between">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {t("users.title")}
        </h2>
        {!isVisualizer ? (
          <Link
            to="/users/create"
            type="button"
            className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
          >
            {t("users.add")}
          </Link>
        ) : null}
      </div>
      {isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {usersByCompany?.filter((u) => u.id !== user.id)?.length === 0 ? (
            <h4 className="text-xs leading-7 text-gray-900 sm:truncate pt-10">
              {t("users.noUsers")}
            </h4>
          ) : (
            <>
              {usersByCompany && (
                <UsersTable
                  usersByCompany={usersByCompany?.filter(
                    (u) => u.id !== user.id
                  )}
                />
              )}
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Users);
