import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  mapDispatchToProps,
  mapStateToProps,
  newSiteValidationSchema,
} from "../../utils";
import { Loader, Modal, InputField, SiteAutocomplete, Button } from "..";
import { useCountries, useLanguage, useSites } from "../../hooks";
import { CountryResponse, UserResponse } from "../../types";

type FormValues = {
  internalCode: string;
  name: string;
  comment?: string;
  country: string;
};

const NewSiteForm = ({
  user,
  onOpenNotification,
}: {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const langEs = useLanguage();
  const formOptions = {
    resolver: yupResolver(newSiteValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState<any>({});
  const { onCreateSite } = useSites();
  const { onGetPublicCountries } = useCountries();

  const { data: countries, isLoading: isLoadingCountries } = useQuery<
    CountryResponse[]
  >({
    queryKey: ["countries"],
    queryFn: () => onGetPublicCountries(),
  });

  const {
    mutate: createSite,
    data: newSite,
    isPending: isLoadingSites,
  } = useMutation({
    mutationFn: (body: any) => onCreateSite(body),
    onSuccess: () => {
      onOpenNotification(t("sites.success"));
      return navigate("/sites");
    },
    onError: () => {
      onOpenNotification(t("newMaterialForm.error"));
    },
  });

  const onSubmit = (data: FormValues) => {
    if (!address) {
      setAddress("sites.errorPlace");
      return;
    }
    createSite({
      ...data,
      company: { id: user?.company?.id },
      address: address?.formatted_address,
      googlePlaceId: address?.place_id,
      country: { id: data.country },
    });
    setOpen(false);
  };
  const onError = () => setOpen(false);

  if (isLoadingSites || isLoadingCountries) return <Loader isFullScreen />;

  return (
    <div className="px-4 mt-6">
      <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
        {t("sites.add")}
      </h2>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden sm:rounded p-4">
              <div className="pb-4">
                <InputField
                  id="internalCode"
                  name="internalCode"
                  type="text"
                  label={t("sites.code")}
                  errorName={errors?.internalCode || newSite?.message}
                  errorMessage={
                    newSite?.message
                      ? t("sites.codeExists")
                      : errors?.internalCode?.message
                  }
                  required
                  register={register}
                />
              </div>

              <div className="pb-4">
                <InputField
                  id="name"
                  name="name"
                  type="text"
                  label={t("sites.name")}
                  errorName={errors?.name}
                  errorMessage={errors?.name?.message}
                  required
                  register={register}
                />
              </div>

              <div>
                <SiteAutocomplete setAddress={setAddress} />
                {address === "sites.errorPlace" && (
                  <span className="text-xs text-red-500">{t(address)}</span>
                )}
              </div>

              <div className="pt-4">
                <label
                  htmlFor="country"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {t("sites.country")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <select
                  {...register("country", { required: true })}
                  id="country"
                  name="country"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  defaultValue=""
                >
                  <option value="">{t("validations.country")}</option>
                  {countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {langEs ? country.name : country.nameEn}
                    </option>
                  ))}
                </select>
                {errors?.country && (
                  <span className="text-xs text-red-500">
                    {errors?.country?.message}
                  </span>
                )}
              </div>

              <div className="pt-4">
                <InputField
                  id="comment"
                  name="comment"
                  type="text"
                  label={t("sites.comment")}
                  register={register}
                />
              </div>

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex gap-2 justify-end">
                <Button link to="/sites" variant="cancel">
                  {t("cta.cancel")}
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  {t("cta.add")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("sites.add")}
        message={t("sites.addQuestion")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSiteForm);
