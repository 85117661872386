import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { OrdersTable, Loader } from "../../../components";
import {
  ORDER_TYPES,
  OrderState,
  SimpleOrderPaginatedResponse,
} from "../../../types";

type ClosedProps = {
  onGetOrders: (
    state: OrderState,
    type: ORDER_TYPES,
    _type: ORDER_TYPES,
    page: number
  ) => Promise<SimpleOrderPaginatedResponse>;
};

export const Closed = ({ onGetOrders }: ClosedProps) => {
  const [closedPageNumber, setClosedPageNumber] = useState(0);

  const orders = useQuery({
    queryKey: ["orders", closedPageNumber],
    queryFn: () =>
      onGetOrders(
        OrderState.CLOSED,
        ORDER_TYPES.NORMAL,
        ORDER_TYPES.INVERSE,
        closedPageNumber
      ),
  });

  const handleClosedPageNumber = (page: number) => setClosedPageNumber(page);

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={closedPageNumber}
              setPageNumber={handleClosedPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
