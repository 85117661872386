import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Tabs } from "../../components";
import { Link, useSearchParams } from "react-router-dom";
import i18next from "../../i18n";
import { useOrders } from "../../hooks";
import { Opened } from "./Opened/Opened";
import { Pending } from "./Pending/Pending";
import { Closed } from "./Closed/Closed";
import { Succeed } from "./Succeed/Succeed";
import { Joint } from "./Joint/Joint";
import { OrderState } from "../../types";
import { Cancelled } from "./Cancelled/Cancelled";

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabNumber = searchParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(
    tabNumber ? parseInt(tabNumber) : 0
  );

  const { onGetOrders, onGetOrdersCount } = useOrders();

  useEffect(() => {
    if (selectedTab === 0) {
      setSearchParams({ tab: "0" });
    }
  }, [selectedTab, setSearchParams]);

  const ordersCount = useQuery({
    queryKey: ["ordersCount"],
    queryFn: () => onGetOrdersCount(),
  });

  const tabs = [
    {
      name: i18next.t("order.open"),
      current: true,
      bgColor: "bg-purple-100 text-purple-800",
      count: ordersCount.data?.opened,
    },
    {
      name: i18next.t("order.pend"),
      current: false,
      bgColor: "bg-blue-100 text-blue-800",
      count: ordersCount.data?.pending,
    },
    {
      name: i18next.t("order.close"),
      current: false,
      bgColor: "bg-yellow-100 text-yellow-800",
      count: ordersCount.data?.closed,
    },
    {
      name: i18next.t("order.success"),
      current: false,
      bgColor: "bg-green-100 text-green-800",
      count: ordersCount.data?.succeed,
    },
    {
      name: i18next.t("order.cancel"),
      current: false,
      bgColor: "bg-red-100 text-red-800",
      count: ordersCount.data?.cancelled,
    },
  ];

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setSearchParams({ tab: `${index}` });
  };

  return (
    <div className="px-4 mt-6">
      <div className="md:flex justify-between items-center">
        <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
          {i18next.t("navigation.tenders")}
        </h2>
        <Link
          type="button"
          to="/order"
          className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
        >
          {i18next.t("navigation.newTender")}
        </Link>
      </div>
      <div className="py-4">
        <Tabs
          tabs={tabs}
          handleTabClick={handleTabClick}
          selectedTab={selectedTab}
        />
        <div className="pt-4">
          {selectedTab === 0 && (
            <>
              {ordersCount.data?.opened === 0 && (
                <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                  {i18next.t("order.noOpenedPlan")}
                </h4>
              )}
              <Opened onGetOrders={onGetOrders} />
              <Joint state={OrderState.OPEN} />
            </>
          )}
          {selectedTab === 1 && (
            <>
              {ordersCount.data?.pending === 0 && (
                <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                  {i18next.t("order.noPendingPlan")}
                </h4>
              )}
              <Pending onGetOrders={onGetOrders} />
              <Joint state={OrderState.PENDING} />
            </>
          )}
          {selectedTab === 2 && (
            <>
              {ordersCount.data?.closed === 0 && (
                <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                  {i18next.t("order.noClosedPlan")}
                </h4>
              )}
              <Closed onGetOrders={onGetOrders} />
              <Joint state={OrderState.CLOSED} />
            </>
          )}
          {selectedTab === 3 && (
            <>
              {ordersCount.data?.succeed === 0 && (
                <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                  {i18next.t("order.noSucceedPlan")}
                </h4>
              )}
              <Succeed onGetOrders={onGetOrders} />
              <Joint state={OrderState.SUCCEED} />
            </>
          )}
          {selectedTab === 4 && (
            <>
              {ordersCount.data?.cancelled === 0 && (
                <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                  {i18next.t("order.noCancelledPlan")}
                </h4>
              )}
              <Cancelled onGetOrders={onGetOrders} />
              <Joint state={OrderState.CANCELLED} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
