import axios from "../axios-orders";

export default function useUserMaterialCategories() {
  const onGetUserMaterialCategories = async () => {
    const { data } = await axios.get(`/userMaterialCategory/material/category`);
    return data;
  };

  return {
    onGetUserMaterialCategories,
  };
}
