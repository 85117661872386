import axios from "../axios-orders";

export default function usePurchaseRequests() {
  const onGetPurchaseRequests = async (page = 0) => {
    const { data } = await axios.get(`/purchase-request/all/${page}`);
    return data;
  };

  const onGetUserPurchaseRequests = async (page = 0) => {
    const { data } = await axios.get(`/purchase-request/user/${page}`);
    return data;
  };

  const onGetMultiplePurchaseRequests = async (page = 0) => {
    const { data } = await axios.get(`/purchase-request/user/multiple/${page}`);
    return data;
  };

  const onGetUserPurchaseRequestsByState = async (state: any, page = 0) => {
    const { data } = await axios.get(
      `/purchase-request/state/${state}/${page}`
    );
    return data;
  };

  const onGetMultiplePurchaseRequestsByState = async (state: any, page = 0) => {
    const { data } = await axios.get(
      `/purchase-request/user/multiple/${state}/${page}`
    );
    return data;
  };

  const onCreateMultiplePurchaseRequest = async (body: any) => {
    const { data } = await axios.post(`/order/multiple/purchase-request`, body);
    return data;
  };

  const onCreatePurchaseRequest = async (body: any) => {
    const { data } = await axios.post(`/order/purchase-request`, body);
    return data;
  };

  const onGetPurchaseRequest = async (purchaseRequestId: string) => {
    const { data } = await axios.get(`/purchase-request/${purchaseRequestId}`);
    return data;
  };

  const onGetMultiplePurchaseRequest = async (
    url: string,
    multiplePurchaseRequestId: string
  ) => {
    const { data } = await axios.get(`${url}${multiplePurchaseRequestId}`);
    return data;
  };

  const onDeletePurchaseRequest = async (purchaseRequestId: string) => {
    const { data } = await axios.delete(
      `/purchase-request/${purchaseRequestId}`
    );
    return data;
  };

  const onUpdatePurchaseRequest = async (body: any) => {
    const { data } = await axios.put(`/order/list`, body);
    return data;
  };

  const onGetCountPurchaseRequests = async () => {
    const { data } = await axios.get(`/purchase-request/count`);
    return data;
  };

  const onRejectPurchaseRequest = async ({
    purchaseRequestId,
    body,
  }: {
    purchaseRequestId: string;
    body: { comment: string };
  }) => {
    const { data } = await axios.post(
      `/purchase-request/reject/${purchaseRequestId}`,
      body
    );
    return data;
  };

  return {
    onGetPurchaseRequest,
    onGetPurchaseRequests,
    onRejectPurchaseRequest,
    onUpdatePurchaseRequest,
    onCreatePurchaseRequest,
    onDeletePurchaseRequest,
    onGetUserPurchaseRequests,
    onGetCountPurchaseRequests,
    onGetMultiplePurchaseRequest,
    onGetMultiplePurchaseRequests,
    onCreateMultiplePurchaseRequest,
    onGetUserPurchaseRequestsByState,
    onGetMultiplePurchaseRequestsByState,
  };
}
