import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { EditSiteForm, GoBackArrow, Loader } from "../../../components";
import { useID, useSites } from "../../../hooks";

const EditSite = () => {
  const { t } = useTranslation();
  const id = useID();
  const { onGetSite } = useSites();

  const site = useQuery({
    queryKey: ["site", id],
    queryFn: () => onGetSite(id),
  });

  return (
    <div className="px-4 mt-6">
      <GoBackArrow />
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {t("sites.edit")}
      </h2>

      {site.isLoading ? <Loader /> : <EditSiteForm site={site.data} />}
    </div>
  );
};

export default EditSite;
