import axios from "../axios-orders";

export default function usePosition() {
  const onGetPosition = async (orderId: string) => {
    const { data } = await axios.get(`/offer/order/position/${orderId}`);
    return data;
  };

  return { onGetPosition };
}
