import axios from "../axios-orders";

export default function useUsers() {
  const onGetUserById = async (userId: string) => {
    const { data } = await axios.get(`/user/company/user/${userId}`);
    return data;
  };

  const onGetUsersByCompany = async () => {
    const { data } = await axios.get(`/user/company`);
    return data;
  };

  const onCreateNewUser = async (body: any) => {
    const { data } = await axios.post(`/user/new`, body);
    return data;
  };

  const onChangePassword = async (body: { password: string }) => {
    const { data } = await axios.post(`/auth/change-password`, body);
    return data;
  };

  const onEditUser = async ({
    userId,
    body,
  }: {
    userId: string;
    body: any;
  }) => {
    const { data } = await axios.put(`/user/${userId}`, body);
    return data;
  };

  return {
    onEditUser,
    onGetUserById,
    onCreateNewUser,
    onChangePassword,
    onGetUsersByCompany,
  };
}
