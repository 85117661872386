import i18next from "../../i18n";
import { Pagination } from "..";
import OfferTableRow from "./OfferTableRow/OfferTableRow";
import { OfferWithPositionPaginatedResponse } from "../../types";

const offersTh = [
  { id: 1, name: i18next.t("tenders.tenderSummary.name") },
  { id: 2, name: i18next.t("offer.table.company") },
  { id: 3, name: i18next.t("offer.table.price") },
  { id: 4, name: i18next.t("offer.table.position") },
  { id: 5, name: i18next.t("offer.table.status") },
  { id: 6, name: i18next.t("offer.table.type") },
  { id: 7, name: "" },
];

type OfferTableProps = {
  offers: OfferWithPositionPaginatedResponse;
  pageNumber: number;
  setPageNumber: any;
};

const OfferTable = ({ offers, pageNumber, setPageNumber }: OfferTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {offersTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {offers?.content?.map((off) => (
                <OfferTableRow
                  key={off.offer.id}
                  offer={off.offer}
                  position={off.position}
                />
              ))}
            </tbody>
          </table>
          <Pagination
            results={offers.numberOfElements}
            total={offers.totalElements}
            amountOfPages={offers.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default OfferTable;
