import axios from "../axios-orders";

export default function useBiddingSuppliers() {
  const onGetBiddingSuppliers = async (materialIds: string[]) => {
    const { data } = await axios.post(`/buyerSeller/materials`, materialIds);
    return data;
  };

  return {
    onGetBiddingSuppliers,
  };
}
