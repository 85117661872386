import React from "react";

type InfoItemProps = {
  name: string;
  description: React.ReactNode | string;
  isCol?: boolean;
};

const InfoItem = ({ name, description, isCol }: InfoItemProps) => {
  return (
    <div className="bg-white overflow-hidden">
      <div className="py-2 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div
            className={`py-2  ${
              isCol ? "flex flex-col gap-2" : "sm:grid sm:grid-cols-2 sm:gap-4"
            }`}
          >
            <dt className="uppercase text-xs font-medium text-gray-500">
              {name}
            </dt>
            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
              {description}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default InfoItem;
