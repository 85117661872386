import axios from "../axios-orders";

export default function useCountries() {
  const onGetPublicCountries = async () => {
    const { data } = await axios.get("/country/public");
    return data;
  };

  const onGetCountriesByMaterialId = async (materialId: string) => {
    const { data } = await axios.get(`/country/filtered/${materialId}`);
    return data;
  };

  return {
    onGetPublicCountries,
    onGetCountriesByMaterialId,
  };
}
