import { Fragment, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import i18next from "../../i18n";
import { MONTHS, mapDispatchToProps, mapStateToProps } from "../../utils";
import { TooltipSP } from "..";
import PriceVolumeTable from "./PriceVolumeTable/PriceVolumeTable";
import AddSuppliers from "./AddSuppliers/AddSuppliers";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import Negotiation from "./Negotiation/Negotiation";
import { UserResponse, MonthData, YearData } from "../../types";
import { useMaterialsOffers } from "../../hooks";

const YEAR = new Date().getFullYear();

function buildYears(years: YearData) {
  return MONTHS.map((month) => years.months[month.id]);
}

type PriceVolumeProps = {
  materialId: string;
  coin: string;
  measurementUnit: string;
  priceVolume: any;
  volumePrice: MonthData[];
  onUpdateMaterialVolume: (data: any) => void;
  onCleanSupplierPriceVolume: () => void;
  isOwner: boolean;
  isResponsible: boolean;
  isMaterialView: boolean;
  isFirst?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  hasTableHead: boolean;
  onClickCancel?: () => void;
  onCreateNegotiation: (data: any) => void;
};

const PriceVolume = ({
  coin,
  isActive,
  isOwner,
  isFirst,
  onClick,
  materialId,
  priceVolume,
  volumePrice,
  hasTableHead,
  isResponsible,
  onClickCancel,
  isMaterialView,
  measurementUnit,
  onCreateNegotiation,
  onUpdateMaterialVolume,
  onCleanSupplierPriceVolume,
}: PriceVolumeProps) => {
  const { onGetMaterialOffers } = useMaterialsOffers();

  const { data: suppliers } = useQuery({
    queryKey: ["suppliers", materialId],
    queryFn: () => onGetMaterialOffers(materialId),
    enabled: !!materialId,
  });

  const [selected, setSelected] = useState<any>();
  const [addSupplier, setAddSupplier] = useState(false);
  const { years, materialName, materialCoin } = priceVolume;
  const prevYear = years[YEAR - 1];
  const currYear = years[YEAR];
  const nexYear = years[YEAR + 1];

  const previousYear = buildYears(prevYear);
  const currentYear = buildYears(currYear);
  const nextYear = buildYears(nexYear);

  const handleSave = () => {
    if (!(isOwner || isResponsible)) return;
    const formattedVolumePrice = volumePrice.map((item) => ({
      ...item,
      forecast: {
        ...item.forecast,
        vol: parseFloat(String(item.forecast.vol)),
        price: parseFloat(String(item.forecast.price)),
      },
      actual: {
        ...item.actual,
        vol: parseFloat(String(item.actual.vol)),
        price: parseFloat(String(item.actual.price)),
      },
      materialId,
    }));

    onUpdateMaterialVolume(formattedVolumePrice);
    onCleanSupplierPriceVolume();
  };

  return (
    <div>
      {isActive && (isOwner || isResponsible) && (
        <div className="fixed top-24 right-4 flex gap-4">
          <button
            onClick={onClickCancel}
            className="py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded mb-4 text-gray-700 bg-white uppercase"
          >
            {i18next.t("volumePrice.cancel")}
          </button>
          <button
            className={`py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded mb-4 uppercase ${
              volumePrice.length === 0
                ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                : "text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            }`}
            onClick={handleSave}
            disabled={volumePrice.length === 0}
          >
            {i18next.t("volumePrice.save")}
          </button>
        </div>
      )}

      {!isActive && (isOwner || isResponsible) && (
        <button
          className={`py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded mb-4 uppercase ${
            volumePrice.length === 0
              ? "bg-gray-200 text-gray-600 cursor-not-allowed"
              : "text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
          }`}
          onClick={handleSave}
          disabled={volumePrice.length === 0}
        >
          {i18next.t("volumePrice.save")}
        </button>
      )}

      <div className="flex overflow-x-scroll pt-8 relative" onClick={onClick}>
        {isActive ? (
          <div className="absolute top-0 left-0">
            <span className="text-xs w-full border-none font-bold uppercase text-spectum-light">
              {materialName}
            </span>
          </div>
        ) : null}
        <div className="flex flex-col items-center text-xs w-100">
          {isFirst || hasTableHead ? <div className="w-full p-3"></div> : null}
          <div className="w-100 border-b p-1">
            <span className="text-xs w-full border-none font-bold">FCST</span>
          </div>
          <div className="w-100 border-b p-3"></div>
          <div className="w-full pt-0.5 bg-gray-100"></div>
          <div className="w-100 border-b p-1">
            <span className="text-xs w-full border-none font-bold">ACTUAL</span>
          </div>
          <div className="w-100 border-b p-3"></div>
          <span className="font-bold text-white bg-gray-200 pt-0.5 w-full"></span>
          {isMaterialView ? (
            <>
              <div className="w-100 border-b p-1">
                <span className="text-xs w-full border-none font-bold uppercase">
                  {i18next.t("volumePrice.impact")}
                </span>
              </div>
              <div className="w-100 border-b p-3"></div>
            </>
          ) : null}
          {isMaterialView ? (
            <>
              <span className="font-bold text-white pt-0.5 bg-gray-200 w-full"></span>
              {currentYear[0]?.suppliers?.length > 0 ? (
                <>
                  {currentYear[0].suppliers
                    .sort((a, b) =>
                      a.supplier.company.name.localeCompare(
                        b.supplier.company.name
                      )
                    )
                    .map((supplier, index) => (
                      <Fragment key={supplier.supplier.id}>
                        <div
                          className={`w-100 flex items-center p-1 h-49 ${
                            index > 0
                              ? "border-t"
                              : "border-t border-white mt-6"
                          }`}
                          id={supplier.supplier.company.name}
                        >
                          <span className="text-xs w-full truncate border-none font-bold uppercase text-spectum-light">
                            {supplier.supplier.company.name}
                          </span>
                          <TooltipSP
                            id={supplier.supplier.company.name}
                            message={supplier.supplier.company.name}
                          />
                        </div>
                      </Fragment>
                    ))}
                  <div className="w-100 border-t"></div>
                </>
              ) : null}
            </>
          ) : null}
        </div>
        <div className="flex flex-col items-center text-xs w-150">
          {isFirst || hasTableHead ? (
            <div className="font-bold w-full p-3"></div>
          ) : null}
          <div className="w-150 border-b p-1">
            <span className="text-xs w-full border-none font-bold">
              VOL <span className="text-gray-500">({measurementUnit})</span>
            </span>
          </div>
          <div className="w-150 border-b p-1">
            <span className="text-xs w-full border-none font-bold uppercase truncate">
              {i18next.t("volumePrice.price")}{" "}
              <span className="text-gray-500">({coin ?? materialCoin})</span>
            </span>
          </div>
          <div className="w-full pt-0.5 bg-gray-100"></div>
          <div className="w-150 border-b p-1">
            <span className="text-xs w-full border-none font-bold truncate">
              VOL <span className="text-gray-500">({measurementUnit})</span>
            </span>
          </div>
          <div className="w-150 border-b p-1">
            <span className="text-xs w-full border-none font-bold uppercase truncate">
              {i18next.t("volumePrice.price")}{" "}
              <span className="text-gray-500">({coin ?? materialCoin})</span>
            </span>
          </div>
          <span className="font-bold text-white pt-0.5 bg-gray-200 w-full"></span>
          {isMaterialView ? (
            <>
              <div className="w-150 border-b p-1 truncate">
                <span className="text-xs w-full border-none font-bold uppercase flex items-center">
                  VOL <span className="text-gray-500">({measurementUnit})</span>
                  <QuestionMarkCircleIcon
                    className="h-4 w-4 text-gray-600"
                    id="volumeVariation"
                  />
                </span>
                <TooltipSP
                  id="volumeVariation"
                  message={i18next.t("volumePrice.volumeVariation")}
                />
              </div>
              <div className="w-150 border-b p-1 truncate">
                <span className="text-xs w-full border-none font-bold uppercase flex items-center">
                  {i18next.t("volumePrice.price")}{" "}
                  <span className="text-gray-500">
                    ({coin ?? materialCoin})
                  </span>
                  <QuestionMarkCircleIcon
                    className="h-4 w-4 text-gray-600"
                    id="priceVariation"
                  />
                </span>
                <TooltipSP
                  id="priceVariation"
                  message={i18next.t("volumePrice.priceVariation")}
                />
              </div>
            </>
          ) : null}
          {isMaterialView ? (
            <>
              <span className="font-bold text-white pt-0.5 bg-gray-200 w-full"></span>
              {currentYear[0]?.suppliers?.length > 0 ? (
                <div className="font-bold w-full pt-6">
                  {currentYear[0].suppliers.map((supplier) => (
                    <Fragment key={supplier.supplier.id}>
                      <div className="font-bold w-full uppercase text-spectum-light relative"></div>
                      <div className="w-150 border-b p-1">
                        <span className="text-xs w-full border-none font-bold">
                          VOL{" "}
                          <span className="text-gray-500">
                            ({measurementUnit})
                          </span>
                        </span>
                      </div>
                      <div className="w-150 border-b p-1">
                        <span className="text-xs w-full border-none font-bold uppercase">
                          {i18next.t("volumePrice.price")}{" "}
                          <span className="text-gray-500">
                            ({coin ?? materialCoin})
                          </span>
                        </span>
                      </div>
                    </Fragment>
                  ))}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <PriceVolumeTable
          isMaterialView={isMaterialView}
          year={YEAR}
          data={previousYear}
          months={MONTHS}
          isPreviousYear
          isFirst={isFirst}
          hasTableHead={hasTableHead}
        />
        <PriceVolumeTable
          isMaterialView={isMaterialView}
          year={YEAR}
          data={currentYear}
          months={MONTHS}
          isFullYear
          isFirst={isFirst}
          hasTableHead={hasTableHead}
        />
        <PriceVolumeTable
          isMaterialView={isMaterialView}
          year={YEAR}
          data={nextYear}
          months={MONTHS}
          isNextYear
          isFirst={isFirst}
          hasTableHead={hasTableHead}
        />
      </div>
      {(isOwner || isResponsible) && (
        <AddSuppliers
          materialId={materialId}
          hasSuppliers={currentYear[0]?.suppliers?.length > 0}
          selected={selected}
          setAddSupplier={setAddSupplier}
          setSelected={setSelected}
          suppliers={suppliers}
          addSupplier={addSupplier}
          isMaterialView={isMaterialView}
        />
      )}
      {(isOwner || isResponsible) && addSupplier && selected && (
        <Negotiation
          cancelNegotiation={addSupplier}
          onCancelNegotiation={setAddSupplier}
          materialId={materialId}
          measurementUnit={measurementUnit}
          coin={coin}
          companyId={selected?.value?.buyerSeller.seller.company.id}
          userId={selected?.value?.buyerSeller.seller.id}
          onCreateNegotiation={onCreateNegotiation}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceVolume);
