import axios from "../axios-orders";

export default function useOffers() {
  const onGetOffers = async (page = 0) => {
    const { data } = await axios.get(`/offer/list/${page}`);
    return data;
  };

  const onGetOffer = async (offerId: string) => {
    const { data } = await axios.get(`/offer/${offerId}`);
    return data;
  };

  const onGetOffersOnOrders = async (orderId: string, page = 0) => {
    const { data } = await axios.get(`/offer/order/${orderId}/${page}`);
    return data;
  };

  const onGetMultipleOffersOnOrders = async (orderId: string) => {
    const { data } = await axios.get(`/offer/multiple/order/${orderId}`);
    return data;
  };

  const onGetMultipleOffers = async (page = 0) => {
    const { data } = await axios.get(`/offer/list/multiple/${page}`);
    return data;
  };

  const onGetUserOffer = async (orderId: string, userId: string) => {
    const { data } = await axios.get(`offer/order/${orderId}/user/${userId}`);
    return data;
  };

  const onGetMultipleOffer = async (orderId: string) => {
    const { data } = await axios.get(`/offer/multiple/order/user/${orderId}`);
    return data;
  };

  const onCreateOffer = async (body: any) => {
    const { data } = await axios.post("/offer", body);
    return data;
  };

  const onCreateMultipleOffer = async (body: any) => {
    const { data } = await axios.post("/offer/multiple", body);
    return data;
  };

  const onAcceptOffer = async (offers: any) => {
    const { data } = await axios.put(`/offer/list/select`, offers);
    return data;
  };

  const onUpdateInverseOffer = async ({
    offerId,
    price,
  }: {
    offerId: string;
    price: string | number;
  }) => {
    const { data } = await axios.get(`/offer/updatePrice/${offerId}/${price}`);
    return data;
  };

  const onUpdateMultipleInverseOffer = async (offers: any) => {
    const { data } = await axios.post(`/offer/updatePrice/list`, offers);
    return data;
  };

  return {
    onGetOffer,
    onGetOffers,
    onAcceptOffer,
    onCreateOffer,
    onGetUserOffer,
    onGetMultipleOffer,
    onGetMultipleOffers,
    onGetOffersOnOrders,
    onUpdateInverseOffer,
    onCreateMultipleOffer,
    onGetMultipleOffersOnOrders,
    onUpdateMultipleInverseOffer,
  };
}
