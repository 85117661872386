import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Loader, PickList } from "../..";
import { useUsers, useMaterials, useMaterialsOffers } from "../../../hooks";
import { mapDispatchToProps, mapStateToProps } from "../../../utils";
import {
  MaterialOfferResponse,
  MaterialResponse,
  UserResponse,
} from "../../../types";

type DelegateProps = {
  user: UserResponse;
  material?: MaterialResponse;
  materialOffer?: MaterialOfferResponse;
  onCloseDelegate?: () => void;
  onOpenNotification: (message: string) => void;
  isDelegateAll?: boolean;
  isDelegateMaterialOffer?: boolean;
};

const Delegate = ({
  user,
  material,
  materialOffer,
  onCloseDelegate,
  onOpenNotification,
  isDelegateAll = false,
  isDelegateMaterialOffer = false,
}: DelegateProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onGetUsersByCompany } = useUsers();
  const { onEditMaterial, onDelegateAllMaterials } = useMaterials();
  const { onDelegateMaterialOffer } = useMaterialsOffers();
  const [selected, setSelected] = useState<any>();

  const usersByCompany = useQuery({
    queryKey: ["usersByCompany"],
    queryFn: () => onGetUsersByCompany(),
    select: (data) => data?.filter((u: any) => u.id !== user?.id),
  });

  const { mutate: mutateMaterial, isPending: isDelegatingMaterial } =
    useMutation({
      mutationFn: ({ materialId, body }: { materialId: string; body: any }) =>
        onEditMaterial({ materialId, body }),
      onSuccess: () => {
        onOpenNotification(
          `${t("delegate.success")} ${selected?.value?.fullName}!`
        );
        navigate(`/materials`);
      },
      onError: () => onOpenNotification(t("newMaterialForm.error")),
    });

  const { data: mutateMaterialOffer, isPending: isDelegatingMaterialOffer } =
    useMutation({
      mutationFn: ({
        materialOfferId,
        body,
      }: {
        materialOfferId: string;
        body: any;
      }) => onDelegateMaterialOffer({ materialOfferId, body }),
      onSuccess: () => {
        onOpenNotification(
          `${t("delegate.successMaterialOffer")} ${selected?.value?.fullName}!`
        );
        navigate(`/material/offers`);
      },
      onError: () => onOpenNotification(t("newMaterialForm.error")),
    });

  const { mutate: mutateAllMaterials, isPending: isDelegatingAll } =
    useMutation({
      mutationFn: (userId: string) => onDelegateAllMaterials(userId),
      onSuccess: () => {
        onOpenNotification(
          `${t("delegate.all")} ${selected?.value?.fullName}!`
        );
        navigate(`/materials`);
      },
      onError: () => onOpenNotification(t("newMaterialForm.error")),
    });

  const onDelegateMaterial = () => {
    if (!selected?.value?.id) return;
    if (isDelegateMaterialOffer) {
      mutateMaterialOffer({
        materialOfferId: materialOffer?.id,
        body: {
          ...materialOffer,
          user: { id: selected?.value?.id },
        },
      });
    } else {
      if (isDelegateAll) {
        mutateAllMaterials(selected?.value?.id);
      } else {
        if (material)
          mutateMaterial({
            materialId: material?.id,
            body: {
              ...material,
              user,
              owner: { id: selected?.value?.id },
            },
          });
      }
    }
  };

  if (isDelegatingMaterialOffer || isDelegatingMaterial || isDelegatingAll)
    return <Loader isFullScreen />;

  return (
    <Fragment>
      <div>
        <PickList
          selected={selected}
          onChange={setSelected}
          list={usersByCompany.data}
          title={t("delegate.delegateTo")}
          placeholder={t("delegate.select")}
        />
      </div>
      <div className="pt-4 flex items-center gap-6">
        <button
          className="py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
          onClick={onDelegateMaterial}
        >
          {t("delegate.delegate")}
        </button>
        <button
          onClick={onCloseDelegate}
          className="py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase mr-3"
        >
          {t("cta.cancel")}
        </button>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Delegate);
