import axios from "../axios-orders";

export default function useValidateSeller() {
  const onGetBuyer = async (page = 0) => {
    const { data } = await axios.get(`/buyerSeller/buyer/${page}`);
    return data;
  };

  const onSearchBuyers = async (searchTerm: string) => {
    const { data } = await axios.get(`/buyerSeller/buyer/search/${searchTerm}`);
    return data;
  };

  const onGetSeller = async () => {
    const { data } = await axios.get(`/buyerSeller/seller`);
    return data;
  };

  const onValidateSeller = async (buyerSellerId: string) => {
    const { data } = await axios.get(
      `/buyerSeller/toggleValidated/${buyerSellerId}`
    );
    return data;
  };

  const onRejectSeller = async ({
    buyerSellerId,
    buyerSeller,
  }: {
    buyerSellerId: string;
    buyerSeller: any;
  }) => {
    const { data } = await axios.put(
      `/buyerSeller/reject/${buyerSellerId}`,
      buyerSeller
    );
    return data;
  };

  return {
    onGetBuyer,
    onGetSeller,
    onSearchBuyers,
    onRejectSeller,
    onValidateSeller,
  };
}
