import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { OrdersTable, Loader } from "../../../components";
import {
  ORDER_TYPES,
  OrderState,
  SimpleOrderPaginatedResponse,
} from "../../../types";

type PendingProps = {
  onGetOrders: (
    state: OrderState,
    type: ORDER_TYPES,
    _type: ORDER_TYPES,
    page: number
  ) => Promise<SimpleOrderPaginatedResponse>;
};

export const Pending = ({ onGetOrders }: PendingProps) => {
  const [pendingPageNumber, setPendingPageNumber] = useState(0);

  const orders = useQuery({
    queryKey: ["orders", pendingPageNumber],
    queryFn: () =>
      onGetOrders(
        OrderState.PENDING,
        ORDER_TYPES.NORMAL,
        ORDER_TYPES.INVERSE,
        pendingPageNumber
      ),
  });

  const handlePendingPageNumber = (page: number) => setPendingPageNumber(page);

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={pendingPageNumber}
              setPageNumber={handlePendingPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
