import i18next from "../../i18n";
import { TableTitle } from "..";
import OpportunitiesTableRow from "./OpportunitiesTableRow/OpportunitiesTableRow";
import { OrderResponse } from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("opportunities.table.material") },
  { id: 2, name: i18next.t("opportunities.table.company") },
  { id: 3, name: i18next.t("opportunities.table.volume") },
  { id: 4, name: i18next.t("opportunities.table.closes") },
  { id: 5, name: i18next.t("opportunities.table.type") },
  { id: 6, name: "" },
];

type OpportunitiesTableProps = {
  opportunities: OrderResponse[];
  title?: string;
};

const OpportunitiesTable = ({
  opportunities,
  title,
}: OpportunitiesTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        {title && <TableTitle title={title} />}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {opportunities
                ?.filter((el) => !el.hasOfferSelected)
                .map((opportunity) => (
                  <OpportunitiesTableRow
                    key={opportunity.id}
                    opportunity={opportunity}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesTable;
