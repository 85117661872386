import { classNames } from "../../../utils";

type TabsProps = {
  selectedTab: number;
  tabs: {
    name: string;
    current: boolean;
    bgColor?: string;
    count?: number;
  }[];
  handleTabClick: (tabIdx: number) => void;
};

export default function Tabs({ tabs, handleTabClick, selectedTab }: TabsProps) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-spectum-light focus:border-spectum-light border-gray-300 rounded font-bold"
          defaultValue={tabs.find((tab) => tab.current)?.name || ""}
          onChange={(e) => handleTabClick(e.target.selectedIndex)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              className={classNames(
                tabIdx === selectedTab
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === selectedTab ? "rounded-l" : "",
                tabIdx === tabs.length - 1 ? "rounded-r" : "",
                "uppercase group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-xs text-center hover:bg-gray-50 focus:z-10 font-bold"
              )}
              onClick={() => handleTabClick(tabIdx)}
            >
              <div className="flex w-full justify-between items-center">
                <span className="w-full text-center block">{tab.name}</span>
                {tab?.count && tab.count >= 0 && (
                  <span
                    className={`${tab.bgColor} rounded-full flex items-center justify-center h-6 w-6`}
                  >
                    {tab.count}
                  </span>
                )}
              </div>
              <span
                aria-hidden="true"
                className={classNames(
                  tabIdx === selectedTab
                    ? "bg-spectum-light"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
