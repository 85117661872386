import { useQuery } from "@tanstack/react-query";
import { GoBackArrow, Loader, Material } from "../../components";
import { useID, useMaterials } from "../../hooks";

const MaterialCnt = () => {
  const id = useID();
  const { onGetMaterial } = useMaterials();

  const material = useQuery({
    queryKey: ["material", id],
    queryFn: () => onGetMaterial(id),
  });

  if (material.isLoading) return <Loader isFullScreen />;

  return (
    <div className="min-h-full flex">
      <div className="flex flex-col w-0 flex-1">
        <div className="py-8 xl:py-10 relative">
          <GoBackArrow stylesAdded={"px-8"} />
          <Material material={material?.data} />
        </div>
      </div>
    </div>
  );
};

export default MaterialCnt;
