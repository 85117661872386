import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { useLanguage } from "../../../hooks";
import { OpportunityByFamilyResponse } from "../../../types";

type UserMaterialOpportunitiesNPITableRowProps = {
  opportunity: OpportunityByFamilyResponse;
  setSelectedOpportunities: (value: any) => void;
  setOpen: (value: boolean) => void;
};

const UserMaterialOpportunitiesNPITableRow = ({
  opportunity,
  setSelectedOpportunities,
  setOpen,
}: UserMaterialOpportunitiesNPITableRowProps) => {
  const langEs = useLanguage();
  return (
    <tr>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs">
          {langEs
            ? opportunity?.category?.parent?.name
            : opportunity?.category?.parent?.nameEn}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs">
          {langEs ? opportunity?.category?.name : opportunity?.category?.nameEn}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs">{opportunity?.quantity}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
        <button
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => {
            setOpen(true);
            setSelectedOpportunities(opportunity.materials);
          }}
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </button>
      </td>
    </tr>
  );
};

export default UserMaterialOpportunitiesNPITableRow;
