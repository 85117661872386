import axios from "../axios-orders";

export default function useCategories() {
  const onGetCategoriesByParent = async (type: string) => {
    const { data } = await axios.get(`/materialCategory/parent/${type}`);
    return data;
  };

  const onGetCategoriesById = async (categoryId: string) => {
    const { data } = await axios.get(
      `/materialCategory/parent/id/${categoryId}`
    );
    return data;
  };

  const onGetCategories = async (categoryIds: string[]) => {
    const { data } = await axios.get(
      `materialCategory/parent/ids?ids=${categoryIds.join(",")}`
    );
    return data;
  };

  const onSearchMaterialCategoryByName = async (name: string) => {
    const { data } = await axios.get(`/materialCategory/material/${name}`);
    return data;
  };

  const onGetAvailableCountries = async () => {
    const { data } = await axios.get(`/country/material`);
    return data;
  };

  return {
    onGetCategoriesByParent,
    onGetCategories,
    onGetAvailableCountries,
    onSearchMaterialCategoryByName,
    onGetCategoriesById,
  };
}
