import { useTranslation } from "react-i18next";
import { BIDDING_STATES } from "../../utils";
import { OfferItem } from "../../components";
import { OfferResponse } from "../../types";

type OffersOnMultipleOrdersProps = {
  offOnOrd: [OfferResponse[]];
  handleItemClick?: any;
  state?: string;
  isCompliant: boolean;
};

export default function OffersOnMultipleOrders({
  offOnOrd,
  handleItemClick,
  state,
  isCompliant,
}: OffersOnMultipleOrdersProps) {
  const { t } = useTranslation();
  return (
    <div className="py-2 w-full">
      {offOnOrd?.length ? (
        <>
          <div className="bg-white shadow overflow-hidden sm:rounded">
            <ul className="divide-y divide-gray-200">
              {offOnOrd.map((offers) => {
                return (
                  <OfferItem
                    key={offers[0]?.id}
                    offer={offers[0]}
                    offers={offers}
                    handleItemClick={() => handleItemClick(offers)}
                  />
                );
              })}
            </ul>
          </div>
          {state === BIDDING_STATES.OPEN && isCompliant && (
            <p className="text-xs text-spectum uppercase font-bold mt-6">
              {t("tenders.data.visualize")}
            </p>
          )}
        </>
      ) : (
        <p className="text-xs font-bold text-black rounded py-2 mb-4">
          {t("tenders.data.noOffers")}.
        </p>
      )}
    </div>
  );
}
