import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  USER_COMPANY_LIST_FOR_BUYER_ADMIN,
  USER_COMPANY_LIST_FOR_SELLER,
  USER_COMPANY_LIST_FOR_SELLER_ADMIN,
  USER_COMPANY_LIST_FOR_SELLER_MANAGER,
  mapDispatchToProps,
  mapStateToProps,
  newUserValidationSchema,
} from "../../utils";
import { Loader, Modal, InputField, Button } from "..";
import { useUsers } from "../../hooks";
import {
  USER_COMPANY_LIST_FOR_BUYER_MANAGER,
  USER_COMPANY_LIST_FOR_BUYER,
  USER_COMPANY_TYPES,
  USER_TYPES,
} from "../../utils";
import { UserResponse } from "../../types";

type NewUserFormProps = {
  user: UserResponse;
  isBuy: boolean;
  isSeller: boolean;
  onOpenNotification: (message: string) => void;
};

type NewUserFormValues = {
  fullName: string;
  email: string;
  confirmEmail?: string;
  phone: string;
  userCompanyType: string;
  parent?: string;
};

const NewUserForm = ({
  user,
  isBuy,
  isSeller,
  onOpenNotification,
}: NewUserFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parentId, setParentId] = useState("");
  const formOptions = {
    resolver: yupResolver(newUserValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewUserFormValues>(formOptions);
  const [open, setOpen] = useState(false);
  const { onCreateNewUser, onGetUsersByCompany } = useUsers();

  const { data: usersByCompany, isLoading: isLoadingUsersByCompany } = useQuery<
    UserResponse[]
  >({
    queryKey: ["userByCompany"],
    queryFn: () => onGetUsersByCompany(),
  });

  const {
    mutate: createNewUser,
    isPending: isCreatingUser,
    isError,
  } = useMutation({
    mutationFn: onCreateNewUser,
    onSuccess: () => {
      onOpenNotification(t("users.success"));
      setOpen(false);
      navigate("/users");
    },
    onError: () => {
      setOpen(false);
      onOpenNotification(t("newMaterialForm.error"));
    },
  });

  const onSubmit = (data: NewUserFormValues) => {
    createNewUser({
      ...data,
      parent: { id: parentId ?? user?.id },
      role: USER_TYPES.USER,
      type: isBuy ? USER_TYPES.BUYER : USER_TYPES.SELLER,
      active: true,
      password: Math.random().toString(36).slice(-8),
    });
    setOpen(false);
  };
  const onError = () => {
    setOpen(false);
  };

  if (isCreatingUser || isLoadingUsersByCompany) return <Loader isFullScreen />;

  return (
    <div className="px-4 mt-6">
      <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
        {t("users.add")}
      </h2>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden sm:rounded p-4">
              {isError && (
                <p className="text-red-600 text-sm pb-4">
                  {t("users.emailExists")}
                </p>
              )}
              <div className="pb-4">
                <InputField
                  id="fullName"
                  name="fullName"
                  type="text"
                  label={t("users.fullName")}
                  errorName={errors?.fullName}
                  errorMessage={errors?.fullName?.message}
                  required
                  register={register}
                />
              </div>

              <div className="pb-4">
                <InputField
                  id="email"
                  name="email"
                  type="email"
                  label={t("users.email")}
                  errorName={errors?.email}
                  errorMessage={errors?.email?.message}
                  required
                  register={register}
                />
              </div>

              <div className="pb-4">
                <InputField
                  id="confirmEmail"
                  name="confirmEmail"
                  type="email"
                  label={t("users.confirmEmail")}
                  errorName={errors?.confirmEmail}
                  errorMessage={errors?.confirmEmail?.message}
                  required
                  register={register}
                />
              </div>

              <div className="pb-4">
                <InputField
                  id="phone"
                  name="phone"
                  type="text"
                  label={t("users.phone")}
                  errorName={errors?.phone}
                  errorMessage={errors?.phone?.message}
                  required
                  register={register}
                />
              </div>

              <div className="pb-4">
                <label
                  htmlFor="province"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {t("users.role")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <select
                  {...register("userCompanyType", { required: true })}
                  id="userCompanyType"
                  name="userCompanyType"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                >
                  <option value="">{t("users.selectRole")}</option>
                  {isBuy &&
                    user?.userCompanyType === USER_COMPANY_TYPES.ADMIN && (
                      <>
                        {USER_COMPANY_LIST_FOR_BUYER_ADMIN?.map((type) => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </>
                    )}
                  {isBuy &&
                    user?.userCompanyType === USER_COMPANY_TYPES.MANAGER && (
                      <>
                        {USER_COMPANY_LIST_FOR_BUYER_MANAGER?.map((type) => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </>
                    )}
                  {isBuy &&
                    user?.userCompanyType === USER_COMPANY_TYPES.BUYER && (
                      <>
                        {USER_COMPANY_LIST_FOR_BUYER?.map((type) => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </>
                    )}
                  {isSeller &&
                    user?.userCompanyType === USER_COMPANY_TYPES.ADMIN && (
                      <>
                        {USER_COMPANY_LIST_FOR_SELLER_ADMIN?.map((type) => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </>
                    )}
                  {isSeller &&
                    user?.userCompanyType === USER_COMPANY_TYPES.MANAGER && (
                      <>
                        {USER_COMPANY_LIST_FOR_SELLER_MANAGER?.map((type) => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </>
                    )}
                  {isSeller &&
                    user?.userCompanyType === USER_COMPANY_TYPES.COMMERCIAL && (
                      <>
                        {USER_COMPANY_LIST_FOR_SELLER?.map((type) => (
                          <option key={type.id} value={type.type}>
                            {type.name}
                          </option>
                        ))}
                      </>
                    )}
                </select>
                {errors?.userCompanyType && (
                  <span className="text-xs text-red-500">
                    {errors?.userCompanyType?.message}
                  </span>
                )}
              </div>

              <div className="pb-3">
                <label
                  htmlFor="parent"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {t("users.responsible")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <select
                  {...register("parent", { required: true })}
                  id="parent"
                  name="parent"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setParentId(e.target.value)
                  }
                >
                  <option value="">{t("users.parentSelect")}</option>
                  {usersByCompany?.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.fullName}
                    </option>
                  ))}
                  <option value={user?.id}>{user?.fullName}</option>
                </select>
              </div>

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end gap-2 items-center">
                <Button link to="/users" variant="cancel">
                  {t("cta.cancel")}
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  {t("cta.add")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("users.add")}
        message={t("users.addQuestion")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserForm);
