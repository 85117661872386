import axios from "../axios-orders";

export default function useMaterialsOffers() {
  const onGetAllMaterialOffers = async (page = 0) => {
    const { data } = await axios.get(`/materialOffer/list/${page}`);
    return data;
  };

  const onGetMaterialOffers = async (materialId: string, page = 0) => {
    const { data } = await axios.get(
      `/materialOffer/material/${materialId}/${page}`
    );
    return data;
  };

  const onGetMaterialOffer = async (offerId: string) => {
    const { data } = await axios.get(`/materialOffer/${offerId}`);
    return data;
  };

  const onGetAllCompanyMaterialOffers = async (page = 0) => {
    const { data } = await axios.get(`/materialOffer/list/company/${page}`);
    return data;
  };

  const onCreateMaterialOffer = async (body: any) => {
    const { data } = await axios.post(`/materialOffer`, body);
    return data;
  };

  const onCreateMaterialOfferList = async (body: any) => {
    const { data } = await axios.post(`/materialOffer/list`, body);
    return data;
  };

  const onDelegateMaterialOffer = async ({
    materialOfferId,
    body,
  }: {
    materialOfferId: string;
    body: any;
  }) => {
    const { data } = await axios.put(`materialOffer/${materialOfferId}`, body);
    return data;
  };

  const onRequestSample = async (materialOfferId: string) => {
    const { data } = await axios.get(
      `/materialOffer/sample/${materialOfferId}`
    );
    return data;
  };

  const onQualifySample = async ({
    materialOfferId,
    body,
  }: {
    materialOfferId: string;
    body: any;
  }) => {
    const { data } = await axios.put(`/materialOffer/${materialOfferId}`, body);
    return data;
  };

  const onCreateMaterialOfferInvitation = async (body: any) => {
    const { data } = await axios.post(`/materialInvitation`, body);
    return data;
  };

  const onCheckMaterialOffer = async ({
    materialId,
    buyerId,
  }: {
    materialId: string;
    buyerId: string;
  }) => {
    const { data } = await axios.get(
      `/buyerSeller/seller/material/${materialId}/buyer/${buyerId}`
    );
    return data;
  };

  return {
    onQualifySample,
    onRequestSample,
    onGetMaterialOffer,
    onGetMaterialOffers,
    onCheckMaterialOffer,
    onCreateMaterialOffer,
    onGetAllMaterialOffers,
    onDelegateMaterialOffer,
    onCreateMaterialOfferList,
    onGetAllCompanyMaterialOffers,
    onCreateMaterialOfferInvitation,
  };
}
