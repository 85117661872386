import { Link } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import {
  addressFormatter,
  numberFormatter,
  PN_TYPE,
  TO_BE_AGREED,
} from "../../../utils";
import { InvitationResponse, MaterialResponse } from "../../../types";

type UserMaterialOpportunitiesTableRowProps = {
  opportunity?: MaterialResponse;
  isNotValidated: boolean;
  handleOpenBanner: (e: any) => void;
  materialInvitationsOpportunities?: InvitationResponse;
};

const UserMaterialOpportunitiesTableRow = ({
  opportunity,
  isNotValidated,
  handleOpenBanner,
  materialInvitationsOpportunities,
}: UserMaterialOpportunitiesTableRowProps) => {
  const isData = materialInvitationsOpportunities
    ? materialInvitationsOpportunities?.material
    : opportunity;

  const PI_NPI = isData?.category?.parent?.parent?.parent?.parent?.name;
  return (
    <tr>
      <td className="px-2 py-2 whitespace-nowrap text-sm">
        <div className="flex items-center">
          <Link
            to={!isNotValidated ? `/opportunities/material/${isData?.id}` : ""}
            className="truncate hover:text-gray-600"
            onClick={
              isNotValidated ? (e: any) => handleOpenBanner(e) : undefined
            }
          >
            <span>{isData?.globalMaterialName}</span>
          </Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {isData?.annualVolume && numberFormatter.format(isData?.annualVolume)}{" "}
          {isData?.measurementUnit}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {PI_NPI === PN_TYPE.PI ? isData?.deliveryFormat : " - "}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {PI_NPI === PN_TYPE.PI ? isData?.incoTerm : " - "}
        </div>
      </td>
      <td className="px-2 py-2">
        <div className="text-xs text-gray-500">
          {isData?.deliveryPlace
            ? addressFormatter(isData?.deliveryPlace?.address)
            : TO_BE_AGREED}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
        <Link
          to={!isNotValidated ? `/opportunities/material/${isData?.id}` : ""}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default UserMaterialOpportunitiesTableRow;
