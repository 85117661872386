import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import i18next from "../../../i18n";
import {
  OpportunitiesTable,
  Loader,
  JointOrdersTable,
} from "../../../components";
import { useOpportunities } from "../../../hooks";
import { BIDDING_TYPES } from "../../../utils";
import { OrderResponse } from "../../../types";

export default function Seller() {
  const { onGetOpportunities, onGetMultipleOpportunities } = useOpportunities();

  const opportunities = useQuery({
    queryKey: ["opportunities"],
    queryFn: () => onGetOpportunities(),
  });

  const multipleOpportunities = useQuery({
    queryKey: ["multipleOpportunities"],
    queryFn: () =>
      onGetMultipleOpportunities(
        BIDDING_TYPES.JOINT,
        BIDDING_TYPES.INVERSE_JOINT
      ),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items as [OrderResponse[]];
    },
  });

  return (
    <Fragment>
      {opportunities?.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {opportunities?.data?.length > 0 && (
            <OpportunitiesTable
              title={i18next.t("material.tenders")}
              opportunities={opportunities?.data}
            />
          )}
          {multipleOpportunities?.data &&
            multipleOpportunities.data.length > 0 && (
              <JointOrdersTable
                orders={multipleOpportunities.data}
                title={i18next.t("order.joint")}
              />
            )}
        </Fragment>
      )}
    </Fragment>
  );
}
