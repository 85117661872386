import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import i18next from "../../i18n";
import {
  Loader,
  UserMaterialOpportunitiesTable,
  CategoriesBuilder,
  UserMaterialOpportunityBox,
} from "../../components";
import {
  useCategories,
  useMaterialOpportunities,
  useMaterials,
  useMaterialsOffers,
  useUserMaterialCategories,
} from "../../hooks";
import { mapDispatchToProps } from "../../utils";
import { MaterialType } from "../../types";

const Opportunities = ({
  onOpenNotification,
}: {
  onOpenNotification: (message: string) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pnType, setPnType] = useState<MaterialType>(MaterialType.PI);

  const [materialOpportunitiesByFamily, setMaterialOpportunitiesByFamily] =
    useState<any>(null);
  const [opportunitiesByLastIds, setOpportunitiesByLastIds] =
    useState<any>(null);

  const [pageNumber, setPageNumber] = useState(0);
  const [familyPage, setFamilyPage] = useState(0);
  const [pageInvitationsNumber, setPageInvitationsNumber] = useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [pageMaterialInvitationsNumber, setPageMaterialInvitationsNumber] =
    useState(0);
  const {
    onGetInvitationsByEmail,
    onGetMaterialOpportunitiesByFamily,
    onGetUserMaterialOpportunitiesInvitations,
  } = useMaterialOpportunities();
  const { onSearchOpportunitiesByLastIds, onGetOpportunitiesByLastIds } =
    useMaterials();
  const { onCreateMaterialOfferList } = useMaterialsOffers();
  const { onGetUserMaterialCategories } = useUserMaterialCategories();
  const { onGetAvailableCountries } = useCategories();

  const availableCountries = useQuery({
    queryKey: ["availableCountries"],
    queryFn: () => onGetAvailableCountries(),
  });

  const userMaterialCategories = useQuery({
    queryKey: ["userMaterialCategories"],
    queryFn: () => onGetUserMaterialCategories(),
  });

  const materialInvitationsOpportunities = useQuery({
    queryKey: [
      "materialInvitationsOpportunities",
      pageMaterialInvitationsNumber,
    ],
    queryFn: () =>
      onGetUserMaterialOpportunitiesInvitations(pageMaterialInvitationsNumber),
  });

  const invitationsByEmail = useQuery({
    queryKey: ["invitationsByEmail", pageInvitationsNumber],
    queryFn: () => onGetInvitationsByEmail(pageInvitationsNumber),
  });

  const {
    mutate: getMaterialOpportunitiesByFamily,
    isPending: isGettingOpportunities,
  } = useMutation({
    mutationFn: ({
      page,
      countryIds,
      categoryIds,
    }: {
      page: number;
      countryIds: string[];
      categoryIds: string[];
    }) => onGetMaterialOpportunitiesByFamily({ page, countryIds, categoryIds }),
    onSuccess: (res) => {
      setOpportunitiesByLastIds(null);
      setMaterialOpportunitiesByFamily(res);
    },
  });

  const {
    mutate: createMaterialOfferList,
    isPending: isCreatingMaterialOfferList,
  } = useMutation({
    mutationFn: (body: any) => onCreateMaterialOfferList(body),
    onSuccess: () => {
      onOpenNotification(i18next.t("materialOffer.success"));
    },
    onError: () => {
      onOpenNotification(i18next.t("newMaterialForm.error"));
    },
  });

  const {
    mutate: getOpportunitiesByLastIds,
    isPending: isLoadingOpportunities,
  } = useMutation({
    mutationFn: onGetOpportunitiesByLastIds,
    onSuccess: (res) => {
      setMaterialOpportunitiesByFamily(null);
      setOpportunitiesByLastIds(res);
    },
  });

  const {
    data: foundOpportunitiesByLastIds,
    mutate: searchOpportunitiesByLastIds,
    isPending: isSearching,
  } = useMutation({
    mutationFn: onSearchOpportunitiesByLastIds,
  });

  useEffect(() => {
    const pnType = localStorage.getItem("pnType") as MaterialType;
    if (pnType) setPnType(pnType);
    if (userMaterialCategories?.data?.length > 0) {
      const userPnType =
        userMaterialCategories.data[0]?.category?.parent?.parent?.parent?.parent
          ?.name;
      setPnType(userPnType);
    }
  }, []);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const handleInvitationsPage = (page: number) =>
    setPageInvitationsNumber(page);
  const handleMaterialInvitationsPage = (page: number) =>
    setPageMaterialInvitationsNumber(page);
  const handleMaterialOpportunitiesPage = (page: number) => setPageNumber(page);
  const handleSearchMaterialOpportunitiesPage = (page: number) =>
    setSearchPage(page);
  const handleFamilyPage = (page: number) => setFamilyPage(page);

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("opportunities.title")}
      </h2>
      <p className="py-3 text-sm">{i18next.t("opportunities.subtitle")}</p>

      {materialInvitationsOpportunities?.data?.content?.length > 0 && (
        <UserMaterialOpportunitiesTable
          pageNumber={pageMaterialInvitationsNumber}
          setPageNumber={handleMaterialInvitationsPage}
          materialInvitationsOpportunities={
            materialInvitationsOpportunities?.data
          }
        />
      )}

      {invitationsByEmail?.data?.content?.length > 0 && (
        <UserMaterialOpportunitiesTable
          pageNumber={pageInvitationsNumber}
          setPageNumber={handleInvitationsPage}
          materialInvitationsOpportunities={invitationsByEmail?.data}
        />
      )}

      {userMaterialCategories?.data && (
        <CategoriesBuilder
          pnType={pnType}
          setPnType={setPnType}
          pageNumber={pageNumber}
          searchPage={searchPage}
          searchTerm={searchTerm}
          familyPage={familyPage}
          onChangeSearch={onChangeSearch}
          availableCountries={availableCountries?.data}
          userMaterialCategories={userMaterialCategories?.data}
          onGetOpportunitiesByLastIds={getOpportunitiesByLastIds}
          onSearchOpportunitiesByLastIds={searchOpportunitiesByLastIds}
          onGetMaterialOpportunitiesByFamily={getMaterialOpportunitiesByFamily}
        />
      )}

      {isGettingOpportunities ||
      isCreatingMaterialOfferList ||
      isLoadingOpportunities ||
      isSearching ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <>
          {searchTerm === "" && opportunitiesByLastIds?.content?.length > 0 ? (
            <UserMaterialOpportunitiesTable
              pageNumber={pageNumber}
              setPageNumber={handleMaterialOpportunitiesPage}
              opportunitiesByLastIds={opportunitiesByLastIds}
            />
          ) : null}

          {searchTerm !== "" &&
            foundOpportunitiesByLastIds?.content?.length > 0 && (
              <UserMaterialOpportunitiesTable
                pageNumber={searchPage}
                setPageNumber={handleSearchMaterialOpportunitiesPage}
                foundOpportunitiesByLastIds={foundOpportunitiesByLastIds}
              />
            )}

          {(opportunitiesByLastIds?.content?.length === 0 ||
            !opportunitiesByLastIds) &&
            (materialOpportunitiesByFamily?.content?.length === 0 ||
              !materialOpportunitiesByFamily) && (
              <h4 className="text-xs leading-7 text-gray-900 sm:truncate pt-4">
                {i18next.t("opportunities.noOpportunities")}
              </h4>
            )}

          {materialOpportunitiesByFamily?.content?.length > 0 && (
            <>
              {!materialOpportunitiesByFamily?.content[0]?.siblings?.every(
                (item: any) => item.materials.length === 0
              ) && (
                <UserMaterialOpportunityBox
                  materialOpportunitiesByFamily={materialOpportunitiesByFamily}
                  onCreateMaterialOfferList={createMaterialOfferList}
                  setFamilyPage={handleFamilyPage}
                  familyPage={familyPage}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Opportunities);
