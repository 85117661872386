import axios from "../axios-orders";

export default function useMaterialOpportunities() {
  const onGetMaterialOpportunitiesByFamily = async ({
    page = 0,
    countryIds,
    categoryIds,
  }: {
    page: number;
    countryIds: string[];
    categoryIds: string[];
  }) => {
    const { data } = await axios.post(
      `/user/material/opportunities/family/${page}?countryIds=${countryIds.join(
        ","
      )}`,
      categoryIds
    );
    return data;
  };

  const onGetNewMaterialCompanyOpportunities = async (
    materialId: string,
    page = 0
  ) => {
    const { data } = await axios.get(
      `/user/material/id/opportunities/${materialId}/${page}`
    );
    return data;
  };

  const onGetNewMaterialCompanyOpportunitiesByCountry = async (
    materialId: string,
    countryId: string,
    page = 0
  ) => {
    const { data } = await axios.get(
      `/user/material/id/opportunities/${materialId}/country/${countryId}/${page}`
    );
    return data;
  };

  const onGetUserMaterialOpportunitiesInvitations = async (page = 0) => {
    const { data } = await axios.get(`/materialInvitation/user/${page}`);
    return data;
  };

  const onGetInvitationsByEmail = async (page = 0) => {
    const { data } = await axios.get(`/materialInvitation/userEmail/${page}`);
    return data;
  };

  return {
    onGetInvitationsByEmail,
    onGetMaterialOpportunitiesByFamily,
    onGetNewMaterialCompanyOpportunities,
    onGetUserMaterialOpportunitiesInvitations,
    onGetNewMaterialCompanyOpportunitiesByCountry,
  };
}
