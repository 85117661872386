import { useMemo, useState } from "react";
import i18next from "../../i18n";
import { currencyFormatter } from "../../utils";

type CompanyAwardItemProps = {
  event: any;
  offers: any;
  actions: any;
  updateEvent: any;
};

const CompanyAwardItem = ({
  event,
  offers,
  actions,
  updateEvent,
}: CompanyAwardItemProps) => {
  const [selectedOffers, setSelectedOffers] = useState([]);

  const sortedOffers = offers.sort((a: any, b: any) =>
    a.materialName.localeCompare(b.materialName)
  );

  const handleCheckboxChange = (offer: any) => {
    const isSelected = selectedOffers.includes(offer.id as never);
    setSelectedOffers((prevSelectedOffers: any) =>
      isSelected
        ? prevSelectedOffers.filter((id: any) => id !== offer.id)
        : [...prevSelectedOffers, offer.id]
    );

    updateEvent({
      type: actions.UPDATE_ON_CHECKBOX_CHANGE,
      id: offer.id,
      price: offer.price,
      volume: offer.order.quantity,
      orderId: offer.order.id,
      material: offer.order.material.globalMaterialName,
      offer: offer,
    });

    if (!isSelected) return;

    updateEvent({ type: actions.DELETE_OFFER, id: offer.id });
  };

  const totalPriceQuantity = useMemo(() => {
    return offers?.reduce((acc: any, offer: any) => {
      return (
        acc +
        (offer?.offer
          ? (event.offersToAccept.find((o: any) => o.id === offer.offer.id)
              ?.volume ?? offer.offer.order.quantity) * offer.offer.price
          : 0)
      );
    }, 0);
  }, [offers, event]);

  const offersData = offers?.find(
    (item: any) => item.offer !== null && item.offer !== undefined
  );

  const currency = currencyFormatter(
    totalPriceQuantity,
    offersData?.offer?.order?.currency
  );

  return (
    <div className="border flex">
      <div>
        <p className="text-xs text-center p-2 border-b uppercase font-bold text-spectum truncate max-w-xs">
          {offersData.seller}
        </p>
        <div className="text-center flex w-full">
          <p className="text-xs w-full border-r border-b p-2 uppercase font-bold text-gray-600">
            {i18next.t("award.finalVolume")} -{" "}
            <span>({offersData?.offer?.order?.measurementUnit})</span>
          </p>
          <p className="text-xs w-full border-b p-2 uppercase font-bold text-gray-600">
            {i18next.t("award.finalPrice")} -{" "}
            <span>({offersData?.offer?.order?.currency})</span>
          </p>
        </div>
        {sortedOffers?.map((offer: any) => {
          const currentOffer = event?.offersToAccept?.find(
            (o: any) => o.id === offer?.offer?.id
          );
          const hasError = event.errors.find(
            (error: any) => error?.orderId === offer?.offer?.order?.id
          );
          if (offer?.offer) {
            return (
              <div
                className={`flex border-b ${
                  offer?.isBestOffer ? "bg-spectum-success" : "bg-white"
                }`}
                key={offer?.offer?.id}
              >
                <div className="p-2 border-r w-full">
                  <input
                    type="number"
                    className={`rounded text-xs shadow w-120 focus:border-transparent border-none ${
                      hasError ? "text-red-500 font-bold" : ""
                    } `}
                    disabled={
                      !selectedOffers?.includes(offer?.offer?.id as never)
                    }
                    value={
                      currentOffer?.volume ?? offer?.offer?.order?.quantity
                    }
                    min={0}
                    onChange={(e) =>
                      updateEvent({
                        type: actions.UPDATE_VOLUME,
                        id: offer.offer.id,
                        volume: e.target.value,
                        orderId: offer.offer.order.id,
                      })
                    }
                  />
                </div>
                <div className="p-2 w-full">
                  <input
                    type="number"
                    className="rounded border-gray-200 text-xs shadow w-120 border-none"
                    disabled={
                      !selectedOffers?.includes(offer?.offer?.id as never)
                    }
                    value={currentOffer?.price ?? offer?.offer?.price}
                    min={0}
                    onChange={(e) =>
                      updateEvent({
                        type: actions.UPDATE_PRICE,
                        id: offer.offer.id,
                        price: e.target.value,
                        orderId: offer.offer.order.id,
                      })
                    }
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div className="p-6 border-b bg-white" key={Math.random()} />
            );
          }
        })}
        <div
          className={`focus:border-transparent border-none flex ${
            offersData?.isBestAccumulate
              ? "bg-spectum-success font-bold"
              : "bg-white"
          }`}
        >
          <div className="font-bold text-xs p-3 border-r w-full">TOTAL</div>
          <div className="text-xs w-full p-3 border-r">
            {offersData?.offer?.order?.currency !== "ARS"
              ? offersData?.offer?.order?.currency
              : ""}{" "}
            {totalPriceQuantity && currency}
          </div>
        </div>
      </div>
      <div>
        <div className="text-center flex w-full p-4 border-b"></div>
        <div className="text-center flex w-full p-2 border-b border-l">
          <p className="text-xs uppercase font-bold text-gray-600">
            {i18next.t("tenders.form.select")}
          </p>
        </div>
        {sortedOffers?.map((offer: any) => {
          if (offer?.offer) {
            return (
              <div
                key={offer?.offer?.id}
                className={`p-4 border-l border-b w-full flex items-center justify-center ${
                  offer?.isBestOffer ? "bg-spectum-success" : "bg-white"
                }`}
              >
                <input
                  type="checkbox"
                  className="rounded shadow text-xs"
                  checked={selectedOffers.includes(offer?.offer?.id as never)}
                  onChange={() => handleCheckboxChange(offer?.offer)}
                />
              </div>
            );
          } else {
            return (
              <div
                key={Math.random()}
                className="p-6 w-full border-b flex items-center justify-center bg-white"
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default CompanyAwardItem;
