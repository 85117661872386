import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { TooltipSP } from "../../../../components";
import i18next from "../../../../i18n";
import { DELIVERY_TYPE, MaterialType } from "../../../../types";

type PeriodDateProps = {
  matToBid: any;
  handleSupplyPeriod: any;
};

export default function PeriodDate({
  matToBid,
  handleSupplyPeriod,
}: PeriodDateProps) {
  return (
    <div className="md:col-span-2 flex items-start gap-3 relative w-full">
      <div className="w-full">
        <label
          htmlFor="spot"
          className="uppercase block text-xs font-medium text-gray-700"
        >
          {matToBid.type === MaterialType.SERVICE
            ? i18next.t("tenders.form.time")
            : i18next.t("tenders.form.tenderPeriod")}
          <span className="text-sm text-red-600"> *</span>
        </label>
        <div className="flex items-center gap-4 mt-2">
          <div className="flex items-center gap-2">
            <input
              name="tenderPeriod"
              id="spot"
              type="radio"
              value={DELIVERY_TYPE.SPOT}
              checked={matToBid.spot === DELIVERY_TYPE.SPOT}
              onChange={(e) => handleSupplyPeriod(e, DELIVERY_TYPE.SPOT)}
              className="cursor-pointer"
            />
            <label
              className="uppercase text-xs text-gray-700 font-medium"
              htmlFor="spot"
            >
              {i18next.t("tenders.form.spot")}
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              name="tenderPeriod"
              id="period"
              type="radio"
              value={DELIVERY_TYPE.PERIOD}
              checked={matToBid.spot === DELIVERY_TYPE.PERIOD}
              className="cursor-pointer"
              onChange={(e) => handleSupplyPeriod(e, DELIVERY_TYPE.SPOT)}
            />
            <label
              className="uppercase text-xs text-gray-700 font-medium"
              htmlFor="period"
            >
              {i18next.t("tenders.form.period")}
            </label>
          </div>
        </div>
      </div>
      {matToBid.spot && (
        <div className="flex items-center gap-3 relative w-full">
          <div className="absolute -top-5 left-0">
            <p className="uppercase text-xs font-medium text-gray-700 pb-2 flex gap-1">
              {matToBid.spot
                ? i18next.t("tenders.form.deliveryDate")
                : i18next.t("tenders.form.tenderPeriod")}
              <QuestionMarkCircleIcon
                className="h-4 w-4"
                id={`tender-period-${matToBid.id}`}
              />
            </p>
            <TooltipSP
              id={`tender-period-${matToBid.id}`}
              message={
                matToBid.spot
                  ? i18next.t("tenders.form.one")
                  : i18next.t("tenders.form.periodInfo")
              }
            />
          </div>
          {matToBid.spot === DELIVERY_TYPE.SPOT ? (
            <div className="w-full">
              <label
                htmlFor="startSupplyDate"
                className="uppercase block text-xs font-medium text-gray-700"
              >
                {i18next.t("tenders.form.date")}
                <span className="text-sm text-red-600"> *</span>
              </label>
              <input
                className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                id="startSupplyDate"
                name="startSupplyDate"
                type="date"
                value={matToBid.startSupplyDate}
                onChange={(e) => handleSupplyPeriod(e, "start")}
                required
              />
            </div>
          ) : (
            <>
              <div className="w-full">
                <label
                  htmlFor="startSupplyDate"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("tenders.form.from")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <input
                  className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                  id="startSupplyDate"
                  name="startSupplyDate"
                  type="date"
                  value={matToBid.startSupplyDate}
                  onChange={(e) => handleSupplyPeriod(e, "start")}
                  required
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="endSupplyDate"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("tenders.form.to")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <input
                  className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                  id="endSupplyDate"
                  name="endSupplyDate"
                  type="date"
                  min={matToBid.startSupplyDate}
                  disabled={!matToBid.startSupplyDate}
                  value={matToBid.endSupplyDate}
                  onChange={(e) => handleSupplyPeriod(e, "end")}
                  required
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
