import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  mapStateToProps,
  PILL_LABELS,
  BIDDING_STATES,
  BIDDING_LABELS,
  BIDDING_TYPES,
} from "../../utils";
import { Pill } from "..";
import { OrderResponse } from "../../types";

const HistoricItem = ({ historic }: { historic: OrderResponse }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <li>
      <div
        onClick={
          historic?.type === BIDDING_TYPES.JOINT ||
          historic?.type === BIDDING_TYPES.INVERSE_JOINT
            ? () => navigate(`/order/multiple/${historic.id}`)
            : () => navigate(`/order/${historic.id}`)
        }
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex items-center px-2 py-2">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-xs font-medium truncate uppercase">
                  {historic?.material?.globalMaterialName}{" "}
                  <span className="text-gray-500">
                    {historic?.material?.ean}
                  </span>
                </p>
                <p className="mt-2 flex items-center text-xs">
                  {historic?.state === BIDDING_STATES.SUCCEED ||
                  historic?.state === BIDDING_STATES.CLOSED ||
                  historic?.state === BIDDING_STATES.CANCELLED ? (
                    <span className="truncate uppercase text-green-700">
                      {t("historic.tenderPast")} {historic?.quantity}{" "}
                      {historic?.measurementUnit}
                    </span>
                  ) : (
                    <span className="truncate uppercase text-spectum">
                      {t("historic.tenderActive")} {historic?.quantity}{" "}
                      {historic?.measurementUnit}
                    </span>
                  )}
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  {historic?.state === BIDDING_STATES.SUCCEED ||
                  historic?.state === BIDDING_STATES.CLOSED ? (
                    <p className="text-xs text-gray-900">
                      {t("historic.tenderFinish")}{" "}
                      <time dateTime={historic?.finishDate}>
                        {historic?.finishDate}
                      </time>
                    </p>
                  ) : (
                    <p className="text-xs text-gray-900">
                      {t("historic.tenderStart")} {historic?.startDate} -{" "}
                      {t("historic.tenderEnds")} {historic?.finishDate}
                    </p>
                  )}
                </div>
                {historic.active && (
                  <>
                    {historic.state === BIDDING_STATES.OPEN && (
                      <Pill label={PILL_LABELS.IN_PROGRESS} />
                    )}
                    {historic.state === BIDDING_STATES.CLOSED && (
                      <Pill label={PILL_LABELS.OFFER_PENDING_OF_SELECTION} />
                    )}
                    {historic.state === BIDDING_STATES.SUCCEED && (
                      <Pill label={PILL_LABELS.OFFER_SELECTED} />
                    )}
                    {historic.state === BIDDING_STATES.CANCELLED && (
                      <Pill label={PILL_LABELS.BIDDING_CANCELED} />
                    )}
                    {historic.state === BIDDING_STATES.PENDING && (
                      <Pill label={BIDDING_LABELS.SCHEDULED} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default connect(mapStateToProps)(HistoricItem);
