import { useState } from "react";
import { OrdersTable, Loader } from "../../../components";
import { useQuery } from "@tanstack/react-query";
import {
  ORDER_TYPES,
  OrderState,
  SimpleOrderPaginatedResponse,
} from "../../../types";

type CancelledProps = {
  onGetOrders: (
    state: OrderState,
    type: ORDER_TYPES,
    _type: ORDER_TYPES,
    page: number
  ) => Promise<SimpleOrderPaginatedResponse>;
};

export const Cancelled = ({ onGetOrders }: CancelledProps) => {
  const [cancelledPageNumber, setCancelledPageNumber] = useState(0);

  const orders = useQuery<SimpleOrderPaginatedResponse>({
    queryKey: ["orders", cancelledPageNumber],
    queryFn: () =>
      onGetOrders(
        OrderState.CANCELLED,
        ORDER_TYPES.NORMAL,
        ORDER_TYPES.INVERSE,
        cancelledPageNumber
      ),
  });

  const handleCancelledPageNumber = (page: number) =>
    setCancelledPageNumber(page);

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders && orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={cancelledPageNumber}
              setPageNumber={handleCancelledPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
