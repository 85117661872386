import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { SitesTable, Loader } from "../../components";
import { useSites } from "../../hooks";
import { mapStateToProps } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { UserResponse } from "../../types";

const Sites = ({ user }: { user: UserResponse }) => {
  const { t } = useTranslation();
  const { onGetSites } = useSites();

  const sites = useQuery({
    queryKey: ["sites", user?.company?.id],
    queryFn: () => onGetSites(user?.company?.id),
  });

  return (
    <div className="px-4 mt-6">
      <div className="md:flex items-center justify-between">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {t("sites.title")}
        </h2>
        <Link
          to="/new-site"
          type="button"
          className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
        >
          {t("sites.add")}
        </Link>
      </div>
      {sites.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {sites?.data?.length === 0 ? (
            <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
              {t("sites.noSites")}
            </h4>
          ) : (
            <SitesTable sites={sites?.data} />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Sites);
