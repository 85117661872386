import React, { Fragment, useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import UserMaterialOpportunitiesNPITableRow from "./UserMaterialOpportunitiesNPITableRow/UserMaterialOpportunitiesNPITableRow";
import i18next from "../../i18n";
import { Modal, Pagination, Slide } from "..";
import { useCountries, useLanguage } from "../../hooks";
import { NO_SPEC, mapStateToProps } from "../../utils";
import {
  CountryResponse,
  MaterialOpportunityResponse,
  OpportunityByFamilyResponse,
  UserResponse,
} from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("material.productClass") },
  { id: 2, name: i18next.t("material.merchancyCode") },
  { id: 3, name: i18next.t("navigation.opportunities") },
  { id: 4, name: "" },
];

type UserMaterialOpportunitiesNPITableProps = {
  user: UserResponse;
  familyPage: number;
  setFamilyPage: (page: number) => void;
  npiOpportunities: OpportunityByFamilyResponse[];
  pagination: any;
  onCreateMaterialOfferList: (materialOffers: any) => void;
};

const UserMaterialOpportunitiesNPITable = ({
  user,
  familyPage,
  setFamilyPage,
  npiOpportunities,
  pagination,
  onCreateMaterialOfferList,
}: UserMaterialOpportunitiesNPITableProps) => {
  const langEs = useLanguage();
  const [selectedOpportunities, setSelectedOpportunities] = useState<
    MaterialOpportunityResponse[]
  >([]);
  const [materialOffers, setMaterialOffers] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [origin, setOrigin] = useState("");
  const { onGetPublicCountries } = useCountries();

  const { data: countries } = useQuery<CountryResponse[]>({
    queryKey: ["countries"],
    queryFn: () => onGetPublicCountries(),
  });

  const handleCheckboxChange = (materialId: string) => {
    const indexOfMaterial = materialOffers.findIndex(
      (mat) => materialId === mat.material.id
    );
    if (indexOfMaterial === -1) {
      setMaterialOffers([
        ...materialOffers,
        {
          specificationFileUrl: NO_SPEC,
          material: { id: materialId },
          origin: { id: origin },
          user: { id: user?.id },
          comment: "",
        },
      ]);
    } else {
      const slicedOrder = [
        ...materialOffers.slice(0, indexOfMaterial),
        ...materialOffers.slice(indexOfMaterial + 1),
      ];

      setMaterialOffers(slicedOrder);
    }
  };

  useEffect(() => {
    if (!open) setMaterialOffers([]);
  }, [open]);

  const handleOriginChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMaterialOffers((prevState) => {
      return prevState.map((offer) => {
        return {
          ...offer,
          origin: { id: e.target.value },
        };
      });
    });
    setOrigin(e.target.value);
  };

  const handleOpen = () => setOpenModal(true);

  const handleSubmit = () => {
    setOpenModal(false);
    setOpen(false);
    onCreateMaterialOfferList(materialOffers);
  };

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        onClickCancel={() => setOpenModal(false)}
        onClickSuccess={handleSubmit}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("materialOffer.apply")}
        message={i18next.t("materialOffer.applyQuestion")}
      />
      <div className="mt-4">
        <div className="flex flex-col mt-2">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {tableTh?.map((th) => (
                    <th
                      key={th.id}
                      scope="col"
                      className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {th.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {npiOpportunities?.map(({ siblings }) => {
                  return siblings.map((opportunity) => {
                    if (opportunity.quantity > 0) {
                      return (
                        <UserMaterialOpportunitiesNPITableRow
                          key={opportunity.category.id}
                          opportunity={opportunity}
                          setSelectedOpportunities={setSelectedOpportunities}
                          setOpen={setOpen}
                        />
                      );
                    }
                  });
                })}
              </tbody>
            </table>
            <Pagination
              results={pagination.numberOfElements}
              total={pagination.totalElements}
              amountOfPages={pagination.totalPages}
              currentPage={familyPage}
              setPageNumber={setFamilyPage}
            />
          </div>
        </div>
      </div>
      <Slide open={open} setOpen={setOpen}>
        <h4 className="text-spectum text-xs font-bold uppercase tracking-wide my-3">
          {i18next.t("opportunities.npiOpportunities")}
        </h4>
        <div className="max-h-500 overflow-y-scroll">
          {selectedOpportunities.map(({ id, material, country }) => (
            <Fragment key={id}>
              <legend className="sr-only">{material}</legend>
              <div className="flex items-center gap-4 mb-1">
                <div className="relative flex items-center">
                  <div className="flex items-center h-5">
                    <input
                      id={id}
                      aria-describedby="comments-description"
                      name={id}
                      type="checkbox"
                      className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                      onChange={() => handleCheckboxChange(id)}
                    />
                  </div>
                  <div className="ml-3 text-xs">
                    <label htmlFor={id} className="text-gray-700">
                      <span className="text-gray-900 uppercase font-bold">
                        {material}
                      </span>{" "}
                      - <span className="text-gray-700">{country?.name}</span>
                    </label>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        {materialOffers.length > 0 && (
          <div className="mt-4">
            <label
              htmlFor="origin"
              className="uppercase block text-xs font-medium text-gray-700"
            >
              {i18next.t("materialOffer.origins")}
              <span className="text-sm text-red-600"> *</span>
            </label>
            <select
              id="origin"
              name="origin"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleOriginChange(e)
              }
            >
              <option value="">{i18next.t("materialOffer.select")}</option>
              {countries?.map((country) => (
                <option key={country.id} value={country.id}>
                  {langEs ? country.name : country.nameEn}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex mt-5 justify-end">
          <button
            onClick={handleOpen}
            disabled={!(materialOffers.length > 0 && origin)}
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded ${
              !(materialOffers.length > 0 && origin)
                ? "bg-white text-gray-400"
                : "bg-spectum hover:bg-spectum-light text-white"
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase`}
          >
            {i18next.t("materialOffer.cta")}
          </button>
        </div>
      </Slide>
    </>
  );
};

export default connect(mapStateToProps)(UserMaterialOpportunitiesNPITable);
