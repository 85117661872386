import { Link } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { IT_DOESNT_HAVE_SPEC, NO_SPEC } from "../../../utils";
import { useTranslation } from "react-i18next";
import { MaterialOfferResponse } from "../../../types";

type MaterialOfferTableRowProps = {
  offer: MaterialOfferResponse;
  isCompany?: boolean;
};

const MaterialOfferTableRow = ({
  offer,
  isCompany,
}: MaterialOfferTableRowProps) => {
  const { t } = useTranslation();
  const isSpec = NO_SPEC === offer?.specificationFileUrl;

  const isInitialized = offer?.state === "INITIALIZED";
  const isRequested = offer?.state === "REQUESTED";
  const isRejected = offer?.state === "REJECTED";
  const isVerified = offer?.state === "VERIFIED";

  return (
    <tr key={offer.id}>
      <td className="px-2 py-2 whitespace-nowrap text-sm">
        <div className="flex items-center">
          <Link
            to={`/material/offer/${offer.id}`}
            className="truncate hover:text-gray-600"
          >
            <span>{offer.material?.globalMaterialName}</span>
          </Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">{offer.origin?.name}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {isInitialized ? <p className="font-bold">N/A</p> : null}
          {isRequested ? (
            <p className="font-bold text-yellow-500 uppercase">
              {t("sampleStates.requested")}
            </p>
          ) : null}
          {isRejected ? (
            <p className="font-bold text-red-500 uppercase">
              {t("sampleStates.rejected")}
            </p>
          ) : null}
          {isVerified ? (
            <p className="font-bold text-green-500 uppercase">
              {t("sampleStates.verified")}
            </p>
          ) : null}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {isSpec ? (
            <p>{IT_DOESNT_HAVE_SPEC}</p>
          ) : (
            <a
              href={offer?.specificationFileUrl}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
            >
              <ArrowDownTrayIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              {t("cta.download")}
            </a>
          )}
        </div>
      </td>
      {isCompany && (
        <td className="px-2 py-2 whitespace-nowrap">
          <div className="text-xs text-gray-500">{offer.user?.fullName}</div>
        </td>
      )}
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
        <Link
          to={`/material/offer/${offer.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default MaterialOfferTableRow;
