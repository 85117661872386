import { Fragment } from "react";
import i18Next from "../../i18n";
import { TO_BE_AGREED } from "../../utils";
import { Pagination, TableTitle } from "..";
import MaterialTableRow from "./MaterialTableRow/MaterialTableRow";
import {
  materialsPITh,
  materialsNPITh,
  materialsSerTh,
  materialsByOwnerTh,
  allPICompanyMaterialsTh,
  allNPICompanyMaterialsTh,
  userMaterialsTh,
  foundMaterialsTh,
} from "./table-heads";
import {
  MaterialPaginatedResponse,
  MaterialResponse,
  MaterialWithSuppliersPaginatedResponse,
} from "../../types";

type MaterialsTableProps = {
  services?: MaterialWithSuppliersPaginatedResponse;
  foundAllMaterials?: MaterialResponse[];
  materialsByOwner?: MaterialWithSuppliersPaginatedResponse;
  npiMaterials?: MaterialWithSuppliersPaginatedResponse;
  userMaterials?: MaterialPaginatedResponse;
  allPICompanyMaterials?: MaterialWithSuppliersPaginatedResponse;
  allNPICompanyMaterials?: MaterialWithSuppliersPaginatedResponse;
  piMaterials?: MaterialWithSuppliersPaginatedResponse;
  foundMaterials?: MaterialPaginatedResponse;
  setPageNPINumber?: any;
  setPagePINumber?: any;
  pagePINumber?: number;
  pageNPINumber?: number;
  searchPage?: number;
  setSearchPage?: any;
  pageDelegated?: number;
  setPageDelegated?: any;
  pageUserMaterial?: number;
  setPageUserMaterial?: any;
};

const MaterialsTable = ({
  services,
  foundAllMaterials,
  materialsByOwner,
  npiMaterials,
  userMaterials,
  allPICompanyMaterials,
  allNPICompanyMaterials,
  piMaterials,
  foundMaterials,
  setPageNPINumber,
  setPagePINumber,
  pagePINumber,
  pageNPINumber,
  searchPage,
  setSearchPage,
  pageDelegated,
  setPageDelegated,
  pageUserMaterial,
  setPageUserMaterial,
}: MaterialsTableProps) => {
  return (
    <div className="hidden sm:block mt-4">
      <div className="flex flex-col mt-2">
        {piMaterials && piMaterials?.content?.length > 0 && (
          <Fragment>
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {materialsPITh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {piMaterials?.content?.map(
                    ({
                      material,
                      suppliersQuantity,
                      supplierValidatedQuantity,
                      activeOrdersQuantity,
                    }) => (
                      <MaterialTableRow
                        key={material.id}
                        id={material.id}
                        status={material.active}
                        deliveryPlace={
                          material.deliveryPlace?.name ?? TO_BE_AGREED
                        }
                        annualVolume={material.annualVolume}
                        merchancyCode={material.ean}
                        deliveryFormat={material.deliveryFormat}
                        measurementUnit={material.measurementUnit}
                        globalMaterialName={material.globalMaterialName}
                        suppliersQuantity={suppliersQuantity}
                        supplierValidatedQuantity={supplierValidatedQuantity}
                        activeOrdersQuantity={activeOrdersQuantity}
                      />
                    )
                  )}
                </tbody>
              </table>
              <Pagination
                results={piMaterials.numberOfElements}
                total={piMaterials.totalElements}
                amountOfPages={piMaterials.totalPages}
                currentPage={pagePINumber || 0}
                setPageNumber={setPagePINumber}
              />
            </div>
          </Fragment>
        )}
        {npiMaterials && npiMaterials?.content?.length > 0 && (
          <Fragment>
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {materialsNPITh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {npiMaterials?.content?.map(
                    ({
                      material,
                      suppliersQuantity,
                      supplierValidatedQuantity,
                      activeOrdersQuantity,
                    }) => (
                      <MaterialTableRow
                        key={material.id}
                        id={material.id}
                        status={material.active}
                        deliveryPlace={
                          material.deliveryPlace?.name ?? TO_BE_AGREED
                        }
                        merchancyCode={material.ean}
                        measurementUnit={material.measurementUnit}
                        annualVolume={material.annualVolume}
                        globalMaterialName={material.globalMaterialName}
                        suppliersQuantity={suppliersQuantity}
                        supplierValidatedQuantity={supplierValidatedQuantity}
                        activeOrdersQuantity={activeOrdersQuantity}
                      />
                    )
                  )}
                </tbody>
              </table>
              <Pagination
                results={npiMaterials.numberOfElements}
                total={npiMaterials.totalElements}
                amountOfPages={npiMaterials.totalPages}
                currentPage={pageNPINumber || 0}
                setPageNumber={setPageNPINumber}
              />
            </div>
          </Fragment>
        )}
        {services && services?.content?.length > 0 && (
          <Fragment>
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {materialsSerTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {services?.content?.map(
                    ({
                      material,
                      suppliersQuantity,
                      supplierValidatedQuantity,
                      activeOrdersQuantity,
                    }) => (
                      <MaterialTableRow
                        key={material.id}
                        service
                        id={material.id}
                        status={material.active}
                        deliveryPlace={
                          material.deliveryPlace?.name ?? TO_BE_AGREED
                        }
                        merchancyCode={material.ean}
                        deliveryFormat={material.deliveryFormat}
                        measurementUnit={material.measurementUnit}
                        annualVolume={material.annualVolume}
                        globalMaterialName={material.globalMaterialName}
                        suppliersQuantity={suppliersQuantity}
                        supplierValidatedQuantity={supplierValidatedQuantity}
                        activeOrdersQuantity={activeOrdersQuantity}
                      />
                    )
                  )}
                </tbody>
              </table>
              <Pagination
                results={services.numberOfElements}
                total={services.totalElements}
                amountOfPages={services.totalPages}
                currentPage={pageNPINumber || 0}
                setPageNumber={setPageNPINumber}
              />
            </div>
          </Fragment>
        )}
        {foundMaterials && foundMaterials?.content?.length > 0 && (
          <Fragment>
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {foundMaterialsTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {foundMaterials.content?.map((mat) => (
                    <MaterialTableRow
                      key={mat.id}
                      id={mat.id}
                      status={mat.active}
                      deliveryFormat={mat.deliveryFormat}
                      deliveryPlace={mat.deliveryPlace?.name ?? TO_BE_AGREED}
                      merchancyCode={mat.ean}
                      measurementUnit={mat.measurementUnit}
                      globalMaterialName={mat.globalMaterialName}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                results={foundMaterials.numberOfElements}
                total={foundMaterials.totalElements}
                amountOfPages={foundMaterials.totalPages}
                currentPage={searchPage || 0}
                setPageNumber={setSearchPage}
              />
            </div>
          </Fragment>
        )}
        {materialsByOwner && materialsByOwner?.content?.length > 0 && (
          <Fragment>
            <TableTitle title={i18Next.t("titles.delegated")} />
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {materialsByOwnerTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {materialsByOwner?.content?.map(
                    ({
                      material,
                      suppliersQuantity,
                      supplierValidatedQuantity,
                      activeOrdersQuantity,
                    }) => (
                      <MaterialTableRow
                        key={material.id}
                        id={material.id}
                        status={material.active}
                        annualVolume={material.annualVolume}
                        deliveryPlace={
                          material.deliveryPlace?.name ?? TO_BE_AGREED
                        }
                        merchancyCode={material.ean}
                        deliveryFormat={material.deliveryFormat}
                        measurementUnit={material.measurementUnit}
                        responsible={material.owner.fullName}
                        globalMaterialName={material.globalMaterialName}
                        suppliersQuantity={suppliersQuantity}
                        supplierValidatedQuantity={supplierValidatedQuantity}
                        activeOrdersQuantity={activeOrdersQuantity}
                      />
                    )
                  )}
                </tbody>
              </table>
              <Pagination
                results={materialsByOwner.numberOfElements}
                total={materialsByOwner.totalElements}
                amountOfPages={materialsByOwner.totalPages}
                currentPage={pageDelegated || 0}
                setPageNumber={setPageDelegated}
              />
            </div>
          </Fragment>
        )}
        {allPICompanyMaterials &&
          allPICompanyMaterials?.content?.length > 0 && (
            <Fragment>
              <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {allPICompanyMaterialsTh?.map((th) => (
                        <th
                          key={th.id}
                          scope="col"
                          className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {th.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {allPICompanyMaterials?.content?.map(
                      ({
                        material,
                        suppliersQuantity,
                        supplierValidatedQuantity,
                        activeOrdersQuantity,
                      }) => (
                        <MaterialTableRow
                          key={material.id}
                          id={material.id}
                          status={material.active}
                          suppliersQuantity={suppliersQuantity}
                          supplierValidatedQuantity={supplierValidatedQuantity}
                          activeOrdersQuantity={activeOrdersQuantity}
                          annualVolume={material.annualVolume}
                          deliveryFormat={material.deliveryFormat}
                          merchancyCode={material.ean}
                          responsible={material.owner.fullName}
                          measurementUnit={material.measurementUnit}
                          globalMaterialName={material.globalMaterialName}
                        />
                      )
                    )}
                  </tbody>
                </table>
                <Pagination
                  results={allPICompanyMaterials.numberOfElements}
                  total={allPICompanyMaterials.totalElements}
                  amountOfPages={allPICompanyMaterials.totalPages}
                  currentPage={pagePINumber || 0}
                  setPageNumber={setPagePINumber}
                />
              </div>
            </Fragment>
          )}
        {allNPICompanyMaterials &&
          allNPICompanyMaterials?.content?.length > 0 && (
            <Fragment>
              <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {allNPICompanyMaterialsTh?.map((th) => (
                        <th
                          key={th.id}
                          scope="col"
                          className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {th.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {allNPICompanyMaterials?.content?.map(
                      ({
                        material,
                        suppliersQuantity,
                        supplierValidatedQuantity,
                        activeOrdersQuantity,
                      }) => (
                        <MaterialTableRow
                          key={material.id}
                          id={material.id}
                          status={material.active}
                          suppliersQuantity={suppliersQuantity}
                          supplierValidatedQuantity={supplierValidatedQuantity}
                          activeOrdersQuantity={activeOrdersQuantity}
                          deliveryFormat={material.deliveryFormat}
                          annualVolume={material.annualVolume}
                          merchancyCode={material.ean}
                          responsible={material.owner.fullName}
                          measurementUnit={material.measurementUnit}
                          globalMaterialName={material.globalMaterialName}
                        />
                      )
                    )}
                  </tbody>
                </table>
                <Pagination
                  results={allNPICompanyMaterials.numberOfElements}
                  total={allNPICompanyMaterials.totalElements}
                  amountOfPages={allNPICompanyMaterials.totalPages}
                  currentPage={pagePINumber || 0}
                  setPageNumber={setPagePINumber}
                />
              </div>
            </Fragment>
          )}
        {userMaterials && userMaterials?.content?.length > 0 && (
          <Fragment>
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {userMaterialsTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {userMaterials?.content?.map((mat) => (
                    <MaterialTableRow
                      key={mat.id}
                      id={mat.id}
                      status={mat.active}
                      annualVolume={mat.annualVolume}
                      merchancyCode={mat.ean}
                      deliveryPlace={mat.deliveryPlace?.name ?? TO_BE_AGREED}
                      measurementUnit={mat.measurementUnit}
                      globalMaterialName={mat.globalMaterialName}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                results={userMaterials.numberOfElements}
                total={userMaterials.totalElements}
                amountOfPages={userMaterials.totalPages}
                currentPage={pageUserMaterial || 0}
                setPageNumber={setPageUserMaterial}
              />
            </div>
          </Fragment>
        )}
        {foundAllMaterials && foundAllMaterials?.length > 0 && (
          <Fragment>
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {allPICompanyMaterialsTh?.map((th) => (
                      <th
                        key={th.id}
                        scope="col"
                        className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {th.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {foundAllMaterials?.map((mat) => (
                    <MaterialTableRow
                      key={mat.id}
                      id={mat.id}
                      status={mat.active}
                      annualVolume={mat.annualVolume}
                      merchancyCode={mat.ean}
                      responsible={mat.owner.fullName}
                      measurementUnit={mat.measurementUnit}
                      globalMaterialName={mat.globalMaterialName}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MaterialsTable;
