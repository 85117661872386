import axios from "../axios-orders";

export default function useSites() {
  const onGetSites = async (companyId: string) => {
    const { data } = await axios.get(`/site/company/${companyId}`);
    return data;
  };

  const onGetSite = async (siteId: string) => {
    const { data } = await axios.get(`/site/${siteId}`);
    return data;
  };

  const onCreateSite = async (body: any) => {
    const { data } = await axios.post("/site", body);
    return data;
  };

  const onEditSite = async ({
    siteId,
    body,
  }: {
    siteId: string;
    body: any;
  }) => {
    const { data } = await axios.put(`/site/${siteId}`, body);
    return data;
  };

  return {
    onGetSite,
    onGetSites,
    onEditSite,
    onCreateSite,
  };
}
