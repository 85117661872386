import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersTable, Loader, UsersTable } from "../../../components";
import { useOrders, useUsers } from "../../../hooks";
import { ORDER_TYPES, OrderState, UserResponse } from "../../../types";
import { useQuery } from "@tanstack/react-query";

type BuyerProps = {
  isAnalyst?: boolean;
  user: UserResponse;
};

export default function Buyer({ isAnalyst = false, user }: BuyerProps) {
  const { t } = useTranslation();
  const [closedPageNumber, setClosedPageNumber] = useState(0);
  const { onGetOrders } = useOrders();
  const { onGetUsersByCompany } = useUsers();

  const orders = useQuery({
    queryKey: ["orders", closedPageNumber],
    queryFn: () =>
      onGetOrders(
        OrderState.CLOSED,
        ORDER_TYPES.NORMAL,
        ORDER_TYPES.INVERSE,
        closedPageNumber
      ),
  });

  const usersByCompany = useQuery({
    queryKey: ["userByCompany"],
    queryFn: () => onGetUsersByCompany(),
  });

  const handleClosedPageNumber = (page: number) => setClosedPageNumber(page);

  return (
    <Fragment>
      {orders.isLoading || usersByCompany.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {orders.data?.content?.length > 0 ? (
            <OrdersTable
              title={t("order.closed")}
              orders={orders.data}
              pageNumber={closedPageNumber}
              setPageNumber={handleClosedPageNumber}
            />
          ) : null}

          {!isAnalyst ? (
            usersByCompany.data?.filter((u: UserResponse) => u.id !== user.id)
              ?.length > 0 ? (
              <UsersTable
                usersByCompany={usersByCompany.data?.filter(
                  (u: UserResponse) => u.id !== user?.id
                )}
                title={t("users.title")}
              />
            ) : null
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
}
