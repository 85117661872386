import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { KpiResponse } from "../types";

const fetchKpis = async (): Promise<KpiResponse> => {
  const response = await axios.get("/user/kpi");
  return response.data;
};

export default function useKpis() {
  return useQuery<KpiResponse, Error>({
    queryKey: ["kpis"],
    queryFn: fetchKpis,
  });
}
