import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  mapStateToProps,
  editSiteValidationSchema,
  capitalize,
  mapDispatchToProps,
} from "../../utils";
import { useSites } from "../../hooks";
import { Loader, Modal, InputField, SiteAutocomplete, Button } from "..";
import { SiteResponse } from "../../types";

type FormValues = {
  internalCode: string;
  name: string;
  comment?: string;
};

const EditSiteForm = ({
  site,
  onOpenNotification,
}: {
  site: SiteResponse;
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState<any>(null);
  const [message, setMessage] = useState("");

  const { onEditSite } = useSites();

  const preloadData = {
    internalCode: site?.internalCode,
    name: site?.name,
  };

  const formOptions = {
    resolver: yupResolver(editSiteValidationSchema),
    defaultValues: preloadData,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  const { mutate, isPending: isLoadingSites } = useMutation({
    mutationFn: ({ siteId, body }: { siteId: string; body: any }) =>
      onEditSite({ siteId, body }),
    onSuccess: () => {
      onOpenNotification(t("sites.editSuccess"));
      return navigate("/sites");
    },
    onError: () => {
      return onOpenNotification(t("newMaterialForm.error"));
    },
  });

  const onSubmit = (data: any) => {
    if (!address && !address?.formatted_address && !address?.place_id) {
      setOpen(false);
      setMessage("La dirección debe tener un valor seleccionado");
      return;
    }
    const name = capitalize(data.name);
    mutate({
      siteId: site?.id,
      body: {
        ...site,
        ...data,
        company: { id: site?.company.id },
        country: { id: site?.country.id },
        name,
        address: address?.formatted_address,
        googlePlaceId: address?.place_id,
      },
    });
    setOpen(false);
  };
  const onError = () => setOpen(false);

  if (isLoadingSites && !site && !preloadData) return <Loader isFullScreen />;

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden rounded">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="pb-4">
                  <InputField
                    id="internalCode"
                    name="internalCode"
                    type="text"
                    label={t("sites.code")}
                    errorName={errors?.internalCode}
                    errorMessage={t("sites.codeExists")}
                    required
                    register={register}
                  />
                </div>

                <div className="pb-4">
                  <InputField
                    id="name"
                    name="name"
                    type="text"
                    label={t("sites.name")}
                    errorName={errors?.name}
                    errorMessage={errors?.name?.message}
                    required
                    register={register}
                  />
                </div>

                <SiteAutocomplete
                  setAddress={(place) => {
                    setAddress(place);
                    setMessage("");
                  }}
                  address={site?.address}
                />
                {message ? (
                  <span className="text-xs text-red-500">{message}</span>
                ) : null}

                <div className="py-4">
                  <InputField
                    id="comment"
                    name="comment"
                    type="text"
                    label={t("sites.comment")}
                    register={register}
                  />
                </div>
              </div>

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex items-center justify-end gap-2">
                <Button link to={`/sites`} variant="cancel">
                  {t("cta.cancel")}
                </Button>
                <Button
                  onClick={(e: any) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  {t("cta.saveChanges")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          icon={
            <CheckCircleIcon
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          }
          title={t("sites.edit")}
          message={t("sites.question")}
          onClickSuccess={handleSubmit(onSubmit, onError)}
          onClickCancel={() => setOpen(false)}
        />
      </form>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSiteForm);
