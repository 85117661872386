import { TableTitle } from "..";
import i18next from "../../i18n";
import JointOffersTableRow from "./JointOffersTableRow/JointOffersTableRow";
import { OfferResponse } from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("tenders.tenderSummary.name") },
  { id: 2, name: i18next.t("tenders.tenderSummary.company") },
  { id: 3, name: i18next.t("tenders.tenderSummary.end") },
  { id: 4, name: i18next.t("tenders.tenderSummary.quantity") },
  { id: 5, name: i18next.t("tenders.tenderSummary.status") },
  { id: 6, name: i18next.t("tenders.tenderSummary.type") },
  { id: 7, name: "" },
];

type JointOffersTableProps = {
  offers: [OfferResponse[]];
  title: string;
};

const JointOffersTable = ({ offers, title }: JointOffersTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        {title && <TableTitle title={title} />}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {offers?.map((offer, index) => (
                <JointOffersTableRow
                  key={offer[0].id}
                  offer={offer[0]}
                  offers={offers[index]}
                  quantity={offer.length}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JointOffersTable;
