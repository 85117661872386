import axios from "../axios-orders";

export default function useAI() {
  const onGetMaterialMarketInfo = async (materialId: string) => {
    const { data } = await axios.get(`/utils/ai/material/${materialId}`);
    return data;
  };

  return {
    onGetMaterialMarketInfo,
  };
}
