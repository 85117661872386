import { Fragment, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  mapStateToProps,
  mapDispatchToProps,
  cutIdOnFirstHyphen,
  BIDDING_STATES,
} from "../../utils";
import { GoBackArrow, Loader, Button, Modal } from "../../components";
import { useID, usePurchaseRequests } from "../../hooks";
import BidHeader from "../Bid/BidHeader";
import PurchaseRequestSummaryDetail from "./PurchaseRequestSummaryDetail/PurchaseRequestSummaryDetail";

const PurchaseRequestDetail = ({
  isRequester,
  onOpenNotification,
}: {
  isRequester: boolean;
  onOpenNotification: (message: string) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const id = useID();
  const [openReject, setOpenReject] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const {
    onGetPurchaseRequest,
    onDeletePurchaseRequest,
    onUpdatePurchaseRequest,
    onRejectPurchaseRequest,
  } = usePurchaseRequests();

  const {
    data: purchaseRequest,
    isLoading: isLoadingPurchaseRequests,
    isError: purchaseRequestError,
  } = useQuery({
    queryKey: ["purchaseRequest", id],
    queryFn: () => onGetPurchaseRequest(id),
  });

  const { mutate: deletePurchaseRequest, isPending: isDeleting } = useMutation({
    mutationFn: (purchaseRequestId: string) =>
      onDeletePurchaseRequest(purchaseRequestId),
    onSuccess: () => {
      onOpenNotification(t("purchaseRequests.deleteMessage"));
      navigate("/");
    },
    onError: () => {
      onOpenNotification(t("newMaterialForm.error"));
    },
  });

  const { mutate: rejectPurchaseRequest, isPending: isRejecting } = useMutation(
    {
      mutationFn: ({
        purchaseRequestId,
        body,
      }: {
        purchaseRequestId: string;
        body: { comment: string };
      }) => onRejectPurchaseRequest({ purchaseRequestId, body }),
      onSuccess: () => {
        onOpenNotification(t("purchaseRequests.rejectedSuccess"));
        navigate("/purchase-requests");
      },
      onError: () => {
        onOpenNotification(t("newMaterialForm.error"));
      },
    }
  );

  const { mutate: updatePurchaseRequest } = useMutation({
    mutationFn: (body: any) => onUpdatePurchaseRequest(body),
    onSuccess: () => {
      onOpenNotification(t("purchaseRequests.sendSuccess"));
      navigate("/");
    },
    onError: () => {
      onOpenNotification(t("newMaterialForm.error"));
    },
  });

  const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRejectMessage(e.target.value);

  const handleReject = () => {
    if (!rejectMessage) return;
    rejectPurchaseRequest({
      purchaseRequestId: id,
      body: { comment: rejectMessage },
    });
    setOpenReject(false);
  };

  const handleDelete = () => {
    deletePurchaseRequest(id);
    setOpenDelete(false);
  };

  const handleUpdate = () => {
    updatePurchaseRequest([
      {
        ...purchaseRequest.order,
        user: { id: purchaseRequest.order.user.id },
        company: { id: purchaseRequest.order.company.id },
        deliveryPlace: purchaseRequest?.order?.deliveryPlace
          ? { id: purchaseRequest.order.deliveryPlace.id }
          : null,
        state: BIDDING_STATES.DELIVERED,
        comment: null,
      },
    ]);
    setOpenSend(false);
  };

  if (purchaseRequestError) navigate("/not-found");

  return (
    <Fragment>
      <Modal
        open={openSend}
        setOpen={setOpenSend}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("purchaseRequests.send")}
        message={t("purchaseRequests.sendMessage")}
        onClickSuccess={handleUpdate}
        onClickCancel={() => setOpenSend(false)}
      />
      <Modal
        open={openReject}
        setOpen={setOpenReject}
        hasMessage
        disabled={!rejectMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={t("purchaseRequests.reject")}
        message={t("purchaseRequests.rejectQuestion")}
        isCancel
        onClickSuccess={handleReject}
        onClickCancel={() => setOpenReject(false)}
      />
      <Modal
        open={openDelete}
        setOpen={setOpenDelete}
        hasMessage
        disabled={!rejectMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={t("purchaseRequests.modalTitleDelete")}
        message={t("purchaseRequests.modalDelete")}
        isCancel
        onClickSuccess={handleDelete}
        onClickCancel={() => setOpenDelete(false)}
      />
      {isLoadingPurchaseRequests || isDeleting || isRejecting ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="w-full px-8 flex justify-between items-center gap-4">
                <GoBackArrow />
                {isRequester ? (
                  <div className="flex gap-2 items-center">
                    {(purchaseRequest?.order?.state === BIDDING_STATES.DRAFT ||
                      purchaseRequest?.order?.state ===
                        BIDDING_STATES.DELIVERED ||
                      purchaseRequest?.order?.state ===
                        BIDDING_STATES.REJECTED) && (
                      <>
                        <Button
                          onClick={() => setOpenDelete(true)}
                          variant="danger"
                        >
                          {t("purchaseRequests.delete")}
                        </Button>
                        <Button
                          link
                          to={`/new-purchase-request?purchaseRequestId=${purchaseRequest?.id}`}
                          variant="cancel"
                        >
                          {t("cta.edit")}
                        </Button>
                        {purchaseRequest?.order?.state !==
                          BIDDING_STATES.DELIVERED && (
                          <Button onClick={() => setOpenSend(true)}>
                            {t("cta.send")}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() => setOpenReject(true)}
                      variant="danger"
                    >
                      {t("purchaseRequests.reject")}
                    </Button>
                    <Button
                      link
                      to={`/order-by-request?purchaseRequestId=${id}`}
                    >
                      {t("cta.launch")}
                    </Button>
                  </div>
                )}
              </div>
              <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-2 gap-4">
                <div className="col-span-2">
                  <h1 className="font-bold text-spectum uppercase mb-4">
                    {t("tenders.form.internalCode")}
                    {": "}
                    {purchaseRequest && cutIdOnFirstHyphen(purchaseRequest?.id)}
                  </h1>
                  <BidHeader
                    order={purchaseRequest?.order}
                    orderName={purchaseRequest?.order?.name}
                  />
                </div>
                <div className="col-span-2">
                  <PurchaseRequestSummaryDetail
                    purchaseRequest={purchaseRequest}
                    type={purchaseRequest?.type}
                    specificationName={purchaseRequest?.plicationUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRequestDetail);
