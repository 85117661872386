import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { OrdersTable, Loader } from "../../../components";
import {
  ORDER_TYPES,
  OrderState,
  SimpleOrderPaginatedResponse,
} from "../../../types";

type SucceedProps = {
  onGetOrders: (
    state: OrderState,
    type: ORDER_TYPES,
    _type: ORDER_TYPES,
    page: number
  ) => Promise<SimpleOrderPaginatedResponse>;
};

export const Succeed = ({ onGetOrders }: SucceedProps) => {
  const [succeedPageNumber, setSucceedPageNumber] = useState(0);

  const orders = useQuery({
    queryKey: ["orders", succeedPageNumber],
    queryFn: () =>
      onGetOrders(
        OrderState.SUCCEED,
        ORDER_TYPES.NORMAL,
        ORDER_TYPES.INVERSE,
        succeedPageNumber
      ),
  });

  const handleSucceedPageNumber = (page: number) => setSucceedPageNumber(page);

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={succeedPageNumber}
              setPageNumber={handleSucceedPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
