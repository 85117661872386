import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader, PurchaseRequestsTable, Tabs } from "../../components";
import i18next from "../../i18n";
import { usePurchaseRequests } from "../../hooks";
import { BIDDING_STATES, mapStateToProps } from "../../utils";

const PurchaseRequests = ({ isRequester }: { isRequester: boolean }) => {
  const [page, setPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [multiplePage, setMultiplePage] = useState(0);
  const {
    onGetUserPurchaseRequests,
    onGetMultiplePurchaseRequests,
    onGetMultiplePurchaseRequestsByState,
    onGetUserPurchaseRequestsByState,
    onGetCountPurchaseRequests,
  } = usePurchaseRequests();

  const { data: multiplePurchaseRequests, isLoading: isLoadingMultiple } =
    useQuery({
      queryKey: ["multiplePurchaseRequests", multiplePage],
      queryFn: () => onGetMultiplePurchaseRequests(multiplePage),
      enabled: !isRequester,
    });

  const { data: userPurchaseRequests, isLoading: isLoadingUser } = useQuery({
    queryKey: ["userPurchaseRequests", page],
    queryFn: () => onGetUserPurchaseRequests(page),
    enabled: !isRequester,
  });

  const { data: countPurchaseRequests, isLoading: isLoadingCount } = useQuery({
    queryKey: ["countPurchaseRequests"],
    queryFn: () => onGetCountPurchaseRequests(),
    enabled: isRequester,
  });

  const {
    data: userPurchaseRequestsByState,
    isLoading: isLoadingUserPurchase,
  } = useQuery({
    queryKey: ["userPurchaseRequestsByState", selectedTab],
    queryFn: () => {
      let state = BIDDING_STATES.DELIVERED;
      if (selectedTab === 1) {
        state = BIDDING_STATES.DRAFT;
      } else if (selectedTab === 2) {
        state = BIDDING_STATES.OPEN;
      } else if (selectedTab === 3) {
        state = BIDDING_STATES.REJECTED;
      } else if (selectedTab === 4) {
        state = BIDDING_STATES.SUCCEED;
      }
      return onGetUserPurchaseRequestsByState(state);
    },
    enabled: isRequester,
  });

  const { data: multiplePurchaseRequestsByState, isLoading: isLoadingByState } =
    useQuery({
      queryKey: ["multiplePurchaseRequestsByState", selectedTab],
      queryFn: () => {
        let state = BIDDING_STATES.DELIVERED;
        if (selectedTab === 1) {
          state = BIDDING_STATES.DRAFT;
        } else if (selectedTab === 2) {
          state = BIDDING_STATES.OPEN;
        } else if (selectedTab === 3) {
          state = BIDDING_STATES.REJECTED;
        } else if (selectedTab === 4) {
          state = BIDDING_STATES.SUCCEED;
        }
        return onGetMultiplePurchaseRequestsByState(state);
      },
      enabled: isRequester,
    });

  const tabs = [
    {
      name: i18next.t("purchaseRequests.tabs.sent"),
      current: false,
      bgColor: "bg-blue-100 text-blue-800",
      count: countPurchaseRequests?.delivered || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.draft"),
      current: true,
      bgColor: "bg-gray-100 text-gray-800",
      count: countPurchaseRequests?.draft || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.inProgress"),
      current: true,
      bgColor: "bg-purple-100 text-purple-800",
      count: countPurchaseRequests?.draft || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.rejected"),
      current: false,
      bgColor: "bg-red-100 text-red-800",
      count: countPurchaseRequests?.rejected || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.closed"),
      current: false,
      bgColor: "bg-green-100 text-green-800",
      count: countPurchaseRequests?.succeed || 0,
    },
  ];

  const handleChangePage = (page: number) => setPage(page);
  const handleChangeMultiplePage = (page: number) => setMultiplePage(page);
  const handleTabClick = (index: number) => setSelectedTab(index);

  return (
    <div className="px-4 mt-8">
      <div className="md:flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("purchaseRequests.title")}
        </h2>
        {isRequester && (
          <Link
            to="/new-purchase-request"
            type="button"
            className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
          >
            {i18next.t("purchaseRequests.add")}
          </Link>
        )}
      </div>
      {isRequester && (
        <Tabs
          tabs={tabs}
          handleTabClick={handleTabClick}
          selectedTab={selectedTab}
        />
      )}
      {isLoadingByState ||
      isLoadingCount ||
      isLoadingMultiple ||
      isLoadingUser ||
      isLoadingUserPurchase ? (
        <Loader />
      ) : (
        <div>
          {isRequester && userPurchaseRequestsByState?.content.length > 0 && (
            <PurchaseRequestsTable
              pageNumber={page}
              setPageNumber={handleChangePage}
              purchaseRequests={userPurchaseRequestsByState}
            />
          )}
          {!isRequester && userPurchaseRequests?.content.length > 0 && (
            <PurchaseRequestsTable
              pageNumber={page}
              setPageNumber={handleChangePage}
              purchaseRequests={userPurchaseRequests}
            />
          )}
          {multiplePurchaseRequests?.content.length > 0 && (
            <PurchaseRequestsTable
              title={`${i18next.t("purchaseRequests.joint")}`}
              pageNumber={multiplePage}
              setPageNumber={handleChangeMultiplePage}
              multiplePurchaseRequests={multiplePurchaseRequests}
            />
          )}
          {multiplePurchaseRequestsByState?.content?.length > 0 && (
            <PurchaseRequestsTable
              title={`${i18next.t("purchaseRequests.joint")}`}
              pageNumber={multiplePage}
              setPageNumber={handleChangeMultiplePage}
              multiplePurchaseRequests={multiplePurchaseRequestsByState}
            />
          )}
          {(userPurchaseRequestsByState?.content.length === 0 ||
            !userPurchaseRequestsByState) &&
            (multiplePurchaseRequests?.content.length === 0 ||
              !multiplePurchaseRequests) && (
              <p className="text-xs mt-10">
                {i18next.t("purchaseRequests.noPurchaseRequests")}
              </p>
            )}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(PurchaseRequests);
