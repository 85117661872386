import { Fragment } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../../i18n";
import {
  MEASUREMENT_UNIT_LIST_SERVICE,
  PN_TYPE,
  TO_BE_AGREED,
} from "../../../utils";
import { TooltipSP } from "../../../components";
import PeriodDate from "./PeriodDate/PeriodDate";
import { ORDER_TYPES } from "../../../types";

type PriceDecrementPaymentProps = {
  type: string;
  materialsToBid: any;
  handlePriceDecrementChanges: any;
  handleSupplyPeriod: any;
};

const PriceDecrementPayment = ({
  type,
  materialsToBid,
  handlePriceDecrementChanges,
  handleSupplyPeriod,
}: PriceDecrementPaymentProps) => {
  return (
    <Fragment>
      {materialsToBid.map((matToBid: any) => (
        <div
          key={matToBid.id}
          className="my-5 border border-gray-300 p-2 rounded hover:shadow-lg"
        >
          <p className="text-sm uppercase font-bold pb-2 text-spectum">
            {matToBid.globalMaterialName}
            <span className="text-gray-700 text-xs">
              {" - "}({matToBid.deliveryPlace?.name ?? TO_BE_AGREED})
            </span>
          </p>
          <div className="grid grid-cols-3 gap-2 justify-between items-end">
            {(type === ORDER_TYPES.NORMAL || type === ORDER_TYPES.INVERSE) && (
              <div className="w-full">
                <label
                  htmlFor="name"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("tenders.form.name")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={matToBid.name}
                    onChange={(e) =>
                      handlePriceDecrementChanges(e, matToBid, "name")
                    }
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                  />
                </div>
              </div>
            )}
            {matToBid.type === PN_TYPE.SERVICE && (
              <>
                <div className="col-span-3 md:col-span-1">
                  <label
                    htmlFor="application"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("newMaterialForm.brand")}
                    <span className="text-sm"></span>
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="application"
                      id="application"
                      value={matToBid.application}
                      onChange={(e) =>
                        handlePriceDecrementChanges(e, matToBid, "application")
                      }
                      className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                    />
                  </div>
                </div>
                <div className="col-span-3 md:col-span-1">
                  <label
                    htmlFor="purpose"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.form.purpose")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="purpose"
                      id="purpose"
                      value={matToBid.purpose}
                      onChange={(e) =>
                        handlePriceDecrementChanges(e, matToBid, "purpose")
                      }
                      className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="w-full">
              <label
                htmlFor="internalCode"
                className="uppercase block text-xs font-medium text-gray-700"
              >
                {i18next.t("tenders.form.internalCode")}
                <span className="text-sm"></span>
              </label>
              <div className="flex items-center">
                <input
                  type="text"
                  name="internalCode"
                  id="internalCode"
                  value={matToBid.internalCode}
                  onChange={(e) =>
                    handlePriceDecrementChanges(e, matToBid, "internalCode")
                  }
                  className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                />
              </div>
            </div>

            {matToBid.type !== PN_TYPE.SERVICE && (
              <div className="w-full">
                <label
                  htmlFor="volume"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("tenders.volume")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <div className="flex items-center">
                  <input
                    type="number"
                    name="volume"
                    min={0}
                    id="volume"
                    value={matToBid.quantity}
                    onChange={(e) =>
                      handlePriceDecrementChanges(e, matToBid, "quantity")
                    }
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                  />
                  {matToBid.type !== PN_TYPE.SERVICE && (
                    <span className="ml-1 text-gray-600 text-xs font-bold">
                      {matToBid.measurementUnit}
                    </span>
                  )}
                </div>
              </div>
            )}

            {matToBid.type === PN_TYPE.SERVICE && (
              <>
                <div className="w-full">
                  <label
                    htmlFor="measurementUnit"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("material.measurementUnit")}
                    <span className="text-sm text-red-500"> *</span>
                  </label>
                  <select
                    id="measurementUnit"
                    name="measurementUnit"
                    value={matToBid.measurementUnit}
                    onChange={(e) =>
                      handlePriceDecrementChanges(
                        e,
                        matToBid,
                        "measurementUnit"
                      )
                    }
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                  >
                    <option value="">
                      {i18next.t("newMaterialForm.selectMeasurementUnit")}
                    </option>
                    {MEASUREMENT_UNIT_LIST_SERVICE?.map((unit) => (
                      <option key={unit.id} value={unit.value}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            <PeriodDate
              matToBid={matToBid}
              handleSupplyPeriod={handleSupplyPeriod}
            />

            {(type === ORDER_TYPES.INVERSE ||
              type === ORDER_TYPES.INVERSE_JOINT) && (
              <Fragment>
                <div className="w-full">
                  <label
                    htmlFor="startingPrice"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.initialPrice")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <input
                    type="number"
                    name="startingPrice"
                    min={0}
                    id="startingPrice"
                    value={matToBid.initialPrice}
                    onChange={(e) =>
                      handlePriceDecrementChanges(e, matToBid, "initialPrice")
                    }
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                  />
                </div>
                <div className="w-full">
                  <label
                    id="decrement"
                    htmlFor="decrement"
                    className="uppercase flex gap-1 text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.minimumDecrement")}
                    <span className="text-sm text-red-600"> *</span>
                    <QuestionMarkCircleIcon
                      className="h-4 w-4"
                      id={`decrement-${matToBid.id}`}
                    />
                  </label>
                  <input
                    type="number"
                    name="decrement"
                    min={0}
                    id="decrement"
                    value={matToBid.minimumDecrement}
                    onChange={(e) =>
                      handlePriceDecrementChanges(
                        e,
                        matToBid,
                        "minimumDecrement"
                      )
                    }
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                  />
                </div>
                <TooltipSP
                  id={`decrement-${matToBid.id}`}
                  message={i18next.t("tenders.form.decrementInfo")}
                />
              </Fragment>
            )}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default PriceDecrementPayment;
