import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  OpportunitiesTable,
  OfferTable,
  Loader,
  Tabs,
  JointOrdersTable,
  JointOffersTable,
} from "../../components";
import { useOffers, useOpportunities } from "../../hooks";
import i18next from "../../i18n";
import { BIDDING_TYPES } from "../../utils";
import { OfferResponse, OrderResponse } from "../../types";

const tabs = [
  {
    name: i18next.t("order.open"),
    current: true,
    bgColor: "bg-purple-100 text-purple-800",
  },
  {
    name: i18next.t("offer.made"),
    current: false,
    bgColor: "bg-blue-100 text-blue-800",
  },
];

const BiddingOpportunities = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabNumber = searchParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(
    tabNumber ? parseInt(tabNumber) : 0
  );
  const [pageNumberOffer, setPageNumberOffer] = useState(0);
  const { onGetOpportunities, onGetMultipleOpportunities } = useOpportunities();
  const { onGetOffers, onGetMultipleOffers } = useOffers();

  useEffect(() => {
    if (selectedTab === 0) {
      setSearchParams({ tab: "0" });
    }
  }, []);

  const opportunities = useQuery({
    queryKey: ["opportunities"],
    queryFn: () => onGetOpportunities(),
  });

  const multipleOpportunities = useQuery({
    queryKey: ["multipleOpportunities"],
    queryFn: () =>
      onGetMultipleOpportunities(
        BIDDING_TYPES.JOINT,
        BIDDING_TYPES.INVERSE_JOINT
      ),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items;
    },
  });

  const offers = useQuery({
    queryKey: ["offers", pageNumberOffer],
    queryFn: () => onGetOffers(pageNumberOffer),
  });

  const multipleOffers = useQuery({
    queryKey: ["multipleOffers"],
    queryFn: () => onGetMultipleOffers(),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items;
    },
  });

  const handlePageNumberOffer = (page: number) => setPageNumberOffer(page);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setSearchParams({ tab: `${index}` });
  };

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("navigation.tenders")}
      </h2>
      <div className="mt-4">
        <Tabs
          tabs={tabs}
          handleTabClick={handleTabClick}
          selectedTab={selectedTab}
        />
      </div>
      {opportunities.isLoading ||
      multipleOpportunities.isLoading ||
      multipleOffers.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <>
          {selectedTab === 0 && (
            <>
              {opportunities.data?.length === 0 &&
                multipleOpportunities.data?.length === 0 && (
                  <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                    {i18next.t("messages.tendersSoon")}
                  </h4>
                )}
              {opportunities.data?.length > 0 && (
                <OpportunitiesTable opportunities={opportunities.data} />
              )}
              {multipleOpportunities &&
                multipleOpportunities.data &&
                multipleOpportunities.data.length > 0 && (
                  <JointOrdersTable
                    orders={multipleOpportunities.data as [OrderResponse[]]}
                    title={i18next.t("order.joint")}
                  />
                )}
            </>
          )}
          {selectedTab === 1 && (
            <>
              {offers.data?.content?.length === 0 &&
                multipleOffers.data?.length === 0 && (
                  <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                    {i18next.t("offer.noOffer")}
                  </h4>
                )}
              {offers.data?.content?.length > 0 && (
                <OfferTable
                  offers={offers.data}
                  pageNumber={pageNumberOffer}
                  setPageNumber={handlePageNumberOffer}
                />
              )}
              {multipleOffers &&
                multipleOffers.data &&
                multipleOffers.data.length > 0 && (
                  <JointOffersTable
                    offers={multipleOffers.data as [OfferResponse[]]}
                    title={i18next.t("order.joint")}
                  />
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BiddingOpportunities;
