import { useQuery } from "@tanstack/react-query";
import i18next from "../../../i18n";
import { JointOrdersTable } from "../../../components";
import { useOrders } from "../../../hooks";
import { OrderResponse, OrderState } from "../../../types";

export const Joint = ({ state }: { state: OrderState }) => {
  const { onGetJointOrders } = useOrders();

  const jointOrders = useQuery({
    queryKey: ["jointOrders", state],
    queryFn: () => onGetJointOrders(state),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items as [OrderResponse[]];
    },
  });

  return (
    <>
      {jointOrders.data && jointOrders.data?.length > 0 && (
        <JointOrdersTable
          title={i18next.t("order.joint")}
          orders={jointOrders.data}
        />
      )}
    </>
  );
};
