import axios from "../axios-orders";

export default function useUserValidate() {
  const handleValidateUser = async ({
    token,
    email,
  }: {
    token: string;
    email: string;
  }) => {
    const { data } = await axios.get(`/auth/validate-email/${token}/${email}`);
    return data;
  };

  return { handleValidateUser };
}
