import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import LogoSpectum from "../../assets/images/logo-simbuy.png";
import { Loader } from "../../components";
import { useUserValidate } from "../../hooks";
import { WHATSAPP } from "../../utils";

export default function ValidateUser() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [enabled, setEnabled] = useState(false);
  const { handleValidateUser } = useUserValidate();

  const {
    data: userValidate,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["userValidate"],
    queryFn: () =>
      handleValidateUser({ token: token || "", email: email || "" }),
    enabled: !enabled,
  });

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  let message = (
    <>
      <p className="block mx-auto pb-10 text-xl">{t("register.clickVerify")}</p>
      <button
        className="w-200 mx-auto block justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
        onClick={() => setEnabled(true)}
      >
        {t("register.verify")}
      </button>
    </>
  );

  if (userValidate?.status === 200) {
    message = (
      <div>
        <p className="block mx-auto pb-10 text-center text-xl">
          <span className="text-green-700 font-bold uppercase">
            {t("register.accountValidated")}
          </span>
          <br />
          <br />
          {t("register.briefly")}
          <br />
          <br />
          {t("register.checkSpam")}
          <br /> {t("register.thanks")}
        </p>
        <a
          href="https://app.spectum.io"
          className="text-center w-200 mx-auto block justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
        >
          {t("register.login")}
        </a>
      </div>
    );
  }

  if (isError) {
    message = (
      <div>
        <p className="block mx-auto pb-10 text-center text-xl">
          <span className="text-red-700 font-bold uppercase">
            {t("register.error")}
          </span>
          <br />
          <br />
          {t("register.contact")}{" "}
          <a
            href={WHATSAPP}
            target="_blank"
            rel="noreferrer"
            className="text-spectum-light font-bold underline"
          >
            {t("register.support")}
          </a>{" "}
          {t("register.help")}
          <br />
          <br /> {t("register.apologies")}
        </p>
        <a
          href="https://spectum.io"
          className="text-center w-200 mx-auto block justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
        >
          {t("register.visit")}
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-72 mb-4" src={LogoSpectum} alt="Spectum" />
      </div>
      {isLoading ? <Loader /> : message}
    </div>
  );
}
