import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { EditMaterialForm, GoBackArrow, Loader } from "../../../components";
import { useID, useMaterials, useSites } from "../../../hooks";
import { mapStateToProps } from "../../../utils";
import { MaterialResponse, UserResponse } from "../../../types";

const EditMaterial = ({ user }: { user: UserResponse }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();
  const { onGetMaterial } = useMaterials();
  const { onGetSites } = useSites();

  const { data: material, isError } = useQuery<MaterialResponse>({
    queryKey: ["material", id],
    queryFn: () => onGetMaterial(id),
    enabled: !!id,
  });

  const { data: sites } = useQuery({
    queryKey: ["sites", user?.company?.id],
    queryFn: () => onGetSites(user?.company?.id),
    enabled: !!user?.company?.id,
  });

  if (isError) navigate("/not-found");

  return (
    <div className="px-4 mt-6">
      <GoBackArrow />
      <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
        {t("newMaterialForm.edit")}
      </h2>
      {material ? (
        <EditMaterialForm
          pnType={material.category.parent.parent.parent.parent.name}
          material={material}
          deliveryPlace={material?.deliveryPlace?.id}
          sites={sites}
          confidentSpec={material?.confidentSpec}
        />
      ) : (
        <Loader isFullScreen />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(EditMaterial);
