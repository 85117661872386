import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Pill } from "../..";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import {
  PILL_LABELS,
  BIDDING_STATES,
  BIDDING_LABELS,
  cutIdOnFirstHyphen,
  mapStateToProps,
} from "../../../utils";
import { connect } from "react-redux";
import { OrderResponse, UserResponse } from "../../../types";

type PurchaseRequestsTableRowProps = {
  order: OrderResponse;
  isRequester: boolean;
  requester: UserResponse;
  purchaseRequestId?: string;
  multiplePurchaseRequestId?: string;
};

const PurchaseRequestsTableRow = ({
  order,
  isRequester,
  requester,
  purchaseRequestId,
  multiplePurchaseRequestId,
}: PurchaseRequestsTableRowProps) => {
  const pillResolver = () => {
    if (order.state === BIDDING_STATES.SUCCEED)
      return PILL_LABELS.OFFER_SELECTED;
    else if (order.state === BIDDING_STATES.PENDING)
      return BIDDING_LABELS.SCHEDULED;
    else if (order.state === BIDDING_STATES.OPEN)
      return BIDDING_LABELS.IN_PROGRESS;
    else if (order.state === BIDDING_STATES.CLOSED)
      return PILL_LABELS.OFFER_PENDING_OF_SELECTION;
    else if (order.state === BIDDING_STATES.CANCELLED)
      return PILL_LABELS.BIDDING_CANCELED;
    else if (order.state === BIDDING_STATES.DRAFT) return PILL_LABELS.DRAFT;
    else if (order.state === BIDDING_STATES.REJECTED)
      return PILL_LABELS.REJECTED;
    else if (order.state === BIDDING_STATES.DELIVERED)
      return PILL_LABELS.DELIVERED;
    else return "";
  };

  return (
    <tr key={order.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-900 uppercase font-bold">
        <Link
          to={
            multiplePurchaseRequestId
              ? `/purchase-request/multiple/${multiplePurchaseRequestId}`
              : `/purchase-request/${purchaseRequestId}`
          }
        >
          {purchaseRequestId
            ? cutIdOnFirstHyphen(purchaseRequestId)
            : cutIdOnFirstHyphen(multiplePurchaseRequestId)}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-900 font-bold">{order.name}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {order.startSupplyDate}{" "}
          {order.endSupplyDate !== order.startSupplyDate &&
            `- ${order.endSupplyDate}`}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {isRequester ? order.user.fullName : requester?.fullName}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xs text-gray-500">
          {format(new Date(order.createdAt), "yyyy-MM-dd")}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill label={pillResolver()} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
        <Link
          to={
            multiplePurchaseRequestId
              ? `/purchase-request/multiple/${multiplePurchaseRequestId}`
              : `/purchase-request/${purchaseRequestId}`
          }
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default connect(mapStateToProps)(PurchaseRequestsTableRow);
