import { connect } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Loader, PriceVolume } from "../..";
import i18next from "../../../i18n";
import { useMaterialVolume } from "../../../hooks";
import { mapDispatchToProps } from "../../../utils";
import { MaterialResponse } from "../../../types";

type PriceVolumeProps = {
  material: MaterialResponse;
  isOwner: boolean;
  isResponsible: boolean;
  onOpenNotification: (message: string) => void;
};

const PricingVolume = ({
  material,
  isOwner,
  isResponsible,
  onOpenNotification,
}: PriceVolumeProps) => {
  const {
    onCreateNegotiation,
    onUpdateMaterialVolume,
    onGetMaterialVolumePrice,
  } = useMaterialVolume();

  const queryClient = useQueryClient();

  const { data: materialVolumePrice, isLoading: isLoadingMaterialVolume } =
    useQuery({
      queryKey: ["materialVolume", material?.id],
      queryFn: () => onGetMaterialVolumePrice(material?.id),
      enabled: !!material?.id,
    });

  const { mutate: mutateCreateNegotiation, isPending: isLoadingNegotiation } =
    useMutation({
      mutationFn: ({ userId, body }: { userId: string; body: any }) =>
        onCreateNegotiation({ userId, body }),
      onSuccess: () => {
        onOpenNotification(i18next.t("volumePrice.addNegotiationSuccess"));
        return queryClient.invalidateQueries({ queryKey: ["materialVolume"] });
      },
      onError: () => {
        onOpenNotification(i18next.t("offer.error"));
      },
    });

  const { mutate: mutateUpdateVolumePrice, isPending: isUpdating } =
    useMutation({
      mutationFn: (body) => onUpdateMaterialVolume(body),
      onSuccess: () => {
        onOpenNotification(i18next.t("volumePrice.update"));
        return queryClient.invalidateQueries({ queryKey: ["materialVolume"] });
      },
      onError: () => {
        onOpenNotification(i18next.t("offer.error"));
      },
    });

  return (
    <section
      aria-labelledby="activity-title"
      className="mx-auto px-4 lg:px-8 pb-8"
    >
      {isLoadingMaterialVolume || isLoadingNegotiation || isUpdating ? (
        <Loader />
      ) : materialVolumePrice ? (
        <PriceVolume
          isOwner={isOwner}
          isResponsible={isResponsible}
          priceVolume={materialVolumePrice}
          materialId={material?.id}
          measurementUnit={material?.measurementUnit}
          coin={material?.coin}
          onUpdateMaterialVolume={mutateUpdateVolumePrice}
          onCreateNegotiation={mutateCreateNegotiation}
          isMaterialView
          hasTableHead
        />
      ) : null}
    </section>
  );
};

export default connect(null, mapDispatchToProps)(PricingVolume);
