import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import UserMaterialOpportunitiesTableRow from "./UserMaterialOpportunitiesTableRow/UserMaterialOpportunitiesTableRow";
import i18next from "../../i18n";
import { mapDispatchToProps, mapStateToProps } from "../../utils";
import { TableTitle, Pagination } from "..";
import {
  MaterialPaginatedResponse,
  InvitationPaginatedResponse,
} from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("opportunities.table.material") },
  { id: 2, name: i18next.t("opportunities.table.annualVolume") },
  { id: 3, name: i18next.t("opportunities.table.deliveryFormat") },
  { id: 4, name: i18next.t("opportunities.table.incoTerm") },
  { id: 5, name: i18next.t("opportunities.table.address") },
  { id: 6, name: "" },
];

type UserMaterialOpportunitiesTableProps = {
  isNew: boolean;
  isPending: boolean;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  onOpenBanner: (message: string) => void;
  onCloseBanner: () => void;
  opportunitiesByLastIds?: MaterialPaginatedResponse;
  foundOpportunitiesByLastIds?: MaterialPaginatedResponse;
  materialInvitationsOpportunities?: InvitationPaginatedResponse;
};

const UserMaterialOpportunitiesTable = ({
  isNew,
  isPending,
  pageNumber,
  setPageNumber,
  onOpenBanner,
  onCloseBanner,
  opportunitiesByLastIds,
  foundOpportunitiesByLastIds,
  materialInvitationsOpportunities,
}: UserMaterialOpportunitiesTableProps) => {
  const { t } = useTranslation();
  const handleOpenBanner = (e: any) => {
    e.preventDefault();
    onOpenBanner(t("messages.new"));
    setTimeout(() => {
      onCloseBanner();
    }, 4000);
  };

  return (
    <div className="flex flex-col mb-4">
      {materialInvitationsOpportunities && (
        <TableTitle title={t("messages.invitations")} />
      )}
      <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {tableTh?.map((th) => (
                <th
                  key={th.id}
                  scope="col"
                  className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {th.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {materialInvitationsOpportunities?.content?.map((opportunity) => (
              <UserMaterialOpportunitiesTableRow
                isNotValidated={isNew || isPending}
                key={opportunity.id}
                materialInvitationsOpportunities={opportunity}
                handleOpenBanner={handleOpenBanner}
              />
            ))}
            {opportunitiesByLastIds?.content?.map((opportunity) => (
              <UserMaterialOpportunitiesTableRow
                isNotValidated={isNew || isPending}
                key={opportunity.id}
                opportunity={opportunity}
                handleOpenBanner={handleOpenBanner}
              />
            ))}
            {foundOpportunitiesByLastIds?.content?.map((opportunity) => (
              <UserMaterialOpportunitiesTableRow
                isNotValidated={isNew || isPending}
                key={opportunity.id}
                opportunity={opportunity}
                handleOpenBanner={handleOpenBanner}
              />
            ))}
          </tbody>
        </table>
        {materialInvitationsOpportunities && (
          <Pagination
            results={materialInvitationsOpportunities?.numberOfElements}
            total={materialInvitationsOpportunities?.totalElements}
            amountOfPages={materialInvitationsOpportunities?.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
        {opportunitiesByLastIds && (
          <Pagination
            results={opportunitiesByLastIds?.numberOfElements}
            total={opportunitiesByLastIds?.totalElements}
            amountOfPages={opportunitiesByLastIds?.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
        {foundOpportunitiesByLastIds && (
          <Pagination
            results={foundOpportunitiesByLastIds?.numberOfElements}
            total={foundOpportunitiesByLastIds?.totalElements}
            amountOfPages={foundOpportunitiesByLastIds?.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMaterialOpportunitiesTable);
