import { connect } from "react-redux";
import { EditUserForm, Loader } from "../../../components";
import { useID, useUsers } from "../../../hooks";
import { mapDispatchToProps } from "../../../utils";
import i18next from "../../../i18n";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type EditUserProps = {
  onOpenNotification: (message: string) => void;
};

const EditUser = ({ onOpenNotification }: EditUserProps) => {
  const id = useID();
  const navigate = useNavigate();
  const { onEditUser, onGetUserById, onGetUsersByCompany } = useUsers();

  const { data: userToEdit, isLoading: isLoadingUser } = useQuery({
    queryKey: ["user", id],
    queryFn: () => onGetUserById(id),
    enabled: !!id,
  });

  const { data: usersByCompany, isLoading: isLoadingUsers } = useQuery({
    queryKey: ["usersByCompany"],
    queryFn: () => onGetUsersByCompany(),
  });

  const {
    mutate: editUser,
    isPending: isUpdatingUser,
    isError,
  } = useMutation({
    mutationFn: ({ userId, body }: { userId: string; body: any }) =>
      onEditUser({ userId, body }),
    onSuccess: () => {
      onOpenNotification(i18next.t("users.editSuccess"));
      navigate("/users");
    },
    onError: () => {
      onOpenNotification(i18next.t("newMaterialForm.error"));
    },
  });

  if (isUpdatingUser || isLoadingUser || isLoadingUsers)
    return <Loader isFullScreen />;

  return (
    <>
      <EditUserForm
        error={isError}
        userToEdit={userToEdit}
        onEditUser={editUser}
        usersByCompany={usersByCompany}
      />
    </>
  );
};

export default connect(null, mapDispatchToProps)(EditUser);
