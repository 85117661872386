import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ScaleIcon,
  MagnifyingGlassIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { CircleStackIcon } from "@heroicons/react/24/solid";

import { SkeletonLoad } from "..";
import { useKpis } from "../../hooks";
import { mapStateToProps } from "../../utils";

type KPIsProps = {
  isBuy: boolean;
  isBuyer: boolean;
  isManager: boolean;
  isAnalyst: boolean;
};

const KPIs = ({ isBuy, isBuyer, isManager, isAnalyst }: KPIsProps) => {
  const { t } = useTranslation();
  const kpis = useKpis();

  const buyersCards = [
    {
      name: t("kpis.tenders"),
      icon: ScaleIcon,
      amount: kpis.data?.ordersQuantity,
      href: "/orders",
    },
    {
      name: t("kpis.materials"),
      icon: CircleStackIcon,
      amount: kpis.data?.materialsQuantity,
      href: "/materials",
    },
    {
      name: t("kpis.purchaseRequests"),
      icon: BoltIcon,
      amount: kpis.data?.purchaseRequestsQuantity,
      href: "/purchase-requests",
    },
  ];

  const sellersCards = [
    {
      name: t("kpis.tenders"),
      icon: ScaleIcon,
      amount: kpis.data?.opportunitiesQuantity,
      href: "/biddings",
    },
    {
      name: t("kpis.applications"),
      icon: MagnifyingGlassIcon,
      amount: kpis.data?.materialOfferedQuantity,
      href: "/material/offers",
    },
  ];

  const cards =
    isBuy && (isBuyer || isManager || isAnalyst) ? buyersCards : sellersCards;

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase pb-4">
        {t("kpis.title")}
      </h2>
      {kpis.isLoading ? (
        <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <SkeletonLoad count={1} style={{ padding: "40px", width: "100%" }} />
          <SkeletonLoad count={1} style={{ padding: "40px", width: "100%" }} />
          <SkeletonLoad count={1} style={{ padding: "40px", width: "100%" }} />
        </div>
      ) : (
        <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
          {cards.map((card) => (
            <div
              key={card.name}
              className="bg-white overflow-hidden shadow rounded"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <card.icon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-xs font-bold text-gray-500 truncate uppercase">
                        {card.name}
                      </dt>
                      <dd>
                        <div className="text-sm font-medium text-gray-900">
                          {card.amount}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <Link
                    to={card.href}
                    className="font-medium text-cyan-700 hover:text-cyan-900"
                  >
                    {t("kpis.more")}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(KPIs);
