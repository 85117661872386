import axios from "../axios-orders";

export default function useChat() {
  const onGetChat = async (userId: string, materialId: string) => {
    const response = await axios.get(`/chat/${userId}/${materialId}`);
    return response.data;
  };

  const onCreateChat = async (body: any) => {
    const response = await axios.post(`/chat`, body);
    return response.data;
  };

  const onCreateMessage = async (body: any) => {
    const response = await axios.post(`/chat/message`, body);
    return response.data;
  };

  const onGetChats = async (type: string) => {
    const response = await axios.get(`/chat/${type}`);
    return response.data;
  };

  const onGetOrderChat = async (orderId: string) => {
    const response = await axios.get(`/chat/order/${orderId}`);
    return response.data;
  };

  const onGetMultipleOrderChat = async (orderId: string) => {
    const response = await axios.get(`/chat/multipleOrder/${orderId}`);
    return response.data;
  };

  return {
    onGetChat,
    onGetChats,
    onCreateChat,
    onGetOrderChat,
    onCreateMessage,
    onGetMultipleOrderChat,
  };
}
