import axios from "../axios-orders";

export default function useHistoric() {
  const onGetHistoric = async (materialId: string, page = 0) => {
    const { data } = await axios.get(`/order/material/${materialId}/${page}`);
    return data;
  };

  return { onGetHistoric };
}
