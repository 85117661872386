import axios from "../axios-orders";
import { ORDER_TYPES, OrderState } from "../types";

export default function useOrders() {
  const onGetOrders = async (
    state: OrderState,
    type: ORDER_TYPES,
    _type: ORDER_TYPES,
    page = 0
  ) => {
    const { data } = await axios.get(
      `/order/${state}/${type}/${_type}/${page}`
    );
    return data;
  };

  const onGetJointOrders = async (state: OrderState) => {
    const { data } = await axios.get(`/order/multiple/state/${state}`);
    return data;
  };

  const onGetJointOrder = async (orderId: string) => {
    const { data } = await axios.get(`/order/multiple/${orderId}`);
    return data;
  };

  const onGetOrder = async (orderId: string) => {
    const { data } = await axios.get(`/order/${orderId}`);
    return data;
  };

  const onGetSelectedOrder = async (orderId: string) => {
    const { data } = await axios.get(`/offer/order/selected/${orderId}`);
    return data;
  };

  const onCreateOrder = async (body: any) => {
    const { data } = await axios.post("/order", body);
    return data;
  };

  const onCreateMultipleOrder = async (body: any) => {
    const { data } = await axios.post("/order/multiple", body);
    return data;
  };

  const onDeleteOrder = async ({
    orderId,
    order,
  }: {
    orderId: string;
    order: any;
  }) => {
    const { data } = await axios.put(`/order/${orderId}`, order);
    return data;
  };

  const onDeleteMultipleOrders = async (orders: any) => {
    const { data } = await axios.put(`/order/list`, orders);
    return data;
  };

  const onGetOrdersCount = async () => {
    const { data } = await axios.get("/order/count");
    return data;
  };

  const onGetOffersComparison = async (orderId: string) => {
    const { data } = await axios.get(`/utils/comparison-report/${orderId}`);
    return data;
  };

  return {
    onGetOrder,
    onGetOrders,
    onCreateOrder,
    onDeleteOrder,
    onGetJointOrder,
    onGetJointOrders,
    onGetOrdersCount,
    onGetSelectedOrder,
    onGetOffersComparison,
    onCreateMultipleOrder,
    onDeleteMultipleOrders,
  };
}
